import { Document } from './document';
import { Instruction } from './instruction';
import { Negociation } from './negociation';

export class ReceivedTransfer {
  public id: number;
  public requestId: string;
  public addDate: Date;
  public receiptDate: Date;
  public amount: number;
  public currency: string;
  public status: string;
  public camundaId: string;
  public clientId: number;
  public taskId: string;
  public clientName: string;
  public referenceDelta: string;
  public referenceWf: string;
  public payerAccount: string;
  public payer: string;
  public documentList: Document[] = new Array();
  public accounts: Instruction[] = new Array();
  public negociations: Negociation[] = new Array();
  public instructionRequest: boolean;
  public comment: string;
  constructor() {}
}
