import { Component, OnInit, OnDestroy } from '@angular/core';
import { LettreCredit } from 'src/app/models/lettre-credit';
import { Subscription } from 'rxjs';
import { CreateLcService } from 'src/app/services/create-lc.service';
import { DataService } from 'src/app/services/data.service';
import { ActivatedRoute } from '@angular/router';
import { ExpirService } from 'src/app/services/expir.service';
import { PaymentOperation } from 'src/app/models/paymentOperation';
import { LastComment } from 'src/app/models/lastComment';
import { Products } from 'src/app/util';
import { RemdocService } from 'src/app/services/remdoc.service';
import { Remdoc } from 'src/app/models/remdoc';
import { IssuedTransferService } from 'src/app/services/issued-transfer.service';
import { IssuedTransfer } from 'src/app/models/issuedTransfer';
import { ClientView } from 'src/app/models/client-view';
import { ClientSharedService } from 'src/app/services/client-shared-service';

@Component({
  selector: 'app-expir-area',
  templateUrl: './expir-area.component.html',
  styleUrls: ['./expir-area.component.css']
})
export class ExpirAreaComponent implements OnInit, OnDestroy {
  public credoc: LettreCredit;
  public remdoc: Remdoc;
  public issued: IssuedTransfer;
  public payment: PaymentOperation;
  lastComment: LastComment;
  private initCredocSubscription: Subscription;
  private remdocSubscription: Subscription;
  private issuedSubscription: Subscription;
  private getPaymentSuscription: Subscription;
  private expirDataSubscription: Subscription;
  private dataServiceSubscription: Subscription;
  public id: string;
  public expirationView: string;
  public taskId: string;
  public client: ClientView;
  EMPTY = '';
  constructor(
    private createLCService: CreateLcService,
    private dataService: DataService,
    private remdocService: RemdocService,
    private issuedService: IssuedTransferService,
    private expirService: ExpirService,
    private route: ActivatedRoute,
    private clientSharedService: ClientSharedService
  ) {}

  ngOnInit() {
    this.client = this.clientSharedService.getClient();
    this.id = this.route.snapshot.params['id'];
    this.expirDataSubscription = this.expirService.currentExpirFormData.subscribe(
      data => {
        this.expirationView = data.expirationView;
      }
    );
    this.dataServiceSubscription = this.dataService.currentData.subscribe(
      data => {
        switch (data.product) {
          case Products.CREDOC_IMPORT:
            this.getPayment(+data.paymentId);
            this.initCredocSubscription = this.createLCService
              .initCredoc(+data.lcId, this.client.id)
              .subscribe(credoc => {
                this.credoc = credoc;
              });
            break;
          case Products.REMDOC_IMPORT:
            this.getPayment(+data.paymentId);
            this.remdocSubscription = this.remdocService
              .getRemdoc(+data.lcId, this.client.id)
              .subscribe(res => {
                this.remdoc = res;
              });
            break;
          case Products.TRANSFER_ISSUED:
            this.issuedSubscription = this.issuedService
              .getById(+data.lcId, this.client.id)
              .subscribe(res => {
                this.issued = res;
              });
            break;
        }
        this.taskId = data.taskId;
        this.lastComment = data.lastComment;
      }
    );
  }

  getPayment(paymentId: number) {
    this.getPaymentSuscription = this.createLCService
      .getPayment(paymentId, this.client.id)
      .subscribe(payment => {
        this.payment = payment;
      });
  }

  ngOnDestroy(): void {
    this.expirService.changeExpirFormData({
      expirationView: null
    });
    if (this.initCredocSubscription) {
      this.initCredocSubscription.unsubscribe();
    }
    this.dataService.changeData({
      product: null,
      summary: false,
      paymentId: null,
      lcId: null,
      taskId: null,
      taskDefinitionKey: null,
      lastComment: { fullMessage: null, time: null, userId: null },
      action: null,
      decision: null
    });
    if (this.dataServiceSubscription) {
      this.dataServiceSubscription.unsubscribe();
    }
    if (this.remdocSubscription) {
      this.remdocSubscription.unsubscribe();
    }
    if (this.expirDataSubscription) {
      this.expirDataSubscription.unsubscribe();
    }
    if (this.getPaymentSuscription) {
      this.getPaymentSuscription.unsubscribe();
    }
    if (this.issuedSubscription) {
      this.issuedSubscription.unsubscribe();
    }
  }
}
