import { Component, OnInit, OnDestroy } from "@angular/core";
import { Beneficiary } from "src/app/models/beneficiary";
import { Bank } from "src/app/models/bank";
import { Subscription, of, iif } from "rxjs";
import { BeneficiaryService } from "src/app/services/beneficiary.service";
import { Router, ActivatedRoute } from "@angular/router";
import { mergeMap } from "rxjs/operators";
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl
} from "@angular/forms";
import { Util } from "src/app/util";
import { ClientView } from "src/app/models/client-view";
import { ClientSharedService } from "src/app/services/client-shared-service";

@Component({
  selector: "app-beneficiary-form",
  templateUrl: "./form.component.html",
  styleUrls: ["./form.component.css"]
})
export class FormComponent implements OnInit, OnDestroy {
  model: Beneficiary;
  title: string;
  id_benef: number;
  private getBeneficiarySubscription: Subscription;
  public client: ClientView;
  benefFormGroup: FormGroup;
  constructor(
    private beneficiaryService: BeneficiaryService,
    private router: Router,
    private route_active: ActivatedRoute,
    private fb: FormBuilder,
    private clientSharedService: ClientSharedService
  ) {}
  config = {
    clickable: true,
    maxFiles: 100,
    autoReset: null,
    errorReset: null,
    cancelReset: null,
    autoProcessQueue: false,
    autoQueue: false,
    previewsContainer: ".dropzone-previews",
    acceptedFiles: "image/*,.xlsx,.xls,.pdf,.doc,.docx"
  };
  ngOnInit() {
    this.client = this.clientSharedService.getClient();
    this.model = new Beneficiary();
    this.model.bank = new Bank();
    this.title = `Création d'un nouveau bénificiaire`;

    this.benefFormGroup = this.fb.group({
      name: [null, [Validators.required, Util.specialCharValidator()]],
      account: ['', [Util.specialCharValidator()]],
      iban: [null, [Validators.required, Util.specialCharValidator()]],
      address: [null, [Validators.required, Util.specialCharValidator()]],
      bankName: [null, [Validators.required, Util.specialCharValidator()]],
      bankCode: [null, [Validators.required, Util.specialCharValidator()]],
      bankAddress: [null, [Validators.required, Util.specialCharValidator()]],
      bankCountry: [null, [Validators.required, Util.specialCharValidator()]]
    });

    this.id_benef = this.route_active.snapshot.params.id;
    if (this.id_benef) {
      this.getBeneficiarySubscription = this.beneficiaryService
        .get(this.id_benef, this.client.id)
        .subscribe((benef: Beneficiary) => {
          this.model = benef;
          this.benefFormGroup.get("name").setValue(this.model.name);
          this.benefFormGroup.get("account").setValue(this.model.account);
          this.benefFormGroup.get("iban").setValue(this.model.iban);
          this.benefFormGroup.get("address").setValue(this.model.address);
          this.benefFormGroup.get("bankName").setValue(this.model.bank.name);
          this.benefFormGroup.get("bankCode").setValue(this.model.bank.code);
          this.benefFormGroup
            .get("bankAddress")
            .setValue(this.model.bank.address);
          this.benefFormGroup
            .get("bankCountry")
            .setValue(this.model.bank.country);
        });
    }
  }

  get name(): FormControl {
    return this.benefFormGroup.get("name") as FormControl;
  }
  get account(): FormControl {
    return this.benefFormGroup.get("account") as FormControl;
  }
  get iban(): FormControl {
    return this.benefFormGroup.get("iban") as FormControl;
  }
  get address(): FormControl {
    return this.benefFormGroup.get("address") as FormControl;
  }
  get bankName(): FormControl {
    return this.benefFormGroup.get("bankName") as FormControl;
  }
  get bankCode(): FormControl {
    return this.benefFormGroup.get("bankCode") as FormControl;
  }
  get bankAddress(): FormControl {
    return this.benefFormGroup.get("bankAddress") as FormControl;
  }
  get bankCountry(): FormControl {
    return this.benefFormGroup.get("bankCountry") as FormControl;
  }

  ngOnDestroy(): void {
    if (this.getBeneficiarySubscription) {
      this.getBeneficiarySubscription.unsubscribe();
    }
  }

  onLogoChange(event) {
    this.model.logo = event.target.files[0];
  }
  saveBeneficiary() {
    this.mapFormToModel();
    of(this.id_benef)
      .pipe(
        mergeMap(id =>
          iif(
            () => this.id_benef === undefined,
            this.beneficiaryService.saveBenficiary(
              Object.assign({}, this.model),
              this.client.id
            ),
            this.beneficiaryService.update(Object.assign({}, this.model), this.client.id)
          )
        )
      )
      .subscribe(
        res => {
          this.router.navigate(["/beneficiary/manage"]);
        },
        err => {

        }
      );
  }
  isBenefFormValid(): boolean {
    return this.benefFormGroup.valid;
  }
  mapFormToModel() {
    this.model.name = this.name.value;
    this.model.address = this.address.value;
    this.model.account = this.account.value;
    this.model.iban = this.iban.value;
    this.model.bank.name = this.bankName.value;
    this.model.bank.address = this.bankAddress.value;
    this.model.bank.code = this.bankCode.value;
    this.model.bank.country = this.bankCountry.value;
  }
}
