import { Component, OnInit, Input } from '@angular/core';
import { ClientView } from 'src/app/models/client-view';
import { IssuedTransfer } from 'src/app/models/issuedTransfer';
import { ClientSharedService } from 'src/app/services/client-shared-service';
import { ClientService } from 'src/app/services/client.service';
import { Util } from 'src/app/util';

@Component({
  selector: 'app-issued-recap',
  templateUrl: './issued-recap.component.html',
  styleUrls: ['./issued-recap.component.css']
})
export class IssuedRecapComponent implements OnInit {
  @Input() issuedTransfer: IssuedTransfer;
  FINANCIAL = 'FINANCIAL';
  applicantModel: { applicant: any; accountClient: string } = {
    applicant: null,
    accountClient: null
  };
  public client: ClientView;
  constructor(
    private clientService: ClientService,
    private clientSharedService: ClientSharedService
    ) {}

  ngOnInit() {
    this.client = this.clientSharedService.getClient();
    this.clientService.getClient(this.client.id).subscribe(client => {
      this.applicantModel.applicant = client;
      this.applicantModel.accountClient = this.issuedTransfer.accountNumber;
      this.clientService.getBeneficiaries(this.client.id).subscribe(beneficiaries => {
        const benefs = beneficiaries.filter(
          b => b.id === this.issuedTransfer.beneficiaryId
        );
        if (benefs && benefs.length) {
          this.issuedTransfer['beneficiary'] = benefs[0];
        }
      });
    });
  }
  getWithholdingText(code): string {
    return Util.getWithholdingText(code);
  }
}
