export class Negociation {
  public id: number;
  public negotiatedAmount: number;
  public ticketReference: string;
  public indicativeExchangeRate: string;
  public colleague: string;
  public valueDate: Date;
  public paymentId: number;
  public refinancingExpirationId: number;
  constructor(public negociationType: string) {}
}
