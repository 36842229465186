import { Component, Input, OnInit } from '@angular/core';
import { LettreCredit } from 'src/app/models/lettre-credit';

@Component({
  selector: 'app-create-goods',
  templateUrl: './create-goods.component.html',
  styleUrls: ['./create-goods.component.css']
})
export class CreateGoodsComponent implements OnInit {
  @Input() public model: LettreCredit;
  private isImporttationTitleValid = false;
  ngOnInit(): void {}

  addTittle() {
    this.model.importationTitles.push('');
  }

  isSectionValid(): boolean {
    return this.isImporttationTitleValid;
  }
  importationTitlesTrackBy(index: number, obj: any): any {
    return index;
  }
}
