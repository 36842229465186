import { Component, Input } from '@angular/core';
import { LettreCredit } from 'src/app/models/lettre-credit';

@Component({
  selector: 'app-create-benef-bank',
  templateUrl: './create-benef-bank.component.html',
  styleUrls: ['./create-benef-bank.component.css']
})
export class CreateBenefBankComponent {
  @Input() public model: LettreCredit;
}
