export enum TransDoc {
  maritimeKnowledge = 0,
  InternationalConsignmentNote = 1,
  FlightLettter = 2
}

export class TransportionDocs {
  public type: string;
  public establishedBy: string;
  constructor() {}
}

export class CommecialInvoice {
  public originals: number;
  public copies: number;
  public additionalConditions: string;
  constructor() {}
}
export class OtherDoc {
  public description: string;
  public copies: number;
  public establishedBy: string;
  public deliveredBy: string;
  constructor() {}
}
export class SubmitionDeadlineMethod {
  constructor(
    public respectingValidityDate: boolean,
    public daysCount: number
  ) {}
}

export class BenefDocs {
  constructor(
    public commInv: CommecialInvoice,
    public transportationDocs: TransportionDocs,
    public packingList: OtherDoc,
    public weightNote: OtherDoc,
    public healthCertif: OtherDoc,
    public originCertif: OtherDoc,
    public otherDocs: string,
    public submissionDeadlineMethod: SubmitionDeadlineMethod
  ) {}
}
