import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { ReceivedTransfer } from 'src/app/models/receivedTransfer';
import { ReceivedTransferService } from 'src/app/services/received-transfer.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertComponent } from 'src/app/core/alert/alert.component';
import { DialogSignComponent } from 'src/app/core/dialog-sign/dialog-sign.component';
import { Subscription } from 'rxjs';
import { Util } from 'src/app/util';
import { ClientSharedService } from 'src/app/services/client-shared-service';
import { ClientView } from 'src/app/models/client-view';

@Component({
  selector: 'app-rt-validation',
  templateUrl: './rt-validation.component.html',
  styleUrls: ['./rt-validation.component.css']
})
export class RtValidationComponent implements OnInit, OnDestroy {
  receivedTransfer: ReceivedTransfer;
  public client: ClientView;
  signVariables: { dpResponse: string; challengeKey: string };
  validatorCilcked: { button: string };
  signMessage =
    'Vous êtes sur le point de signer cette opération. Entrer la code de signature.';
  @ViewChild('rt_validator_alert')
  rtValidatorAlert: AlertComponent;
  getRtSubscription: Subscription;
  @ViewChild('app_dialog_rt_sign')
  appDialogPaymentSign: DialogSignComponent;
  SPACE = '  ';
  tempRejectMessage =
    'Veuillez saisir ci-dessous votre motif pour valider votre choix.';
  confirmRejectMessage =
    'Vous êtes sur le point de rejecter provisoirement ces instructions. Êtes-vous sûr de vouloir continuer ?';
  validatePaymentMessage =
    'Quelle est votre décision concernant ces instructions de paiements ?';
  constructor(
    private router: Router,
    private rtService: ReceivedTransferService,
    private route: ActivatedRoute,
    private clientSharedService: ClientSharedService
  ) {}

  ngOnInit() {
    this.client = this.clientSharedService.getClient();
    this.signVariables = { challengeKey: null, dpResponse: null };
    this.validatorCilcked = { button: '' };
    this.getRt(+this.route.snapshot.params['id']);
  }

  getRt(id: number) {
    this.rtService.getRt(id, this.client.id).subscribe(rt => {
      this.receivedTransfer = rt;
    });
  }

  cancelValidation() {
    this.validatorCilcked.button = '';
  }

  getNegociationTypeText(type): string {
    return Util.getNegociationTypeText(type);
  }

  onValidateRt(action) {
    if (action === 'VALIDATE') {
      this.rtService
        .validateRt(
          this.receivedTransfer,
          this.signVariables.dpResponse,
          this.signVariables.challengeKey,
          this.client.id
        )
        .subscribe(
          () => {
            this.rtValidatorAlert.snackbarData.display = {
              isDisplay: true
            };
            this.rtValidatorAlert.snackbarData.message =
              'Le virement reçu a bien été signé.';
            this.rtValidatorAlert.snackbarData.reference = this.receivedTransfer.requestId;
            setTimeout(() => {
              this.rtValidatorAlert.snackbarData.display = {
                isDisplay: false
              };
              this.returnToTasks();
            }, 2000);
          },
          error => this.appDialogPaymentSign.onRefresh(null)
        );
    } else if (action === 'REJECT') {
      this.rtService
        .rejectRt(this.receivedTransfer, this.client.id)
        .subscribe(() => this.returnToTasks());
    }
  }

  returnToTasks() {
    this.router.navigate(['/tasks']);
  }

  ngOnDestroy(): void {
    if (this.getRtSubscription) {
      this.getRtSubscription.unsubscribe();
    }
  }
}
