import { Component, OnInit, Input } from '@angular/core';
import { LettreCredit } from 'src/app/models/lettre-credit';

@Component({
  selector: 'app-summary-lc-beneficiary',
  templateUrl: './beneficiary.component.html',
  styleUrls: ['./beneficiary.component.css']
})
export class SummaryLcBeneficiaryComponent implements OnInit {
  @Input() displayBankRequired: boolean;
  @Input() model: LettreCredit;

  constructor() {}

  ngOnInit() {}
}
