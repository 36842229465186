import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Page } from 'src/app/models/page';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.css']
})
export class PaginationComponent implements OnInit {
  @Input() page: Page;
  @Output() getPage = new EventEmitter<void>();
  constructor() {}
  onNext() {
    if (this.page.currentPage < this.page.totalPages - 1) {
      this.page.currentPage += 1;
      return this.getPage.emit();
    }
  }
  onPrevious() {
    if (this.page.currentPage > 0) {
      this.page.currentPage -= 1;
      return this.getPage.emit();
    }
  }
  ngOnInit() {}
}
