import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, iif, of } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { RefinancingExpiration } from '../models/refinancingExpiration';
import { mergeMap } from 'rxjs/operators';
import { Document } from '../models/document';
import { DocumentService } from './document.service';
import { environment } from 'src/environments/environment';
const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json'
  })
};

@Injectable({
  providedIn: 'root'
})
export class ExpirService {
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };

  private expirFormData = new BehaviorSubject({
    expirationView: null
  });
  currentExpirFormData = this.expirFormData.asObservable();
  constructor(
    private http: HttpClient,
    private documentService: DocumentService
  ) {}

  changeExpirFormData(data: { expirationView: string }) {
    this.expirFormData.next(data);
  }

  submitNegociations(expiration: RefinancingExpiration, clientId: number): Observable<void> {
    return this.http
      .post<RefinancingExpiration>(
        `${environment.url}/refinancing/expired/treatement/client/${clientId}`,
        expiration,
        httpOptions
      )
      .pipe(
        mergeMap(expirationResp =>
          iif(
            () => expiration.documentList.length > 0,
            this.uploadExpirationDocs(expiration.id, expiration.documentList, clientId),
            of(null)
          )
        )
      );
  }

  uploadExpirationDocs(expirationId: number, documents: Document[], clientId: number) {
    return this.documentService.uploadDocs(
      documents,
      `${environment.url}/refinancing/uploadDocuments/${expirationId}/client/${clientId}`
    );
  }

  getExpiration(expirationId: number, clientId: number): Observable<RefinancingExpiration> {
    return this.http.get<RefinancingExpiration>(
      `${environment.url}/refinancing/expired/${expirationId}/client/${clientId}`
    );
  }

  signExpiration(id, dpResponse, challengeKey, clientId: number): Observable<{}> {
    return this.http.post(
      `${environment.url}/refinancing/expired/validator/accept/client/${clientId}`,
      {
        id,
        dpResponse,
        challengeKey
      },
      this.httpOptions
    );
  }

  tempRejectExpiration(id, comment, clientId: number) {
    return this.http.post(
      `${environment.url}/refinancing/expired/validator/reject/client/${clientId}`,
      {
        id,
        comment
      },
      this.httpOptions
    );
  }
}
