import { Injectable } from '@angular/core';
import { Client } from '../models/client';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Beneficiary } from '../models/beneficiary';
import { ClientView } from '../models/client-view';
import { environment } from 'src/environments/environment';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json'
  })
};

@Injectable({
  providedIn: 'root'
})
export class ClientService {
  constructor(private http: HttpClient) {}

  getClient(clientId: number): Observable<Client> {
    return this.http.get<Client>(
      `${environment.url}/clients/client/${clientId}`,
      httpOptions
    );
  }

  getBeneficiaries(clientId: number): Observable<Array<Beneficiary>> {
    return this.http.get<Array<Beneficiary>>(
      `${environment.url}/beneficiaries/client/${clientId}/valid`,
      httpOptions
    );
  }

  getClients(): Observable<ClientView[]> {
    return this.http.get<ClientView[]>(
      `${environment.url}/clients`,
      httpOptions
    );
  }

  getCustomerClient(): Observable<any> {
    return this.http.get<any>(
      `${environment.urlPortal}${environment.servicePortal}clients/selected-customer-client`,
      {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
        }),
        params : new HttpParams().set('username', localStorage.getItem('username') ? localStorage.getItem('username').toLowerCase() : null)
      }
    );
  }
}
