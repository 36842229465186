export class VndError {
  readonly message: string;
  logref: string;
  readonly links: Array<any>;
  readonly exception;

  constructor(init: {
    message?: string;
    logref?: string;
    links?: Array<any>;
    exception?: string;
  }) {
    this.message = init.message;
    this.logref = init.logref;
    this.links = init.links;
    this.exception = init.exception;
  }
}
