import { Modele } from 'src/app/models/modele';
import { LettreCredit } from 'src/app/models/lettre-credit';
import { LcUtil } from '../lcUtil';

export class CreateUtil {
  public static mapModeleToCredoc(
    modele: Modele,
    credoc: LettreCredit
  ): LettreCredit {
    modele.name = null;
    modele.id = null;
    const modeleKeys = Object.keys(modele);
    modeleKeys.map(key => {
      credoc[key] = modele[key];
    });
    return LcUtil.completeModel(credoc);
  }

  public static mapCredocToModele(
    credoc: LettreCredit,
    modeleName: string
  ): Modele {
    const modele: Modele = {
      ...credoc,
      name: modeleName,
      fromAcceptancePayment: null,
      fromOtherAcceptancePayment: null
    };
    modele['taskId'] = null;
    modele['requestId'] = null;
    modele['documents'] = null;
    modele['documentList'] = null;
    modele['event'] = null;
    modele['addDate'] = null;
    modele['assignee'] = null;
    modele['credocImportParentId'] = null;
    modele['credocImportChildId'] = null;
    modele['operation'] = null;
    modele['referenceWF'] = null;
    modele['status'] = null;
    modele['comment'] = null;
    modele['parent'] = null;
    modele['title'] = null;
    modele['paymentTypeSectionChanged'] = null;
    modele['importationTitlesChanged'] = null;
    return modele;
  }
}
