import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { RemdocService } from 'src/app/services/remdoc.service';
import { Remdoc } from 'src/app/models/remdoc';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertComponent } from 'src/app/core/alert/alert.component';
import { DataService } from 'src/app/services/data.service';
import { Subscription } from 'rxjs';
import { DialogSignComponent } from 'src/app/core/dialog-sign/dialog-sign.component';
import { ClientView } from 'src/app/models/client-view';
import { ClientSharedService } from 'src/app/services/client-shared-service';

@Component({
  selector: 'app-recap-validator',
  templateUrl: './recap-validator.component.html',
  styleUrls: ['./recap-validator.component.css']
})
export class RemdocRecapValidatorComponent implements OnInit, OnDestroy {
  id: number;
  remdoc: Remdoc;
  decision: string = null;
  @ViewChild('validateRemdocAlert') validateRemdocAlert: AlertComponent;
  @ViewChild('app_dialog_remdoc_sign') appDialogRemdocSign: DialogSignComponent;
  snackbarMessage = 'La remdoc a bie été signée.';
  private dataServiceSubscription: Subscription;
  private getRemdocSubscription: Subscription;
  ACCEPTATION = 'Acceptation';
  message = `Quelle est votre décision concernant cette opération suite à l'acceptation de votre initiateur ?`;
  signMessage =
    'Vous êtes sur le point de signer cette opération. Entrer la code de signature.';
  actionButtonClicked = '';
  VALIDATE = 'validate';
  REJECT = 'reject';
  EMPTY = '';
  public client: ClientView;
  public commentObj: {
    comment: string;
    challengeKey: string;
    dpResponse: string;
  };
  validateMessage =
    'Vous êtes sur le point de valider cette décision. Êtes-vous sûr de vouloir continuer ?';
  rejectMessage =
    'Vous êtes sur le point de rejecter cette décision. Êtes-vous sûr de vouloir continuer ?';
  constructor(
    private remdocService: RemdocService,
    private route: ActivatedRoute,
    private router: Router,
    private dataService: DataService,
    private clientSharedService: ClientSharedService
  ) {}

  ngOnInit() {
    this.client = this.clientSharedService.getClient();
    this.commentObj = { comment: null, dpResponse: null, challengeKey: null };
    this.terminateValidation = this.terminateValidation.bind(this);
    this.dataServiceSubscription = this.dataService.currentData.subscribe(
      data => {
        this.decision = data.decision;
      }
    );
    this.id = this.route.snapshot.params['id'];
    this.getRemdoc();
  }

  confirmRejection() {
    return this.reject(this.terminateValidation);
  }

  confirmValidation() {
    return this.validate(this.terminateValidation);
  }

  ngOnDestroy(): void {
    this.dataService.changeData({
      product: null,
      summary: false,
      taskId: null,
      paymentId: null,
      lcId: null,
      taskDefinitionKey: null,
      lastComment: { fullMessage: null, time: null, userId: null },
      action: null,
      decision: null
    });
    if (this.dataServiceSubscription) {
      this.dataServiceSubscription.unsubscribe();
    }
    if (this.getRemdocSubscription) {
      this.getRemdocSubscription.unsubscribe();
    }
  }

  terminateValidation() {
    this.validateRemdocAlert.openAlert(
      this.snackbarMessage,
      this.remdoc.requestId
    );
    setTimeout(() => {
      this.validateRemdocAlert.closeAlert();
      this.router.navigate(['/tasks']);
    }, 1000);
  }

  validate(callback) {
    this.remdocService
      .validateRemdocDecision(
        this.id,
        this.commentObj.dpResponse,
        this.commentObj.challengeKey
      )
      .subscribe(callback, error => this.appDialogRemdocSign.onRefresh(null));
  }

  reject(callback) {
    this.remdocService.rejectRemdocDecision(this.id).subscribe(callback);
  }
  cancelValidation() {
    this.actionButtonClicked = this.EMPTY;
  }
  getRemdoc() {
    this.getRemdocSubscription = this.remdocService
      .getRemdoc(this.id, this.client.id)
      .subscribe(res => {
        this.remdoc = res;
      });
  }
}
