import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { IssuedTransfer } from 'src/app/models/issuedTransfer';
import { IssuedTransferService } from 'src/app/services/issued-transfer.service';
import { RefinancingExtension } from 'src/app/models/refinancingExtension';

@Component({
  selector: 'app-issued-prorog-list-recap',
  templateUrl: './issued-prorog-list-recap.component.html',
  styleUrls: ['./issued-prorog-list-recap.component.css']
})
export class IssuedProrogListRecapComponent implements OnInit, OnDestroy {
  issuedSeviceDataSubscription: Subscription;
  issued: IssuedTransfer;
  extId: number;
  constructor(private issuedService: IssuedTransferService) {}

  ngOnInit() {
    this.issuedService.currentData.subscribe(data => {
      this.issued = data.issued;
      this.extId = data.extId;
    });
  }

  ngOnDestroy(): void {
    this.issuedService.changeData({ extId: null, issued: null });
    if (this.issuedSeviceDataSubscription) {
      this.issuedSeviceDataSubscription.unsubscribe();
    }
  }
}
