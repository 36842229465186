import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { IssuedTransfer } from 'src/app/models/issuedTransfer';
import { ActivatedRoute, Router } from '@angular/router';
import { IssuedTransferService } from 'src/app/services/issued-transfer.service';
import { AlertComponent } from 'src/app/core/alert/alert.component';
import { DialogSignComponent } from 'src/app/core/dialog-sign/dialog-sign.component';
import { Subscription } from 'rxjs';
import { ClientView } from 'src/app/models/client-view';
import { ClientSharedService } from 'src/app/services/client-shared-service';

@Component({
  selector: 'app-issued-validation',
  templateUrl: './issued-validation.component.html',
  styleUrls: ['./issued-validation.component.css']
})
export class IssuedValidationComponent implements OnInit, OnDestroy {
  issuedTransfer: IssuedTransfer;
  message = `Quelle est votre décision concernant cette opération suite à l'acceptation de votre initiateur ?`;
  signMessage =
    'Vous êtes sur le point de signer cette opération. Entrer la code de signature.';
  VALIDATE = 'validate';
  REJECT = 'reject';
  TEMP_REJECT = 'temp_reject';
  EMPTY = '';
  actionButtonClicked: { button: string } = { button: this.EMPTY };
  snackbarMessage = "L'opération a été rejetée";
  rejectProviMessage = "L'opération a été rejetée provisoirement";
  validationMessage = "L'opération a bien été signée";
  validationButton = {
    button: null
  };
  public client: ClientView;
  private getIssuedSubscription: Subscription;
  @ViewChild('validateIssuedAlert') validateIssuedAlert: AlertComponent;
  @ViewChild('app_dialog_issued_sign') appDialogIssuedSign: DialogSignComponent;
  public commentObj: {
    comment: string;
    challengeKey: string;
    dpResponse: string;
  };
  validateMessage =
    'Vous êtes sur le point de valider cette décision. Êtes-vous sûr de vouloir continuer ?';
  rejectMessage =
    'Vous êtes sur le point de rejecter cette décision. Êtes-vous sûr de vouloir continuer ?';
  confirmRejectMessage =
    'Vous êtes sur le point de rejecter définitivement ce virement émis.';
  confirmTempRejectMessage =
    'Vous êtes sur le point de rejecter provisoirement ce virement émis.';
  constructor(
    private activatdRoute: ActivatedRoute,
    private issuedTransferService: IssuedTransferService,
    private issuedService: IssuedTransferService,
    private router: Router,
    private clientSharedService: ClientSharedService
  ) {}

  ngOnInit() {
    this.client = this.clientSharedService.getClient();
    this.commentObj = { comment: null, dpResponse: null, challengeKey: null };
    const id = this.activatdRoute.snapshot.params['id'];
    this.getIssuedSubscription = this.issuedTransferService
      .getById(id, this.client.id)
      .subscribe(issuedTransfer => {
        this.issuedTransfer = issuedTransfer;
      });
  }

  validate() {
    this.issuedService
      .validateIssuedTransfer(
        this.issuedTransfer.id,
        this.commentObj.dpResponse,
        this.commentObj.challengeKey,
        this.client.id
      )
      .subscribe(
        () => this.terminateAcceptValidation(this.issuedTransfer.requestId),
        error => this.appDialogIssuedSign.onRefresh(null)
      );
  }

  reject() {
    this.issuedService
      .rejectIssuedTransfer(this.issuedTransfer.id, this.commentObj.comment, this.client.id)
      .subscribe(() => this.terminateValidation(this.issuedTransfer.requestId));
  }
  tempReject() {
    this.issuedService
      .tempRejectIssuedTransfer(this.issuedTransfer.id, this.commentObj.comment, this.client.id)
      .subscribe(() => this.terminateRejetProvisoire(this.issuedTransfer.requestId));
  }
  cancelValidation() {
    this.actionButtonClicked.button = this.EMPTY;
  }
  terminateValidation(requestId: string) {
    this.validateIssuedAlert.openAlert(this.snackbarMessage, requestId);
    setTimeout(() => {
      this.validateIssuedAlert.closeAlert();
      this.router.navigate(['/tasks']);
    }, 1000);
  }

  terminateRejetProvisoire(requestId: string) {
    this.validateIssuedAlert.openAlert(this.rejectProviMessage, requestId);
    setTimeout(() => {
      this.validateIssuedAlert.closeAlert();
      this.router.navigate(['/tasks']);
    }, 1000);
  }

  terminateAcceptValidation(requestId: string) {
    this.validateIssuedAlert.openAlert(this.validationMessage, requestId);
    setTimeout(() => {
      this.validateIssuedAlert.closeAlert();
      this.router.navigate(["/tasks"]);
    }, 1000);
  }

  ngOnDestroy(): void {
    if (this.getIssuedSubscription) {
      this.getIssuedSubscription.unsubscribe();
    }
  }
}
