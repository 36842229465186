import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { LettreCredit } from 'src/app/models/lettre-credit';
import { CreateLcService } from 'src/app/services/create-lc.service';
import { Subscription } from 'rxjs';
import { Products } from 'src/app/util';
import { ClientView } from 'src/app/models/client-view';
import { ClientSharedService } from 'src/app/services/client-shared-service';

@Component({
  selector: 'app-create-applicant',
  templateUrl: './create-applicant.component.html',
  styleUrls: ['./create-applicant.component.css']
})
export class CreateApplicantComponent implements OnInit, OnDestroy {
  @Input() public model: LettreCredit;
  @Input() public product: string;
  private getAccountsSubscription: Subscription;
  public accounts: string[] = [];
  @Input() client: ClientView;

  constructor(
    private createLCService: CreateLcService,
    private clientSharedService: ClientSharedService
    ) {}
  ngOnInit() {
    this.client = this.clientSharedService.getClient();
    switch (this.product) {
      case Products.TRANSFER_ISSUED:
        this.getAccountsSubscription = this.createLCService
          .getTransferIssuedAccounts(this.client.id)
          .subscribe(
            accounts => {
              this.accounts = accounts;
            },
            _err => {}
          );
        break;
      case Products.CREDOC_IMPORT:
        this.getAccountsSubscription = this.createLCService
          .getCredocImportAccounts(this.client.id)
          .subscribe(
            accounts => {
              this.accounts = accounts;
            },
            _err => {}
          );
        break;
    }
  }

  isAccountSelectionValid(): boolean {
    return this.accounts.includes(this.model.accountClient);
  }

  ngOnDestroy(): void {
    if (this.getAccountsSubscription) {
      this.getAccountsSubscription.unsubscribe();
    }
  }
}
