import { Component, Input } from '@angular/core';
import { LettreCredit } from 'src/app/models/lettre-credit';

@Component({
  selector: 'app-create-beneficiary',
  templateUrl: './create-beneficiary.component.html',
  styleUrls: ['./create-beneficiary.component.css']
})
export class CreateBeneficiaryComponent {
  @Input() public model: LettreCredit;
  @Input() public bankRequired: boolean;
  onSelectChange(event) {
    this.model.beneficiary = this.model.applicant.beneficiaries.filter(
      benef => {
        return benef['id'] === parseInt(event, 10);
      }
    )[0];
  }
}
