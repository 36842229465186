import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { PaymentOperation } from 'src/app/models/paymentOperation';
import { LettreCredit } from 'src/app/models/lettre-credit';
import { Util, NegociationTypes } from 'src/app/util';
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormArray,
  FormControl
} from '@angular/forms';
import { ExpirValidators } from '../expir-form/validators';
import { Router } from '@angular/router';
import { Negociation } from 'src/app/models/negociation';
import { AlertComponent } from 'src/app/core/alert/alert.component';
import { PaymentValidators } from '../../payment-operation/validators/validators';
import { ExpirService } from 'src/app/services/expir.service';
import { RefinancingExpiration } from 'src/app/models/refinancingExpiration';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { ClientView } from 'src/app/models/client-view';
import { ClientSharedService } from 'src/app/services/client-shared-service';
@Component({
  selector: 'app-expir-form',
  templateUrl: './expir-form.component.html',
  styleUrls: ['./expir-form.component.css']
})
export class ExpirFormComponent implements OnInit {
  @Input() payment: PaymentOperation;
  @Input() id: number;
  @Input() taskId: string;
  @Input() model: LettreCredit;
  expiration: RefinancingExpiration;
  @Input() taskDefinitionKey: string;
  @ViewChild('expir_form_alert') expirFormAlert: AlertComponent;
  datePickerConfig = Util.date_input_config;
  expirPaymentForm: FormGroup;
  submitPaymentMessage =
    'Vous êtes sur le point de soumettre les instructions de la tombée de refinancement pour signature.';
  confirmationShown = false;
  public client: ClientView;
  constructor(
    private fb: FormBuilder,
    private router: Router,
    private expirService: ExpirService,
    private localeService: BsLocaleService,
    private clientSharedService: ClientSharedService
  ) { }

  ngOnInit() {
    this.client = this.clientSharedService.getClient();
    this.localeService.use('fr');
    this.expiration = new RefinancingExpiration();
    this.expiration.id = this.id;
    this.getExpiration();
    this.expirPaymentForm = this.fb.group({
      negociations: this.fb.array([], [Validators.required])
    });
  }

  getExpiration() {
    this.expirService
      .getExpiration(this.id, this.client.id)
      .subscribe((expiration: RefinancingExpiration) => {
        this.expiration = expiration;
        if (expiration && expiration.negociations.length <= 0) {
          expiration.negociations.push(
            new Negociation(NegociationTypes.FIXING)
          );
        }
        this.mapExpirationToForm(expiration);
      });
  }

  get negociations(): FormArray {
    return this.expirPaymentForm.get('negociations') as FormArray;
  }

  negociationTrackBy(index: number, obj: any): any {
    return index;
  }

  createNewColleagueForm(
    amountPayment,
    id,
    negociationType,
    indicativeExchangeRate,
    negotiatedAmount,
    ticketReference,
    colleague,
    valueDate,
    expirationId
  ): FormGroup {
    const collFrm: FormGroup = this.fb.group({
      negociationType: [negociationType, [Validators.required]],
      id: [id],
      indicativeExchangeRate: [indicativeExchangeRate],
      negotiatedAmount: [negotiatedAmount],
      ticketReference: [ticketReference],
      colleague: [colleague],
      valueDate: [valueDate],
      expirationId: [expirationId, Validators.required]
    });
    if (negociationType != null) {
      this.setNegociationsValidators(amountPayment, collFrm, negociationType);
    }
    (collFrm.get('negociationType') as FormControl).valueChanges.subscribe(
      newVal => {
        this.setNegociationsValidators(amountPayment, collFrm, newVal);
      }
    );
    return collFrm;
  }

  setNegociationsValidators(
    amountPayment: number,
    collFrm: FormGroup,
    newVal: string
  ) {
    switch (newVal) {
      case NegociationTypes.FIXING:
        collFrm.get('indicativeExchangeRate').clearValidators();
        collFrm.get('negotiatedAmount').clearValidators();
        collFrm.get('ticketReference').clearValidators();
        collFrm.get('colleague').clearValidators();
        collFrm.get('valueDate').clearValidators();
        collFrm.get('indicativeExchangeRate').setValue(null);
        collFrm.get('negotiatedAmount').setValue(null);
        collFrm.get('ticketReference').setValue(null);
        collFrm.get('colleague').setValue(null);
        collFrm.get('valueDate').setValue(null);
        collFrm.get('indicativeExchangeRate').updateValueAndValidity();
        collFrm.get('negotiatedAmount').updateValueAndValidity();
        collFrm.get('ticketReference').updateValueAndValidity();
        collFrm.get('colleague').updateValueAndValidity();
        collFrm.get('valueDate').updateValueAndValidity();
        break;
      case NegociationTypes.NEGOTIATE_SGMA:
        collFrm
          .get('indicativeExchangeRate')
          .setValidators([Validators.required, Validators.min(0)]);
        collFrm
          .get('negotiatedAmount')
          .setValidators([
            Validators.required,
            ExpirValidators.amountNegociationsValidator(amountPayment),
            Util.specialCharValidator(),
            Validators.min(0)
          ]);
        collFrm.get('ticketReference').setValidators([Validators.required,
        Util.specialCharValidator()]);
        collFrm.get('colleague').clearValidators();
        collFrm.get('valueDate').clearValidators();
        collFrm.get('colleague').setValue(null);
        collFrm.get('valueDate').setValue(null);
        collFrm.get('indicativeExchangeRate').updateValueAndValidity();
        collFrm.get('negotiatedAmount').updateValueAndValidity();
        collFrm.get('ticketReference').updateValueAndValidity();
        collFrm.get('colleague').updateValueAndValidity();
        collFrm.get('valueDate').updateValueAndValidity();
        break;
      case NegociationTypes.NEGOTIATE_COLLEAGUE:
        collFrm.get('indicativeExchangeRate').clearValidators();
        collFrm
          .get('indicativeExchangeRate')
          .setValidators([Validators.required, Validators.min(0)]);
        collFrm
          .get('negotiatedAmount')
          .setValidators([
            Validators.required,
            ExpirValidators.amountNegociationsValidator(amountPayment),
            Util.specialCharValidator(),
            Validators.min(0)
          ]);
        collFrm.get('ticketReference').setValidators([Validators.required,
        Util.specialCharValidator()]);
        collFrm.get('colleague').setValidators([Validators.required,
        Util.specialCharValidator()]);
        collFrm
          .get('valueDate')
          .setValidators([Validators.required,
          Util.specialCharValidator(), PaymentValidators.minDate]);
        collFrm.get('indicativeExchangeRate').updateValueAndValidity();
        collFrm.get('negotiatedAmount').updateValueAndValidity();
        collFrm.get('ticketReference').updateValueAndValidity();
        collFrm.get('colleague').updateValueAndValidity();
        collFrm.get('valueDate').updateValueAndValidity();
        break;
    }
  }

  removeNegociation(i: number): void {
    this.negociations.removeAt(i);
  }

  isWithColleague(negociation) {
    return (
      negociation.value.negociationType === NegociationTypes.NEGOTIATE_COLLEAGUE
    );
  }

  isWithSGMA(negociation) {
    return (
      negociation.value.negociationType === NegociationTypes.NEGOTIATE_SGMA
    );
  }

  setRegulationStatus(): string {
    return Util.setStatus('recap', this.expiration.status).text;
  }
  addNegociationControl() {
    const expirationId = this.expiration ? this.expiration.id : null;
    this.negociations.push(
      this.createNewColleagueForm(
        this.expiration.refinancingAmount,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        expirationId
      )
    );
  }

  setMinDate(): Date {
    return new Date();
  }

  cancel() {
    return this.router.navigate(['/tasks']);
  }

  confirm() {
    this.mapFormToExpiration();
    this.submitNegociations();
  }

  submitNegociations() {
    this.expirService.submitNegociations(this.expiration, this.client.id).subscribe(
      _res => {
        this.returnHome();
      },
      err => {
  
      }
    );
  }

  private returnHome() {
    this.expirFormAlert.snackbarData = {
      reference: this.model.requestId,
      message: `L'opération a été soumise pour signature `,
      display: { isDisplay: true }
    };
    setTimeout(() => {
      this.expirFormAlert.snackbarData.display.isDisplay = false;
      this.cancel();
    }, 2000);
  }

  mapFormToExpiration() {
    this.expiration.negociations = this.expirPaymentForm.value.negociations;
  }

  mapExpirationToForm(expiration: RefinancingExpiration) {
    this.expirPaymentForm.setValue({
      negociations: []
    });
    this.negociations.setValue([]);
    expiration.negociations.map((n: Negociation) => {
      this.negociations.push(
        this.createNewColleagueForm(
          expiration.refinancingAmount,
          n.id,
          n.negociationType,
          n.indicativeExchangeRate,
          n.negotiatedAmount,
          n.ticketReference,
          n.colleague,
          new Date(n.valueDate),
          expiration.id
        )
      );
    });
  }
}
