import { Component, OnInit, Input, ViewChild } from "@angular/core";
import { PaymentOperation } from "src/app/models/paymentOperation";
import { ExpirationViews } from "src/app/util";
import { AlertComponent } from "src/app/core/alert/alert.component";
import { RefinancingExpiration } from "src/app/models/refinancingExpiration";
import { ExpirService } from "src/app/services/expir.service";
import { Router } from "@angular/router";
import { DialogSignComponent } from "src/app/core/dialog-sign/dialog-sign.component";
import { ClientView } from "src/app/models/client-view";
import { ClientSharedService } from "src/app/services/client-shared-service";

@Component({
  selector: "app-expir-recap",
  templateUrl: "./expir-recap.component.html",
  styleUrls: ["./expir-recap.component.css"]
})
export class ExpirRecapComponent implements OnInit {
  @Input() id: number;
  public commentObj: {
    comment: string;
    dpResponse: string;
    challengeKey: string;
  };
  @Input() currency: string;
  @Input() taskId: string;
  @Input() payment: PaymentOperation;
  @Input() expirationView: string;
  expiration: RefinancingExpiration;
  public validatorCilcked: { button: string };
  public client: ClientView;
  @ViewChild("expir_recap_alert") expiRecapAlert: AlertComponent;
  @ViewChild("app_dialog_expiration_sign")
  appDialogExpirationSign: DialogSignComponent;
  confirmTempRejectMessage =
    "Vous êtes sur le point de rejeter les instructions de la tombée de refinancement provisoirement.";
  rejectMessage =
    "Veuillez saisir ci-dessous votre motif pour valider votre choix.";
  validateMessage =
    "Vous êtes sur le point de valider les instructions de la tombée de refinancement.";
  EMPTY = "";

  signMessage =
    "Vous êtes sur le point de signer cette opération. Entrer la code de signature.";
  saveSnackbarMessage = `L'opération a été `;
  constructor(
    private expirService: ExpirService,
    private router: Router,
    private clientSharedService: ClientSharedService) {}
  ngOnInit() {
    this.client = this.clientSharedService.getClient();
    this.commentObj = { comment: null, dpResponse: null, challengeKey: null };
    this.validatorCilcked = { button: "" };
    this.expiration = new RefinancingExpiration();
    this.expiration.id = this.id;
    this.getExpiration();
  }

  getExpiration() {
    this.expirService
      .getExpiration(this.id, this.client.id)
      .subscribe((expiration: RefinancingExpiration) => {
        this.expiration = expiration;
      });
  }

  negociationTrackBy(index: number, _obj: any): any {
    return index;
  }

  cancelValidation(_action: string) {
    this.validatorCilcked.button = "";
  }

  onSignAction(action: string) {
    this.expirService
      .signExpiration(
        this.id,
        this.commentObj.dpResponse,
        this.commentObj.challengeKey,
        this.client.id
      )
      .subscribe(this.showSnackbar(action));
  }

  tempRejectExpiration(action: string) {
    this.expirService
      .tempRejectExpiration(this.id, this.commentObj.comment, this.client.id)
      .subscribe(this.showSnackbar(action));
  }

  private showSnackbar(action: string) {
    return _val => {
      this.expiRecapAlert.snackbarData.display.isDisplay = true;
      this.expiRecapAlert.snackbarData.message =
        this.saveSnackbarMessage +
        (action === "CONFIRMED"
          ? " signée"
          : action === "TO_BE_COMPLETED"
          ? " rejetée provisoirement"
          : "");
      setTimeout(() => {
        this.expiRecapAlert.snackbarData.display.isDisplay = false;
        this.returnToList();
      }, 3000);
    };
  }

  returnToList(): void {
    if (this.expirationView === ExpirationViews.EXPIRATION_VALIDATION) {
      this.router.navigate(["/tasks"]);
    }
  }
}
