import { Applicant } from './applicant';
import { Beneficiary } from './beneficiary';
import { CommecialInvoice, TransportionDocs, OtherDoc } from './benefDocs';
import { Document } from './document';
export class LettreCredit {
  public taskId: string;

  public partialExpeditions: boolean;
  public transshipment: boolean;
  public expeditionFrom: string;
  public expeditionTo: string;
  public expeditionLastDate: string;
  public incoterm: string;
  public incotermOther: string;

  public paymentType: string; // single or mixte payment
  public paymentTypeSelected: string;

  public paymentAtSightType: boolean; // PAYMENT_AT_SIGHT or PAYMENT_BY_ACCEPTANCE or PAYMENT_DUE
  public paymentByAcceptanceType: boolean;
  public paymentDueType: boolean;

  public amountSightPayment: number;
  public fromAcceptancePayment: string;
  public fromOtherAcceptancePayment: string;
  public daysAcceptancePayment: number;
  public amountAcceptancePayment: number;
  public initiator: String;
  public daysDuePayment: number;
  public fromDuePayment: string; // DATE_OF_INVOICE or EXPEDITION_DATE
  public amountDuePayment: number;
  public fromOtherDuePayment: string;
  public amount: number;
  public currency: string;
  public applicant: Applicant;
  public goodDescription: string;
  public importationTitles: string[];
  public commercialInvoice: CommecialInvoice;
  public transportationDocument: TransportionDocs;
  public packingList: OtherDoc;
  public weightNote: OtherDoc;
  public sanitaryCertification: OtherDoc;
  public originCertification: OtherDoc;
  public otherRequiredDocuments: string;
  public respectingValidityDate: boolean;
  public submissionDeadlineDays: number;
  public amountToleranceMore: number;
  public amountToleranceLess: number;
  public advance: boolean;
  public amountAdvance: number;
  public documents: Document[] = new Array();
  public documentList = new Array();
  public requestId: string;

  public id: string;
  public event: string;
  public validityLocation: string;
  public validityDate: string;
  public insurance: boolean;
  public transferability: boolean;
  public addDate: Date;
  public confirmation: boolean;
  public importationOfGoods: boolean;
  public serviceProvision: boolean;
  public beneficiaryBankRequired: boolean;

  public beneficiary: Beneficiary;
  public accountClient: string;

  public additionalConditions: string;

  public assignee: string;

  public credocImportParentId: string;
  public credocImportChildId: string;
  public operation: string; // OPENING or MODIFICATION or REALIZATION
  public referenceWF: string;
  public status: string; // EXECUTED or REJECTED
  public comment: string;
  public chargesOutside: string;

  public parent: LettreCredit;
  public title: string;
  public paymentTypeSectionChanged: boolean;
  public importationTitlesChanged: boolean;

  public challengeKey: string;
  public dpResponse: string;

  constructor() {}
}
