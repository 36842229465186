import { Component, OnInit, Input } from '@angular/core';
import { ProrogationRecap } from 'src/app/models/prorogationRecap';
import { Util } from 'src/app/util';
import { ProrogService } from 'src/app/services/prorog.service';
import { ClientSharedService } from 'src/app/services/client-shared-service';
import { ClientView } from 'src/app/models/client-view';

@Component({
  selector: 'app-prorog-inner-recap',
  templateUrl: './prorog-inner-recap.component.html',
  styleUrls: ['./prorog-inner-recap.component.css']
})
export class ProrogInnerRecapComponent implements OnInit {
  @Input() recap: ProrogationRecap;
  @Input() currency: string;
  DAYS = ' Jours';
  @Input() id: number;
  public client: ClientView;
  constructor(
    private prorogService: ProrogService,
    private clientSharedService: ClientSharedService) {}

  ngOnInit() {
    this.client = this.clientSharedService.getClient();
    if (this.id) {
      this.getProrogRecapInfo();
    }
  }

  setStatus(statusCode): string {
    return Util.setStatus('recap', statusCode).text;
  }
  getProrogRecapInfo() {
    this.prorogService.getProrogData(this.id, this.client.id).subscribe(data => {
      this.recap = data;
    });
  }
}
