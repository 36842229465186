import {
  Directive,
  Input,
  HostBinding,
  OnInit,
  TemplateRef,
  ViewContainerRef
} from '@angular/core';
import { AuthorizationService } from '../services/authorization.service';
import { AuthorizedActions } from '../services/AuthorizedActions';
import { environment } from 'src/environments/environment';


@Directive({
  // tslint:disable-next-line:directive-selector
  selector: '[IsAuthorizedTo]'
})
export class IsAuthorizedToDirective implements OnInit {
  private actionName: AuthorizedActions;
  private isAuthorized = false;
  private roleMapping: any = environment.roles_mapping;

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private authorizationService: AuthorizationService
  ) {}

  @Input()
  set IsAuthorizedTo(action: AuthorizedActions) {
    this.actionName = action;
    this.checkPermission();
  }

  ngOnInit() {}

  checkPermission() {
    if (this.actionName === undefined) {
      throw new Error('Action name undefined');
    }
    this.authorizationService.isAuthorizeTo(this.actionName).subscribe(roles => {
      for (const role of roles) {
        if (Object.keys(this.roleMapping).indexOf(role) !== -1) {
          if (this.roleMapping[role].indexOf(this.actionName) !== -1) {
             this.isAuthorized = true;
          }
        }
      }
      if (!this.isAuthorized) {
        this.viewContainer.clear();
      } else {
        this.viewContainer.createEmbeddedView(this.templateRef);
      }
    });
  }
}
