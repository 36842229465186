import { AsyncValidatorFn, AbstractControl } from '@angular/forms';
import { of } from 'rxjs';
import { map } from 'rxjs/operators';
import { CreateLcService } from 'src/app/services/create-lc.service';
import { IssuedTransferFormComponent } from './issued-transfer-form.component';

export class IssuedValidators {

  static amountValidator(
    controlerObject: IssuedTransferFormComponent,
    createLcService: CreateLcService,
    product: string,
    clientId: number
  ): AsyncValidatorFn {
    return (control: AbstractControl) => {
      const currency = control.parent.get('currency').value;
      if (!currency || currency === '') {
        return of(true).pipe(
          map(() => {
            return {
              noCurrency: `Veuillez sélectionner une devise`
            };
          })
        );
      }
      return createLcService
        .verifyAmount(product, control.value, currency, clientId)
        .pipe(
          map((res: { sigingSchemaExist: boolean; valueExchange: number }) => {
            controlerObject.exchangeValueSetter.next(res.valueExchange);
            return !res.sigingSchemaExist
              ? {
                  noSchema: `Plafond dépassé pour ce schéma de signature ou schéma non implémenté`
                }
              : null;
          })
        );
    };
  }
}
