import { LettreCredit } from "./models/lettre-credit";
import { KeycloakService } from "keycloak-angular";

import { environment } from "../environments/environment";
import { RdiTypes } from "./models/rditype";
import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms";
export class Status {
  public static readonly TO_REGULARIZE = "TO_REGULARIZE";
  public static readonly CLOSE_BANK = "CLOSE_BANK";
  public static readonly VALIDATOR_REJECT = "VALIDATOR_REJECT";
  public static readonly TO_REGULARIZE_MO = "TO_REGULARIZE_MO";
  public static readonly TO_VALIDATE = "TO_VALIDATE";
  public static readonly TO_SIGN = "TO_SIGN";
  public static readonly TO_COMPLETE = "TO_COMPLETE";
  public static readonly TO_TREAT = "TO_TREAT";
  public static readonly EXECUTED = "EXECUTED";
  public static readonly BANK_EXECUTE = "BANK_EXECUTE";
  public static readonly REJECTED = "REJECTED";
  public static readonly WAITING_FOR_TREATMENT = "WAITING_FOR_TREATMENT";
  public static readonly VALIDATED = "VALIDATED";
  public static readonly IN_TREATMENT = "IN_TREATMENT";
  public static readonly ACCEPTED = "ACCEPTED";
  public static readonly CANCELED = "CANCELED";
  public static readonly PRESENCE_OF_RESERVES = "PRESENCE_OF_RESERVES";
  public static readonly AUTOMATIC_BANK_REJECT = "AUTOMATIC_BANK_REJECT";
  public static readonly BANK_REJECT = "BANK_REJECT";
  public static readonly MO_REJECT = "MO_REJECT";
  public static readonly WAIT_DATE_EXECUTION = "WAIT_DATE_EXECUTION";
}

export class ProrogationViews {
  public static readonly PROROGATION_INITIATION = "PROROGATION_INITIATION";
  public static readonly PROROGATION_UPDATE = "PROROGATION_UPDATE";
  public static readonly PROROGATION_VALIDATION = "PROROGATION_VALIDATION";
  public static readonly PROROGATION_RECAP = "PROROGATION_RECAP";
}

export class ExpirationViews {
  public static readonly EXPIRATION_INITIATION = "EXPIRATION_INITIATION";
  public static readonly EXPIRATION_VALIDATION = "EXPIRATION_VALIDATION";
  public static readonly EXPIRATION_RECAP = "EXPIRATION_RECAP";
}

export class NegociationTypes {
  public static readonly FIXING = "FIXING";
  public static readonly NEGOTIATE_COLLEAGUE = "NEGOTIATE_COLLEAGUE";
  public static readonly NEGOTIATE_SGMA = "NEGOTIATE_SGMA";
}
export class Operations {
  public static readonly MODIFICATION = "MODIFICATION";
  public static readonly REMDOC_OPENING = "OPENING_REMDOC_IMPORT";
  public static readonly REMDOC_PAYMENT = "PAYMENT_REMDOC_IMPORT";
  public static readonly OPENING = "OPENING";
  public static readonly REALIZATION = "REALIZATION";
  public static readonly PAYMENT = "PAYMENT";
  public static readonly CANCELLATION = "CANCEL";
  public static readonly REFINANCING_EXTENSION = "REFINANCING_EXTENSION";
  public static readonly REMDOC_IMPORT_EXTENSION = "REMDOC_IMPORT_EXTENSION";
  public static readonly CREDOC_IMPORT_EXTENSION = "CREDOC_IMPORT_EXTENSION";
  public static readonly REFINANCING_EXPIRATION = "REFINANCING_EXPIRED";
  public static readonly TRANSFER_RECEIVED = "TRANSFER_RECEIVED";
  public static readonly TRANSFER_ISSUED = "TRANSFER_ISSUED";
  public static readonly TRANSFER_ISSUED_EXTENSION =
    "TRANSFER_ISSUED_EXTENSION";
}

export const Products = {
  REMDOC_IMPORT: "REMDOC_IMPORT",
  CREDOC_IMPORT: "CREDOC_IMPORT",
  TRANSFER_RECEIVED: "TRANSFER_RECEIVED",
  TRANSFER_ISSUED: "TRANSFER_ISSUED"
};

export abstract class Util {
  static displayLoader = false;

  static contextType = [
    {
      code: "CREDOC_IMPORT",
      label: "Crédits Documentaires Import"
    },
    {
      code: "REMDOC_IMPORT",
      label: "Remise Documentaires Import"
    },
    {
      code: "BENEFICIARIE",
      label: "Bénéficiaire"
    },
    {
      code: "TRANSFER_RECEIVED",
      label: "Virement reçu"
    },
    {
      code: "TRANSFER_ISSUED",
      label: "Virement émis"
    }
  ];

  static contextOperationRDType = [
    {
      code: "OPENING",
      label: "Ouverture"
    },
    {
      code: "PAYMENT",
      label: "Réglement"
    },
    {
      code: "REFINANCING",
      label: "Refinancement"
    },
    {
      code: "EXTENSION",
      label: "Prorogation"
    }
  ];

  static contextOperationCDType = [
    ...Util.contextOperationRDType,
    {
      code: "CHANGE",
      label: "Modification"
    },
    {
      code: "CANCELLATION",
      label: "Annulation"
    },
    {
      code: "PRODUCTION",
      label: "Réalisation"
    }
  ];

  static contextOperationBenefType = [
    {
      code: "BENEF_ADD",
      label: "Ajout"
    },
    {
      code: "BENEF_UPDATE",
      label: "Modification"
    },
    {
      code: "BENEF_DELETE",
      label: "Suppression"
    },
    {
      code: "BENEF_ADD_VALIDATION",
      label: "Validation ajout"
    },
    {
      code: "BENEF_UPDATE_VALIDATION",
      label: "Validation modification"
    },
    {
      code: "BENEF_DELETE_VALIDATION",
      label: "Validation suppression"
    },
    {
      code: "BENEF_ADD_REJECT",
      label: "Rejet ajout"
    },
    {
      code: "BENEF_UPDATE_REJECT",
      label: "Rejet modification"
    },
    {
      code: "BENEF_DELETE_REJECT",
      label: "Rejet suppression"
    }
  ];

  static contextOperationVRIType = [
    {
      code: "OPENING",
      label: "Virement reçu"
    }
  ];

  static contextOperationITType = [
    {
      code: "OPENING",
      label: "Ouverture"
    },
    {
      code: "REFINANCING",
      label: "Refinancement"
    },
    {
      code: "EXTENSION",
      label: "Prorogation"
    }
  ];

  static descriptionTraceType = [
    {
      code: "DELETING",
      label: "Suppression"
    },
    {
      code: "SUBMISSION",
      label: "Soumission"
    },
    {
      code: "PROVISIONAL_REJECTION",
      label: "Rejet provisoire"
    },
    {
      code: "DEFINITIVE_REJECTION",
      label: "Rejet définitif"
    },
    {
      code: "REGULARIZATION",
      label: "Régularisation"
    },
    {
      code: "PROVISIONAL_REJECTION",
      label: "Rejet provisoire"
    },
    {
      code: "SIGNATURE",
      label: "Signature"
    },
    {
      code: "REJECT",
      label: "Rejet provisoire"
    },
    {
      code: "TREATMENT",
      label: "Traitement"
    },
    {
      code: "SIGNATURE",
      label: "Signature"
    },
    {
      code: "INIT_CANCELLING",
      label: `Initiation d'annulation`
    },
    {
      code: "VALIDATE_CANCELLING",
      label: `Validation d'annulation`
    },
    {
      code: "REJECT_CANCELLING",
      label: `Rejet d'annulation`
    },
    {
      code: "REJECT_REALIZATION",
      label: "Rejet de réalisation"
    },
    {
      code: "VALIDATE_REALIZATION",
      label: "Validation de réalisation"
    },
    {
      code: "ACCEPT_RESERVE_REALIZATION",
      label: "Accepter réserves de réalisation"
    },
    {
      code: "REJECT_RESERVE_REALIZATION",
      label: "Rejet réserves de réalisation"
    },
    {
      code: "ACCEPT_DECISION_REALIZATION",
      label: "Accepter décision de réalisation"
    },
    {
      code: "REJECT_DECISION_REALIZATION",
      label: "Rejet de décision de réalisation"
    }
  ];

  static charges = [
    {
      code: "OUR_CHARGE",
      label: "A notre charge"
    },
    {
      code: "BENEFICIARY_CHARGES",
      label: "A la charge du bénéficiaire"
    },
    {
      code: "SHARED_CHARGES",
      label: "Charges paratgées"
    }
  ];
  static date_input_config = {
    containerClass: "theme-default",
    customTodayClass: "bs-today",
    showWeekNumbers: false,
    dateInputFormat: "DD/MM/YYYY"
  };
  static paymentTypes = [
    {
      code: "PAYMENT_AT_SIGHT",
      label: "Paiement à vue"
    },
    {
      code: "PAYMENT_DUE",
      label: "Paiement différé"
    },
    {
      code: "PAYMENT_BY_ACCEPTANCE",
      label: "Paiement par acceptation par votre correspontant"
    }
  ];

  public static operations = [
    Operations.CANCELLATION,
    Operations.MODIFICATION,
    Operations.REALIZATION,
    Operations.OPENING,
    Operations.PAYMENT,
    Operations.REFINANCING_EXPIRATION,
    Operations.REFINANCING_EXTENSION,
    Operations.REMDOC_OPENING,
    Operations.REMDOC_PAYMENT,
    Operations.REMDOC_IMPORT_EXTENSION,
    Operations.CREDOC_IMPORT_EXTENSION,
    Operations.TRANSFER_RECEIVED,
    Operations.TRANSFER_ISSUED,
    Operations.TRANSFER_ISSUED_EXTENSION
  ];
  static currencies = [
    "EUR",
    "USD",
    "CAD",
    "GBP",
    "CHF",
    "DKK",
    "SEK",
    "NOK",
    "SAR",
    "KWD",
    "AED",
    "JPY",
    "DZD",
    "TND",
    "RUB",
    "CNY",
    "TRY",
    "CFA",
    "MAD"
  ];

  static witholdings = [
    {
      code: "NO_WITHHOLDING",
      label: "Opération non soumise à la retenue à la source"
    },
    {
      code: "PAID",
      label: "Retenue à la source versée"
    },
    {
      code: "CLIENT_EXPENSE_PAID_BY_CLIENT",
      label: "Retenue à la source à notre charge à verser par nous-mêmes"
    },
    {
      code: "BENEFICIARY_EXPENSE_PAID_BY_BANK",
      label:
        "Retenue à la source à la charge du bénéficiaire à verser par la Banque"
    },
    {
      code: "BENEFICIARY_EXPENSE_PAID_BY_CLIENT",
      label:
        "Retenue à a source à la charge du bénéficiaire à verser par nous-mêmes"
    },
    {
      code: "CLIENT_EXPENSE_PAID_BY_BANK",
      label: "Retenue à la source À notre charge à verser par la Banque"
    }
  ];

  static specialCharacters = [
    "<",
    "!",
    "&",
    "|",
    "$",
    "*",
    ";",
    "^",
    "%",
    "_",
    ">",
    "`",
    "#",
    "@",
    "=",
    '"',
    "~",
    "[",
    "]",
    "{",
    "}",
    "\\"
  ];
  static nonNumericChars = [
    'e', '+', '-', 'E'
  ];
  public static isSubmissionDeadlineDaysCountValid(
    model: LettreCredit
  ): boolean {
    if (model.importationOfGoods) {
      return (
        model.submissionDeadlineDays >= 0 &&
        Util.diffDays(
          model.submissionDeadlineDays,
          model.validityDate,
          model.expeditionLastDate
        )
      );
    } else {
      return (
        model.submissionDeadlineDays >= 0 &&
        Util.diffDays(
          model.submissionDeadlineDays,
          model.validityDate,
          model.addDate
        )
      );
    }
  }
  public static setDecisionText(decision: string) {
    switch (decision) {
      case Status.REJECTED:
        return "Rejet";
      case Status.VALIDATED:
      case Status.EXECUTED:
        return "Acceptation";
      default:
        return decision;
    }
  }
  public static getNegociationTypeText(type: any) {
    switch (type) {
      case NegociationTypes.FIXING:
        return "Fixing";
      case NegociationTypes.NEGOTIATE_COLLEAGUE:
        return "Avec un confrère";
      case NegociationTypes.NEGOTIATE_SGMA:
        return "Avec la SGMA";
      default:
        return type;
    }
  }
  public static seDecisionText(decision) {
    switch (decision) {
      case Status.REJECTED:
        return "Rejet";
      case Status.EXECUTED:
        return "Acceptation";
      default:
        return decision;
    }
  }

  public static diffDays(val: number, date1Num: any, date2Num: any): boolean {
    if (val !== null && date1Num && date2Num) {
      const date1 = new Date(date1Num);
      const date2 = new Date(date2Num);
      const timeDiff = date1.getTime() - date2.getTime();
      const diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24)) - val;
      if (diffDays >= 0) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }
  public static getFormattedDate(dt: Date): string {
    let monthDt: any;
    let dayDt: any;
    dayDt = dt.getDate();
    const yearDt = dt.getFullYear();
    monthDt = dt.getMonth() + 1;
    if (monthDt <= 9) {
      monthDt = "0" + monthDt;
    }
    if (dayDt <= 9) {
      dayDt = "0" + dayDt;
    }
    return yearDt + "-" + monthDt + "-" + dayDt;
  }

  public static getWithholdingText(code) {
    const res = this.witholdings.filter(wh => wh.code === code);
    return res && res.length && res.length > 0 ? res[0].label : null;
  }

  public static compareAmounts(model: LettreCredit): boolean {
    let calculateAmount =
      typeof model.amountDuePayment !== "number" && model.paymentDueType
        ? 0
        : model.amountDuePayment;
    calculateAmount +=
      typeof model.amountAcceptancePayment !== "number" &&
        model.paymentByAcceptanceType
        ? 0
        : model.amountAcceptancePayment;
    calculateAmount +=
      typeof model.amountSightPayment !== "number" && model.paymentAtSightType
        ? 0
        : model.amountSightPayment;
    return calculateAmount === +model.amount;
  }
  public static getProduct(
    requestId: string
  ): { color: string; name: string; icon: string } {
    return requestId.includes("RDI")
      ? {
        color: "#43b1d0",
        name: Products.REMDOC_IMPORT,
        icon: "/assets/img/rdi-icon@2x.png"
      }
      : requestId.includes("LC")
        ? {
          color: "#f28a8a",
          name: Products.CREDOC_IMPORT,
          icon: "/assets/img/cdi-icon@2x.png"
        }
        : requestId.includes("VRI")
          ? {
            color: "#59d2da",
            name: Products.TRANSFER_RECEIVED,
            icon: "/assets/img/vri-icon@2x.png"
          }
          : requestId.includes("VRE")
            ? {
              color: "#a694bf",
              name: Products.TRANSFER_ISSUED,
              icon: "/assets/img/ve-icon@2x.png"
            }
            : null;
  }
  public static setStatus(
    view: string,
    key: string
  ): { text: string; color: string; className: string } {
    switch (key) {
      case Status.TO_COMPLETE:
        return {
          text: view === "tasks" ? "Compléter" : view === "recap" ? "À compléter" : "En cours de saisie",
          color: "#b98fd1",
          className: "to-complete-status"
        };
      case Status.TO_SIGN:
        return {
          text: view === "tasks" ? "Signer" : view === "recap" ? "À signer" : "En cours de signature",
          color: "#6acedd",
          className: "to-sign-status"
        };
      case Status.TO_REGULARIZE:
      case Status.TO_REGULARIZE_MO:
        return {
          text:
            view === "tasks" ? "Régulariser" : view === "recap" ? "À régulariser" : "En cours de régularisation",
          color: "#7e9cf5",
          className: "a-regulariser"
        };
      case Status.EXECUTED:
      case Status.BANK_EXECUTE:
        return {
          text: "Exécuté",
          color: "#6ed3cd",
          className: "en-cours-de-traitement"
        };
      case Status.VALIDATOR_REJECT:
      case Status.BANK_REJECT:
      case Status.REJECTED:
      case Status.MO_REJECT:
        return {
          text: "Rejeté",
          color: "#ff465f",
          className: "en-cours-de-traitement"
        };
      case Status.CLOSE_BANK:
        return {
          text: "Clôturée",
          color: "#ff465f",
          className: "en-cours-de-traitement"
        };
      case Status.IN_TREATMENT:
        return {
          text: "En cours de traitement",
          color: "#7b9bd5",
          className: "to-complete-status"
        };
      case Status.WAITING_FOR_TREATMENT:
        return {
          text: "En attente de traitement",
          color: "#f8ad63",
          className: "en-cours-de-traitement"
        };
      case Status.PRESENCE_OF_RESERVES:
        return {
          text: "Présence ce réserves",
          color: "#6ed3cd",
          className: "to-complete-status"
        };
      case Status.WAIT_DATE_EXECUTION:
        return {
          text: "En attende d’exécution",
          color: "#6ed3cd",
          className: "to-complete-status"
        };
      case Status.ACCEPTED:
        return {
          text: "Accepté",
          color: "#6ed3cd",
          className: "to-complete-status"
        };
      case Status.TO_VALIDATE:
        return {
          text: view === "tasks" ? "Valider" : view === "recap" ? "À valider" : "En cours de validation",
          color: "#7b9bd5",
          className: "en-cours-de-traitement"
        };
      case Status.TO_TREAT:
        return {
          text: view === "tasks" ? "Traiter" : view === "recap" ? "À traiter" : "En cours de traitement",
          color: "#ffa35f",
          className: "to-complete-status"
        };
      case Status.VALIDATED:
        return {
          text: "Validé",
          color: "#6ed3cd",
          className: "to-complete-status"
        };
      case Status.CANCELED:
        return {
          text: "Rejeté",
          color: "#ff465f",
          className: "to-complete-status"
        };
      case Status.AUTOMATIC_BANK_REJECT:
        return {
          text: "Annulée par la banque",
          color: "#ff465f",
          className: "to-complete-status"
        };
      default:
        return {
          text: "Status inconnu: " + key,
          color: "#ff465f",
          className: ""
        };
    }
  }

  static setOperationText(operation: string): string {
    switch (operation) {
      case Operations.OPENING:
      case Operations.REMDOC_OPENING:
        return "Ouverture";
      case Operations.MODIFICATION:
        return "Modification";
      case Operations.REALIZATION:
        return "Réalisation";
      case Operations.PAYMENT:
      case Operations.REMDOC_PAYMENT:
        return "Réglement";
      case Operations.CANCELLATION:
        return "Annulation";
      case Operations.TRANSFER_RECEIVED:
        return "Virement reçu";
      case Operations.TRANSFER_ISSUED:
        return "Virement émis";
      case Operations.REFINANCING_EXTENSION:
      case Operations.REMDOC_IMPORT_EXTENSION:
      case Operations.TRANSFER_ISSUED_EXTENSION:
      case Operations.CREDOC_IMPORT_EXTENSION:
        return "Prorogation";
      case Operations.REFINANCING_EXPIRATION:
        return "Tombée de refinancement";
      default:
        return operation;
    }
  }

  static removeExtFromName(fileName): string {
    return fileName
      .split(".")
      .slice(0, -1)
      .join(".");
  }

  static setPaymentTypeText(paymentType: string): string {
    switch (paymentType) {
      case this.paymentTypes[0].code:
        return this.paymentTypes[0].label;
      case this.paymentTypes[1].code:
        return this.paymentTypes[1].label;
      case this.paymentTypes[2].code:
        return this.paymentTypes[2].label;
      default:
        return paymentType;
    }
  }
  static setRegulationStatus(key: string): string {
    switch (key) {
      case Status.TO_TREAT:
        return "Réglement à traiter";
      case Status.TO_SIGN:
      case Status.TO_VALIDATE:
        return "Réglement en cours";
      case Status.TO_REGULARIZE:
        return "A régulariser";
      default:
        return "Statut inconnu: " + key;
    }
  }
  static setRdiType(type: RdiTypes) {
    switch (type) {
      case RdiTypes.AT_SIGHT:
        return "A vue";
        case RdiTypes.BY_ACCEPTANCE_BILL_CUSTOMER:
          return "Par acceptation effet client";
        case RdiTypes.BY_ACCEPTANCE_BILL_VENDOR:
          return "Par acceptation effet fournisseur";
          case RdiTypes.BY_AVAL_BILL_CUSTOMER:
          return "Par aval effet client";
        case RdiTypes.BY_AVAL_BILL_VENDOR:
          return "Par aval effet fournisseur";
        case RdiTypes.BY_ACCEPTANCE:
            return "Par acceptation";
        case RdiTypes.BY_ACCEPTANCE_DOWNSTRAM:
            return "Par acceptation et aval";

      default:
        return type;
    }
  }


  static containsSpecialChar(value: string) {
    return this.specialCharactersRegEx().test(value);
  }
  static specialCharactersRegEx() {
    return new RegExp(this.specialCharacters.join("|\\"));
  }

  static nonNumericCharactersRegEx() {
    return new RegExp(this.nonNumericChars.join("|\\"));
  }
  static containsNonNUmericChars(value: string) {
    return this.nonNumericCharactersRegEx().test(value);
  }

  static togglePanel(panel: HTMLElement) {
    panel.parentNode.querySelectorAll(".panel").forEach((item: HTMLElement) => {
      if (item !== panel) {
        item.style.display = "none";
      } else {
        item.style.display = "block";
      }
    });
  }

  static specialCharValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (this.containsSpecialChar(control.value)) {
        return {
          charError: `Ce champs ne doit pas contenir des caractères spéciaux (${this.specialCharacters.join(
            ", "
          )})`
        };
      }
      return null;
    };
  }


  // Gestion des cookies.
  static getCookie(name: string) {
    let cookies: Array<string> = window.parent.document.cookie.split(';');
    let cookiesLength: number = cookies.length;
    let cookieName = `${name}=`;
    let cn: string;

    for (let i: number = 0; i < cookiesLength; i += 1) {
        cn = cookies[i].replace(/^\s+/g, '');
        if (cn.indexOf(cookieName) == 0) {
            return cn.substring(cookieName.length, cn.length);
        }
    }
    return '';
  }

  static deleteCookie(name) {
    this.setCookie(name, '', -1);
  }

  static setCookie(name: string, value: string, expireDays: number, path: string = '') {
    let date: Date = new Date();
    date.setTime(date.getTime() + expireDays * 86400 * 1000);
    let _expire: string = `expires=${date.toUTCString()}`;
    let _path: string = path ? `; path=${path}` : '';
    window.parent.document.cookie = `${name}=${value}; ${_expire}${_path}`;
  }
}

export function initializer(keycloak: KeycloakService): () => Promise<any> {
  return (): Promise<any> => {
    return new Promise(async (resolve, reject) => {
      try {
        await keycloak.init({
          config: environment.keycloak,
          initOptions: {
            onLoad: "login-required",
            checkLoginIframe: false
          },
          bearerExcludedUrls: []
        });
        resolve();
      } catch (error) {
        reject(error);
      }
    });
  };
}
