import { Component, OnInit, Input } from '@angular/core';
import { Remdoc } from 'src/app/models/remdoc';
import { RemdocService } from 'src/app/services/remdoc.service';
import { ActivatedRoute } from '@angular/router';
import { ClientView } from 'src/app/models/client-view';
import { ClientSharedService } from 'src/app/services/client-shared-service';

@Component({
  selector: 'app-rdi-recap',
  templateUrl: './rdi-recap.component.html',
  styleUrls: ['./rdi-recap.component.css']
})
export class RdiRecapComponent implements OnInit {
  @Input() remdoc: Remdoc;
  id: number;
  public client: ClientView;
  constructor(
    private remdocService: RemdocService,
    private route: ActivatedRoute,
    private clientSharedservice: ClientSharedService
  ) {}
  ngOnInit() {
    this.client = this.clientSharedservice.getClient();
    this.id = this.route.snapshot.params['id'];
    if (this.id && !this.remdoc) {
      this.remdocService
        .getRemdoc(this.id, this.client.id)
        .subscribe(res => (this.remdoc = res));
    }
  }
}
