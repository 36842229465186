import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import {
  FormGroup,
  FormBuilder,
  Validators,
  FormControl
} from '@angular/forms';
import { IdentityService } from 'src/app/services/identity.service';
import { IdentityChallenge } from 'src/app/models/identityChallenge';

@Component({
  selector: 'app-dialog-sign',
  templateUrl: './dialog-sign.component.html',
  styleUrls: ['./dialog-sign.component.css']
})
export class DialogSignComponent implements OnInit {
  @Input() message;
  @Input() model;
  @Input() validatorCilcked;
  @Output() signed = new EventEmitter<string>();
  @Output() cancelled = new EventEmitter<string>();
  @Input() displayConfirmDialog = false;
  signForm: FormGroup;
  identityChallenge: IdentityChallenge;

  constructor(
    private fb: FormBuilder,
    private identityService: IdentityService
  ) {}

  ngOnInit(): void {
    this.signForm = this.fb.group({
      dpResponse: this.fb.control(null, [
        Validators.required,
        Validators.maxLength(8)
      ])
    });

    this.loadCallenge();
  }

  loadCallenge() {
    this.identityService.getChallenge().subscribe(
      response => {
        this.identityChallenge = response;
      },
      error => {
        this.identityChallenge = null;
      }
    );
  }

  get dpResponse(): FormControl {
    return this.signForm.get('dpResponse') as FormControl;
  }

  onSign(event: string) {
    this.model.dpResponse = this.dpResponse.value;
    this.model.challengeKey = this.identityChallenge.challengeKey;
    this.signed.emit(event);
  }

  onCancel(event: string) {
    this.cancelled.emit(event);
  }

  onRefresh(event: string) {
    this.loadCallenge();
  }

  formValid(): boolean {
    return this.signForm.valid;
  }
}
