import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json'
  })
};
@Injectable({
  providedIn: 'root'
})
export class GroupService {

  constructor(private http: HttpClient) {}

  getGroups(clientId: number): Observable<Array<Group>> {
    return this.http
      .get<Array<Group>>(
        `${environment.url}/group/client/${clientId}`,
        httpOptions
      );
  }
}
