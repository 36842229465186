import { Negociation } from './negociation';
import { Document } from './document';
import { RefinancingExtension } from './refinancingExtension';

export class IssuedTransfer {
  type: string;
  id: number;
  amount: number;
  public addDate: Date;
  currency: string;
  initiator: string;
  referenceWf: string;
  negociations: Negociation[] = [];
  reason: string;
  execusionDate: Date;
  chargesOutside: string;
  witholding: string;
  importationTitles: string[] = [];
  refinancing: boolean;
  refinancingAmount: number;
  duration: number;
  refinancingExtensions: RefinancingExtension[];
  accountNumber: string;
  instructions: string;
  documentList: Document[] = [];
  beneficiaryId: number;
  requestId: string;
  taskId: string;
  status: string;
  comment: string;
  refinancingExtensible: boolean;
}
