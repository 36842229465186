import {
  Component,
  OnInit,
  Input,
  SimpleChanges,
  OnChanges
} from '@angular/core';
import { environment } from '../../../environments/environment';
import { productList, ProductItem } from '../../models/productList';
import { User } from 'src/app/models/user';
@Component({
  selector: 'app-user-details',
  templateUrl: './user-details.component.html',
  styleUrls: ['./user-details.component.css']
})
export class UserDetailsComponent implements OnInit, OnChanges {
  productList: Array<ProductItem> = productList;
  @Input() user: User;
  userProductsRoles: Array<ProductRoles>;
  userBeneficiariesRoles: ProductRoles;
  constructor() {}

  setUserRoles() {
    this.userProductsRoles = [];
    this.userProductsRoles.push({
      productName: 'CREDOC_IMPORT',
      productLabel: 'Crédit documentaire Import',
      roles: environment.credocRoles.map(credocRole => {
        return {
          checked: this.user.keycloakRoles.includes(credocRole),
          name: credocRole
        };
      })
    });

    this.userProductsRoles.push({
      productName: 'REMDOC_IMPORT',
      productLabel: 'Remise documentaire Import',
      roles: environment.remdocRoles.map(remdocRole => {
        return {
          checked: this.user.keycloakRoles.includes(remdocRole),
          name: remdocRole
        };
      })
    });

    this.userProductsRoles.push({
      productName: 'TRANSFER_RECEIVED',
      productLabel: 'Virement reçu',
      roles: environment.transferReceivedRoles.map(transferReceivedRole => {
        return {
          checked: this.user.keycloakRoles.includes(transferReceivedRole),
          name: transferReceivedRole
        };
      })
    });

    this.userProductsRoles.push({
      productName: 'TRANSFER_ISSUED',
      productLabel: 'Virement émis',
      roles: environment.transferIssuedRoles.map(transferIssuedRole => {
        return {
          checked: this.user.keycloakRoles.includes(transferIssuedRole),
          name: transferIssuedRole
        };
      })
    });

    this.userBeneficiariesRoles = {
      productName: 'BENEFICIARY',
      productLabel: '',
      roles: environment.beneficiaryRoles.map(benefRole => {
        return {
          checked: this.user.keycloakRoles.includes(benefRole.name),
          name: benefRole.name,
          label: benefRole.label
        };
      })
    };
  }

  setBeneficiaryLabels(label: string) {}

  isSuperUser() {
    return this.user.keycloakRoles.includes('SUPER_USER');
  }

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges): void {
    if (this.user) {
      this.setUserRoles();
    }
  }
}

interface ProductRoles {
  roles: Array<Role>;
  productName: string;
  productLabel: string;
}

interface Role {
  name: string;
  checked: boolean;
  label?: string;
}
