import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { ProrogService } from 'src/app/services/prorog.service';
import { ProrogationViews } from 'src/app/util';
import { Router } from '@angular/router';
import { AlertComponent } from 'src/app/core/alert/alert.component';
import { ProrogationRecap } from 'src/app/models/prorogationRecap';
import { DialogSignComponent } from 'src/app/core/dialog-sign/dialog-sign.component';
import { ClientView } from 'src/app/models/client-view';
import { ClientSharedService } from 'src/app/services/client-shared-service';

@Component({
  selector: 'app-prorog-recap',
  templateUrl: './prorog-recap.component.html',
  styleUrls: ['./prorog-recap.component.css']
})
export class ProrogRecapComponent implements OnInit {
  @Input() id: string;
  @Input() taskId: string;
  @Input() prorogationView: string;
  @Input() currency: string;
  @ViewChild('app_dialog_prorogation_sign')
  appDialogProrogationSign: DialogSignComponent;
  prorogationRecap: ProrogationRecap;
  public commentObj: {
    comment: string;
    challengeKey: string;
    dpResponse: string;
  };
  public client: ClientView;
  EMPTY = '';
  public validateMessage =
    'Vous êtes sur le point de valider cette opération. Êtes-vous sûr de vouloir continuer ?';
  public rejectMessage =
    'Veuillez saisir votre motif de rejet pour cette prorogation.';
  confirmRejectMessage =
    'Vous êtes sur le point de rejecter définitivement cette prorogation.';
  confirmTempRejectMessage =
    'Vous êtes sur le point de rejecter provisoirement cette prorogation.';
  signMessage =
    'Vous êtes sur le point de signer cette opération. Entrer la code de signature.';
  public validatorCilcked: { button: string };
  @ViewChild('prorog_recap_alert') prorogRecapAlert: AlertComponent;
  constructor(
    private prorogService: ProrogService, 
    private router: Router,
    private cleintSharedService: ClientSharedService
    ) {}

  ngOnInit() {
    this.client = this.cleintSharedService.getClient();
    this.showSnackbar = this.showSnackbar.bind(this);
    this.commentObj = { comment: null, dpResponse: null, challengeKey: null };
    this.validatorCilcked = { button: '' };
    this.getProrogRecapInfo();
  }

  getProrogRecapInfo() {
    this.prorogService.getProrogData(this.id, this.client.id).subscribe(data => {
      this.prorogationRecap = data;
    });
  }

  cancelValidation(_action: string) {
    this.validatorCilcked.button = '';
  }

  onSignAction(action: string) {
    this.prorogService
      .signProrogation(
        this.id,
        this.commentObj.dpResponse,
        this.commentObj.challengeKey,
        this.client.id
      )
      .subscribe(this.showSnackbar('CONFIRMED'), _error =>
        this.appDialogProrogationSign.onRefresh(null)
      );
  }

  rejectProrog(action: string) {
    this.prorogService
      .rejectProrog(this.id, this.commentObj.comment, this.client.id)
      .subscribe(this.showSnackbar(action));
  }

  tempRejectProrog(action: string) {
    this.prorogService
      .tempRejectProrog(this.id, this.commentObj.comment, this.client.id)
      .subscribe(this.showSnackbar(action));
  }

  private showSnackbar(action: string) {
    return _val => {
      this.prorogRecapAlert.snackbarData.display.isDisplay = true;
      this.prorogRecapAlert.snackbarData.message =
        `L'opération a bien été` +
        (action === 'CONFIRMED'
          ? ' signée'
          : action === 'TO_BE_COMPLETED'
          ? ' rejetée provisoirement'
          : action === 'REJECTED'
          ? ' rejetée'
          : '');
      setTimeout(() => {
        this.prorogRecapAlert.snackbarData.display.isDisplay = false;
        this.returnToList();
      }, 3000);
    };
  }

  returnToList(): void {
    if (this.prorogationView === ProrogationViews.PROROGATION_VALIDATION) {
      this.router.navigate(['/tasks']);
    }
  }
}
