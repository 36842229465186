import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { LettreCredit } from 'src/app/models/lettre-credit';
import { Status, Operations, NegociationTypes } from 'src/app/util';
import { CreateLcService } from 'src/app/services/create-lc.service';
import { DataService } from 'src/app/services/data.service';
import { Subscription } from 'rxjs';
import { PaymentOperation } from 'src/app/models/paymentOperation';
import { Negociation } from 'src/app/models/negociation';
import { CreateComponent } from '../create/create.component';
import { SummaryComponent } from '../summary/summary-comp/summary.component';
import { LastComment } from 'src/app/models/lastComment';
import { LcUtil } from '../lcUtil';
import { ClientView } from 'src/app/models/client-view';
import { ClientSharedService } from 'src/app/services/client-shared-service';

@Component({
  selector: 'app-lc',
  templateUrl: './lc.component.html',
  styleUrls: ['./lc.component.css']
})
export class LcComponent implements OnInit, OnDestroy {
  public model: LettreCredit;
  public payment: PaymentOperation;
  public taskDefinitionKey: string;
  public taskId: string;
  public assignee: string;
  public lcId: string;
  public clientId: string;
  summary = { isSummary: false };
  public lastComment: LastComment;
  public decision: string;
  public action = Operations.OPENING; // MODIFICATION
  private dataServiceSubscription: Subscription;
  public client: ClientView;
  @ViewChild('createComp') createComp: CreateComponent;
  @ViewChild('summaryComp') summaryComp: SummaryComponent;
  constructor(
    private createLCService: CreateLcService,
    private dataService: DataService,
    private clientSharedService: ClientSharedService
  ) {}
  ngOnInit() {
    this.client = this.clientSharedService.getClient();
    this.dataServiceSubscription = this.dataService.currentData.subscribe(
      data => {
        this.summary.isSummary = data.summary;
        this.taskDefinitionKey = data.taskDefinitionKey;
        this.taskId = data.taskId;
        this.lcId = data.lcId;
        this.lastComment = data.lastComment;
        this.action = data.action;
        this.decision = data.decision;

        this.completeModel(); // If no response from server
        this.initModel();
        if (
          this.action === Operations.PAYMENT ||
          this.action === 'view_recap_payment'
        ) {
          this.completePayment();
          this.createLCService
            .getPayment(data.paymentId, this.client.id)
            .subscribe((p: PaymentOperation) => {
              this.payment = p;
              this.payment.taskId = this.taskId;
              this.completePayment();
              if (this.summaryComp.paymentFormComp) {
                this.summaryComp.paymentFormComp.mapPaymentToForm(this.payment);
              }
            });
        }
      }
    );
  }

  ngOnDestroy() {
    this.dataService.changeData({
      product: null,
      summary: false,
      paymentId: null,
      lcId: null,
      taskId: null,
      taskDefinitionKey: null,
      lastComment: { fullMessage: null, time: null, userId: null },
      action: null,
      decision: null
    });
    if (this.dataServiceSubscription) {
      this.dataServiceSubscription.unsubscribe();
    }
  }

  initModel() {
    this.createLCService.initCredoc(+this.lcId, this.client.id).subscribe(credoc => {
      this.model = credoc;
      this.model.taskId = this.taskId;
      if (!this.model.operation) {
        this.model.operation = Operations.OPENING;
      }
      this.completeModel();

      if (
        this.action === Operations.MODIFICATION &&
        this.model.status === Status.EXECUTED
      ) {
        this.model.documentList = [];
        this.model.credocImportParentId = this.model.id;
        this.model.credocImportChildId = null;
        this.model.id = null;
        this.model.operation = Operations.MODIFICATION;
        this.model.status = null;
        this.model.referenceWF = null;
        this.model.addDate = new Date();
      }
      this.model['title'] = `Création d'une nouvelle lettre de crédit`;
      if (this.model.operation === 'MODIFICATION') {
        this.model['title'] = 'Modification de la lettre de crédit';
      }
      // verify amount after opening form if exists.
      if (this.model.amount && this.model.currency && this.createComp) {
        this.createComp.paymentSectionComponent.verifyAmount(
          this.model.amount,
          this.model.currency
        );
      }
    });
  }

  completePayment() {
    if (!this.payment) {
      this.payment = new PaymentOperation();
    }
    if (this.payment.negociations.length < 1) {
      const neg = new Negociation(NegociationTypes.FIXING);
      neg.paymentId = this.payment.id;
      this.payment.negociations.push(neg);
    }
  }

  completeModel() {
    this.model = LcUtil.completeModel(this.model);
    if (this.model.parent) {
      this.model.parent = LcUtil.completeModel(this.model.parent);
    }
  }
}
