export const ERROR_MAPPING = {
  TECHNICAL_EXCEPTION: {
    message: 'Erreur système,merci de réssayer plus tard.'
  },
  BENEFICIARY_ALREADY_IN_UPDATING: {
    message: 'Bénéficaire est déjà en cours de modification !'
  },
  BENEFICIARY_NOT_EXIST_CLIENT: {
    message: `Le client n'autorise pas le modification de ce bénéficaire !`
  },
  MISSING_FIELD_EXCEPTION: {
    message: 'Champs manquant !'
  },
  FILE_ALREADY_PRESENT: {
    message: 'Fichier déja présent dans la liste.'
  },
  'org.springframework.web.multipart.MultipartException': {
    message: 'Taille maximale des fichiers dépassée !'
  },
  CREDOC_IMPORT_ALREADY_HAS_CHILD: {
    message: 'Impossible de lancer cette opération plusieurs fois.'
  },
  PAYMENT_HAS_EXTENSION_IN_PROGRESS: {
    message: 'Une prorogation est déjà en cours ...'
  },
  PAYMENT_NOT_EXIST: {
    message: 'Payment non trouvé.'
  },
  NO_AUTHORZATION: {
    message: 'Pas de schéma/validateur disponible pour ce montant!'
  },
  MODEL_CREDOC_IMPORT_ALREADY_EXIST: {
    message: 'Un modèle avec le même nom existe déjà.'
  },
  INVALID_CHALLENGE_RESPONSE: {
    message: 'Réponse challenge invalide !'
  },
  USER_LOCKED_CHALLENGE_RESPONSE: {
    message: 'Le compte utilisateur Digipass est verrouillé !'
  },
  USER_EXPIRE_CHALLENGE_RESPONSE: {
    message: 'Le compte utilisateur Digipass est expiré !'
  },
  USER_NOT_FOUND: {
    message: `L'utilisateur n'existe pas!`
  },
  FORBIDDEN: {
    message: `Vous n'avez plus le droit d'accès à cette fonctionnalité !`
  },
  CREDOC_IMPORT_NOT_FOUND: {
    message: `Crédit documentaire n'existe pas !`
  },
  PAYMENT_TYPE_NOT_DEFINED: {
    message: `Type de paiment non défini !`
  },
  BENEFICIARY_NOT_FOUND: {
    message: `Bénéficiaire n'existe pas !`
  },
  CURRENCY_AMOUNT_REQUIRED: {
    message: `Devise et montants requis  !`
  },
  CLIENT_NOT_FOUND: {
    message: `Client n'existe pas  !`
  },
  CANNOT_GET_EXCHANGE: {
    message: `Erreur lors d'échange de la devise  !`
  },
  CREDOC_IMPORT_STATUS_NOT_FOUND: {
    message: 'Erreur système,merci de réssayer plus tard.'
  },
  CREDOC_IMPORT_PARENT_NOT_FOUND: {
    message: 'Erreur système,merci de réssayer plus tard.'
  },
  CREDOC_IMPORT_REALIZATION_PARENT_NOT_FOUND: {
    message: 'Erreur système,merci de réssayer plus tard.'
  },
  CREDOC_IMPORT_CANCELLING_PARENT_NOT_FOUND: {
    message: 'Erreur système,merci de réssayer plus tard.'
  },
  USER_DB_NOT_FOUND: {
    message: 'vous n’êtes pas autorisé pour cette opération, veuillez contacter votre agence/gestionnaire .'
  },
  SOURCE_INITIATOR_NOT_FOUND: {
    message: `Initiateur inexistant !`
  },
  BENEFICIARIES_NOT_FOUND: {
    message: `Bénéficiares inexistants !`
  },
  ERROR_GET_CHALLENGE: {
    message: `Erreur de génération du code challenge !`
  },
  INVALID_SIGNATURE_CODE: {
    message: `Code de signature invalide !`
  },
  INVALID_SIGNATURE_CHALLENGE_KEY: {
    message: `Code de signature invalide !`
  },
  USER_DISABLED: {
    message: `Utilisateur désactivé !`
  }
    
  
};
