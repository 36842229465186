import { Component, OnInit } from "@angular/core";
import { UserService } from 'src/app/services/user.service';
import { Observable } from 'rxjs';
import { Client } from "src/app/models/client";
import { FormGroup } from "@angular/forms";
import { ClientService } from "src/app/services/client.service";
import { HttpClient } from "@angular/common/http";
import { ClientView } from "src/app/models/client-view";
import { Util } from "src/app/util";
import { HomeService } from "src/app/services/home.service";
import { Router } from "@angular/router";
import { ClientSharedService } from "src/app/services/client-shared-service";

@Component({
  selector: "app-side-menu",
  templateUrl: "./side-menu.component.html",
  styleUrls: ["./side-menu.component.scss"]
})
export class SideMenuComponent implements OnInit {
  /**
   * new menu ==> new item to toggle list. I case of simple element dont't
   * use toggle list, use only onHide method to hide all
   */


  
   public filterForm: FormGroup;
   public selectIsOpened: boolean;
   public clientListIsOpened: boolean = false;
   public loaded: boolean = true;
   public noResult: boolean = false;
   public technicalError: boolean = false;
   public exceeded: boolean = false;
   public sensImage:string = " ";
   public showWebgedResult:boolean = false;
   selectedClient;
   loadedclient: boolean = true;
   public index = 0;
   public currencies: string[];
   public clientList: Array<ClientView> = [];
   public filtredClientList:Array<ClientView> = [];
   public transactions: any;
   public listBankCode: any;
   searchClient;
   public fullName: Observable<string>;
   toggle = [true, true, true, true, true, true];
   customerNumber;


   
  ngOnInit() {
    this.getClientList();

  }
 
   selectIsopened() {
     this.selectIsOpened = true;
   }
   selectIsclosed() {
     this.selectIsOpened = false;
   }

  constructor(
    private userService: UserService,
    private clientService: ClientService,
    private homeService: HomeService,
    private router: Router,
    private clientSharedService: ClientSharedService,
    private http: HttpClient) {
      
  }

 
  getClientList(): void {
    this.clientService.getClients().subscribe((clients) => {
      this.clientList = clients;
      this.filtredClientList = this.clientList;

      this.clientService.getCustomerClient().subscribe((obj) => {
        if (obj && obj.customerNumber) {
          if (obj.customerNumber.includes('customerNumber')) {
            this.selectedClient = clients.find(client => {
              return client.customerNumber === JSON.parse(obj.customerNumber).customerNumber
            });
          } else {
            this.selectedClient = clients.find(client => {
              return client.customerNumber === obj.customerNumber
            });
          }
        } else {
          this.selectedClient = this.clientList[0];
        }
        this.clientSharedService.addClient(this.selectedClient);
        this.fullName = this.setFullName(this.selectedClient.id);
        this.customerNumber = this.selectedClient.customerNumber;
        this.router.navigate(['/portail']);
        this.clientListIsOpened = false;
      });

      //this.onChange(this.selectedClient);
    });
  }

  
  /*onChange(client) {
    
    this.clientSharedService.addClient(client);
    this.customerNumber = client.customerNumber;
    this.selectedClient = client;
    this.router.navigate(['/portail']);
    this.clientListIsOpened = false;
  }*/

  changeStatus() {
    this.filtredClientList = this.transform(this.clientList, this.searchClient);
  }

  public  transform(items: any[], searchText: string): any[] {
    if (!items) {
      return [];
    }
    if (!searchText) {
      return items;
    }
    searchText = searchText.toLocaleLowerCase();
  
    return items.filter(it => {
      return JSON.stringify(it).toLocaleLowerCase().includes(searchText);
    });
  }

  selectClient(cl) {
    this.customerNumber = cl.customerNumber;
    this.clientListIsOpened = false;
  }

  

  isBackDrop() {
    return this.toggle.reduce((acc, curr) => {
      return acc && curr;
    }, true);
  }

  onShow(i: number) {
    this.onHide_(i);
    this.toggle[i] = false;
  }

  
  onShowClient(i: number) {
    this.onHideClient_(i);
    this.toggle[i] = !this.toggle[i];
  }


  getFullName() {
    return this.userService.getFullName();
  }

  setFullName(clientId: number): Observable<string>{
    return this.userService.setFullName(clientId);
  }

  randomParam() {
    return Math.random();
  }
  onHide_(i: number) {
    this.toggle.forEach((_element, index) => {
      if (index !== i) {
        this.toggle[index] = true;
      }
    });
  }

  onHideClient_(i: number) {
    this.toggle.forEach((_element, index) => {
      if (index !== i) {
        this.toggle[index] = true;
      }
    });
  }

  onHide() {
    this.toggle.forEach((element, index) => (this.toggle[index] = true));
  }
}
