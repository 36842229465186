import { Bank } from './bank';

export class Beneficiary {
  public name: string;
  public address: string;
  public country: string;
  public account: string;
  public bank: Bank;
  public status: string;
  public statusOperation: string;
  public beneficiaryParentId: number;
  public beneficiaryChildId: number;
  public logo: any;
  public id: number;
  public iban: string;
  constructor() {}
}
