import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { AppError } from './types/AppError';
import { VndError } from './types/VndError';
import { ERROR_MAPPING } from './types/errorMapping';
import { environment } from 'src/environments/environment';

const debugMode: boolean = environment.debugMode;

@Injectable({
  providedIn: 'root'
})
export class ErrorService {
  public errorNotifer: BehaviorSubject<AppError> = new BehaviorSubject(null);
  constructor() {}

  emitError(error: VndError) {
    const appError: AppError = this.errorMapper(error);
    this.errorNotifer.next(appError);
  }

  errorMapper(error: VndError): AppError {
    if (
      error.logref === undefined &&
      error.exception === 'org.springframework.web.multipart.MultipartException'
    ) {
      error.logref = error.exception;
    }

    let appError: AppError = <AppError>ERROR_MAPPING[error.logref];

    // unknown error
    if (appError === null || appError === undefined) {
      appError = <AppError>ERROR_MAPPING['TECHNICAL_EXCEPTION'];
    }
    // optional mode debug
    if (debugMode) {
      appError['details'] = error.message;
    }

    return appError;
  }
}
