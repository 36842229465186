import { LettreCredit } from 'src/app/models/lettre-credit';
import { Beneficiary } from 'src/app/models/beneficiary';
import { Bank } from 'src/app/models/bank';
import { Applicant } from 'src/app/models/applicant';
import {
  CommecialInvoice,
  OtherDoc,
  TransportionDocs
} from 'src/app/models/benefDocs';
import { Util } from 'src/app/util';

export class LcUtil {
  public static completeModel(model): LettreCredit {
    if (!model) {
      model = new LettreCredit();
    }

    if (!model.addDate) {
      model.addDate = new Date();
    }

    if (!model.beneficiary) {
      model.beneficiary = new Beneficiary();
    }

    if (!model.beneficiary.bank) {
      model.beneficiary.bank = new Bank();
    }

    if (!model.applicant) {
      model.applicant = new Applicant();
    }

    if (!model.commercialInvoice) {
      model.commercialInvoice = new CommecialInvoice();
    }

    if (!model.commercialInvoice) {
      model.commercialInvoice = new CommecialInvoice();
    }

    if (!model.transportationDocument) {
      model.transportationDocument = new TransportionDocs();
    }

    if (!model.weightNote) {
      model.weightNote = new OtherDoc();
    }

    if (!model.packingList) {
      model.packingList = new OtherDoc();
    }

    if (!model.sanitaryCertification) {
      model.sanitaryCertification = new OtherDoc();
    }

    if (!model.originCertification) {
      model.originCertification = new OtherDoc();
    }

    if (!model.originCertification) {
      model.originCertification = new OtherDoc();
    }

    if (model.validityDate) {
      model.validityDate = Util.getFormattedDate(new Date(model.validityDate));
    }
    if (model.expeditionLastDate) {
      model.expeditionLastDate = Util.getFormattedDate(
        new Date(model.expeditionLastDate)
      );
    }
    if (!model.importationTitles) {
      model.importationTitles = [];
    }
    if (!model.documentList) {
      model.documentList = new Array();
    }
    let counter = 0;
    if (model.paymentAtSightType) {
      model.paymentTypeSelected = 'PAYMENT_AT_SIGHT';
      counter += 1;
    }
    if (model.paymentDueType) {
      model.paymentTypeSelected = 'PAYMENT_DUE';
      counter += 1;
    }
    if (model.paymentByAcceptanceType) {
      model.paymentTypeSelected = 'PAYMENT_BY_ACCEPTANCE';
      counter += 1;
    }
    if (counter < 2) {
      model.paymentType = 'SINGLE_PAYMENT';
    } else {
      model.paymentType = 'MIXED_PAYMENT';
    }
    return model;
  }
}
