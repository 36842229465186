import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-charges-outside-recap',
  templateUrl: './charges-outside-recap.component.html',
  styleUrls: ['./charges-outside-recap.component.css']
})
export class ChargesOutsideRecapComponent implements OnInit {
  @Input() model: any;
  constructor() {}

  ngOnInit() {}
}
