import { Component, OnInit, Input } from '@angular/core';
import { LettreCredit } from 'src/app/models/lettre-credit';

@Component({
  selector: 'app-goods',
  templateUrl: './goods.component.html',
  styleUrls: ['./goods.component.css']
})
export class GoodsComponent implements OnInit {

  @Input()model: LettreCredit;
  @Input() edited: boolean;
  @Input() isEditedVesion: boolean;
  constructor() { }

  ngOnInit() {
  }

}
