import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-refinancing-recap',
  templateUrl: './refinancing-recap.component.html',
  styleUrls: ['./refinancing-recap.component.css']
})
export class RefinancingRecapComponent implements OnInit {
  @Input() currency: string;
  @Input() model: any;
  constructor() {}

  ngOnInit() {}
  isRefinancingText(): string {
    return this.model.refinancing ? 'Oui' : 'Non';
  }
}
