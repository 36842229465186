import { Component, OnInit, Input } from '@angular/core';
import { LettreCredit } from 'src/app/models/lettre-credit';

@Component({
  selector: 'app-required-docs',
  templateUrl: './required-docs.component.html',
  styleUrls: ['./required-docs.component.css']
})
export class RequiredDocsComponent implements OnInit {

  @Input()model: LettreCredit;
  MARITIME_KNOWLEDGE = 'MARITIME_KNOWLEDGE';
  INTERNATIONAL_CONSIGNMENTNOTE = 'INTERNATIONAL_CONSIGNMENTNOTE';
  FLIGHT_LETTTER = 'FLIGHT_LETTTER';
  IN_DAYS = 'Dans les ';
  constructor() { }

  ngOnInit() {
  }

}
