import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ReassignmentFilter } from '../models/reassignmentFilter';
import { Operation } from '../models/operation';
import { Reassignment } from '../models/reassignment';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ReassignmentService {
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };
  constructor(private http: HttpClient) {}

  getOperations(
    reassignmentFilter: ReassignmentFilter,
    clientId: number
  ): Observable<Array<Operation>> {
    return this.http.post<Array<Operation>>(
      `${environment.url}/reassignment/operations/client/${clientId}`,
      reassignmentFilter,
      this.httpOptions
    );
  }
  getInitiatorsSource(product: string, clientId: number): Observable<Array<string>> {
    return this.http.get<Array<string>>(
      `${environment.url}/clients/client/${clientId}/initiators/source/${product}`,
      this.httpOptions
    );
  }
  getInitiatorsTarget(product: string, clientId: number): Observable<Array<string>> {
    return this.http.get<Array<string>>(
      `${environment.url}/clients/client/${clientId}/initiators/target/${product}`,
      this.httpOptions
    );
  }
  reassign(reassignment: Reassignment, clientId: number): Observable<void> {
    return this.http.post<void>(
      `${environment.url}/reassignment/client/${clientId}`,
      reassignment,
      this.httpOptions
    );
  }
}
