import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter
} from '@angular/core';
import { ClientView } from 'src/app/models/client-view';
import { ClientSharedService } from 'src/app/services/client-shared-service';
import { ModeleService } from 'src/app/services/modele.service';

@Component({
  selector: 'app-modele',
  templateUrl: './modele.component.html',
  styleUrls: ['./modele.component.css']
})
export class ModeleComponent implements OnInit {
  modeles: Array<any> = [];
  selectedId: string;
  modeleName: string;
  LIST = 'list';
  NEW = 'new';
  HIDE_MODAL = 'hideModal';
  SHOW_MODAL = 'showModal';
  selectedModele: ModeleComponent;
  constructor(
    private modeleService: ModeleService,
    private clientSharedService: ClientSharedService) {}
  @Input() data: { showClass: string; type: null };
  @Output() selectModele = new EventEmitter<string>();
  @Output() saveModele = new EventEmitter<string>();
  public client: ClientView;

  ngOnInit() {
    this.client = this.clientSharedService.getClient();
    this.getModeles();
  }

  confirmSelection() {
    return this.selectModele.emit(this.selectedId);
  }

  onSaveModele() {
    return this.saveModele.emit(this.modeleName);
  }
  getModeles() {
    this.modeleService.getModeles(this.client.id).subscribe(modeles => {
      this.modeles = modeles;
    });
  }
}
