import { Component, OnInit, Input, ViewChild, OnDestroy } from '@angular/core';
import { PaymentOperation } from 'src/app/models/paymentOperation';
import { LettreCredit } from 'src/app/models/lettre-credit';
import { Util, NegociationTypes } from 'src/app/util';
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormArray,
  FormControl
} from '@angular/forms';
import { PaymentValidators } from '../validators/validators';
import { Router } from '@angular/router';
import { CreateLcService } from 'src/app/services/create-lc.service';
import { Negociation } from 'src/app/models/negociation';
import { AlertComponent } from 'src/app/core/alert/alert.component';
import { Subscription } from 'rxjs';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { ClientView } from 'src/app/models/client-view';
import { ClientSharedService } from 'src/app/services/client-shared-service';

@Component({
  selector: 'app-payment-operation',
  templateUrl: './payment-operation.component.html',
  styleUrls: ['./payment-operation.component.css']
})
export class PaymentOperationComponent implements OnInit, OnDestroy {
  @Input() payment: PaymentOperation;
  @Input() model: LettreCredit;
  @Input() taskDefinitionKey: string;
  private getAccountsSubscription: Subscription;
  private refinancingChangeSubscription: Subscription;
  private negociationTypeChangeSubscription: Subscription;
  private negotiatedAmountChangeSubscrition: Subscription;
  @ViewChild('payment_operation_alert') paymentOperationAlert: AlertComponent;
  paymentForm: FormGroup;
  public accounts: string[] = [];
  refinancingForm: FormGroup;
  datePickerConfig = Util.date_input_config;
  public client: ClientView;
  confirmationShown = false;
  submitPaymentMessage =
    'Vous êtes sur le point de soumettre les nouvelles instructions de paiement pour signature.';
  constructor(
    private fb: FormBuilder,
    private router: Router,
    private createLCService: CreateLcService,
    private localeService: BsLocaleService,
    private clientSharedService: ClientSharedService
  ) {}

  ngOnInit() {
    this.localeService.use('fr');
    this.client = this.clientSharedService.getClient();
    this.getAccounts();
    this.paymentForm = this.fb.group({
      negociations: this.fb.array([], [Validators.required]),
      accountNumber: this.fb.control('', [Validators.required]),
      refinancing: [false, [Validators.required]]
    });
    this.refinancingForm = this.fb.group({
      amountToBeRefinanced: [null],
      duration: [null]
    });
    this.paymentForm.addControl('refinancingForm', this.refinancingForm);
    this.refinancingChangeSubscription = this.paymentForm
      .get('refinancing')
      .valueChanges.subscribe(newVal => {
        if (newVal) {
          this.refinancingForm
            .get('amountToBeRefinanced')
            .setValidators([
              Validators.required,
              PaymentValidators.amountRefinancingValidator(
                this.payment.amountPayment
              ),
              Validators.min(0)
            ]);
          this.refinancingForm
            .get('duration')
            .setValidators([Validators.required, Validators.min(0)]);
        } else {
          this.refinancingForm.get('amountToBeRefinanced').clearValidators();
          this.refinancingForm.get('duration').clearValidators();
          this.refinancingForm.get('amountToBeRefinanced').setValue(null);
          this.refinancingForm.get('duration').setValue(null);
          this.refinancingForm
            .get('amountToBeRefinanced')
            .updateValueAndValidity();
          this.refinancingForm.get('duration').updateValueAndValidity();
        }
      });
  }

  getAccounts() {
    this.getAccountsSubscription = this.createLCService.getCredocImportAccounts(this.client.id).subscribe(
      accounts => {
        this.accounts = accounts;
      },
      err => {}
    );
  }


  get accountNumber(): FormControl {
    return this.paymentForm.get('accountNumber') as FormControl;
  }
  get negociations(): FormArray {
    return this.paymentForm.get('negociations') as FormArray;
  }

  negociationTrackBy(index: number, obj: any): any {
    return index;
  }

  createNewColleagueForm(
    amountPayment,
    id,
    negociationType,
    indicativeExchangeRate,
    negotiatedAmount,
    ticketReference,
    colleague,
    valueDate,
    paymentId
  ): FormGroup {
    const collFrm: FormGroup = this.fb.group({
      negociationType: [negociationType, [Validators.required]],
      id: [id],
      indicativeExchangeRate: [indicativeExchangeRate],
      negotiatedAmount: [negotiatedAmount],
      ticketReference: [ticketReference],
      colleague: [colleague],
      valueDate: [valueDate],
      paymentId: [paymentId, Validators.required]
    });
    if (negociationType != null) {
      this.setNegociationsValidators(amountPayment, collFrm, negociationType);
    }
    this.negotiatedAmountChangeSubscrition = (collFrm.get(
      'negotiatedAmount'
    ) as FormControl).valueChanges.subscribe(_newVal => {
      this.refinancingForm.get('amountToBeRefinanced').updateValueAndValidity();
    });
    this.negociationTypeChangeSubscription = (collFrm.get(
      'negociationType'
    ) as FormControl).valueChanges.subscribe(newVal => {
      this.setNegociationsValidators(amountPayment, collFrm, newVal);
    });
    return collFrm;
  }

  ngOnDestroy(): void {
    if (this.refinancingChangeSubscription) {
      this.refinancingChangeSubscription.unsubscribe();
    }
    if (this.negotiatedAmountChangeSubscrition) {
      this.negotiatedAmountChangeSubscrition.unsubscribe();
    }
    if (this.negociationTypeChangeSubscription) {
      this.negociationTypeChangeSubscription.unsubscribe();
    }
    if (this.getAccountsSubscription) {
      this.getAccountsSubscription.unsubscribe();
    }
  }

  setNegociationsValidators(
    amountPayment: number,
    collFrm: FormGroup,
    newVal: string
  ) {
    switch (newVal) {
      case NegociationTypes.FIXING:
        collFrm.get('indicativeExchangeRate').clearValidators();
        collFrm.get('negotiatedAmount').clearValidators();
        collFrm.get('ticketReference').clearValidators();
        collFrm.get('colleague').clearValidators();
        collFrm.get('valueDate').clearValidators();
        collFrm.get('indicativeExchangeRate').setValue(null);
        collFrm.get('negotiatedAmount').setValue(null);
        collFrm.get('ticketReference').setValue(null);
        collFrm.get('colleague').setValue(null);
        collFrm.get('valueDate').setValue(null);
        collFrm.get('indicativeExchangeRate').updateValueAndValidity();
        collFrm.get('negotiatedAmount').updateValueAndValidity();
        collFrm.get('ticketReference').updateValueAndValidity();
        collFrm.get('colleague').updateValueAndValidity();
        collFrm.get('valueDate').updateValueAndValidity();
        break;
      case NegociationTypes.NEGOTIATE_SGMA:
        collFrm
          .get('indicativeExchangeRate')
          .setValidators([Validators.required, Validators.min(0)]);
        collFrm
          .get('negotiatedAmount')
          .setValidators([
            Validators.required,
            PaymentValidators.amountNegociationsValidator(amountPayment),
            Validators.min(0)
          ]);
        collFrm.get('ticketReference').setValidators([Validators.required]);
        collFrm.get('colleague').clearValidators();
        collFrm.get('valueDate').clearValidators();
        collFrm.get('colleague').setValue(null);
        collFrm.get('valueDate').setValue(null);
        collFrm.get('indicativeExchangeRate').updateValueAndValidity();
        collFrm.get('negotiatedAmount').updateValueAndValidity();
        collFrm.get('ticketReference').updateValueAndValidity();
        collFrm.get('colleague').updateValueAndValidity();
        collFrm.get('valueDate').updateValueAndValidity();
        break;
      case NegociationTypes.NEGOTIATE_COLLEAGUE:
        collFrm.get('indicativeExchangeRate').clearValidators();
        collFrm
          .get('indicativeExchangeRate')
          .setValidators([Validators.required, Validators.min(0)]);
        collFrm
          .get('negotiatedAmount')
          .setValidators([
            Validators.required,
            PaymentValidators.amountNegociationsValidator(amountPayment),
            Validators.min(0)
          ]);
        collFrm.get('ticketReference').setValidators([Validators.required]);
        collFrm.get('colleague').setValidators([Validators.required]);
        collFrm
          .get('valueDate')
          .setValidators([Validators.required, PaymentValidators.minDate]);
        collFrm.get('indicativeExchangeRate').updateValueAndValidity();
        collFrm.get('negotiatedAmount').updateValueAndValidity();
        collFrm.get('ticketReference').updateValueAndValidity();
        collFrm.get('colleague').updateValueAndValidity();
        collFrm.get('valueDate').updateValueAndValidity();
        break;
    }
  }

  removeNegociation(i: number): void {
    this.negociations.removeAt(i);
  }

  isWithColleague(negociation) {
    return (
      negociation.value.negociationType === NegociationTypes.NEGOTIATE_COLLEAGUE
    );
  }

  isWithSGMA(negociation) {
    return (
      negociation.value.negociationType === NegociationTypes.NEGOTIATE_SGMA
    );
  }

  setRegulationStatus(): string {
    return Util.setStatus('recap', this.payment.status).text;
  }

  addNegociationControl() {
    const paymentId = this.payment ? this.payment.id : null;
    this.negociations.push(
      this.createNewColleagueForm(
        this.payment.amountPayment,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        paymentId
      )
    );
  }

  setPaymentTypeText(): string {
    return Util.setPaymentTypeText(this.payment.paymentType);
  }

  setMinDate(): Date {
    return new Date();
  }

  cancel() {
    return this.router.navigate(['/tasks']);
  }

  confirm() {
    this.mapFormToPayment();
    this.createLCService.submitNegociations(this.payment, this.client.id).subscribe(
      () => {
        this.paymentOperationAlert.snackbarData = {
          reference: this.model.requestId,
          message: 'Le réglement a bien été soumis pour signature.',
          display: { isDisplay: true }
        };
        setTimeout(() => {
          this.paymentOperationAlert.snackbarData.display.isDisplay = false;
          this.cancel();
        }, 2000);
      },
      err => {

      }
    );
  }

  mapFormToPayment() {
    this.payment.negociations = this.paymentForm.value.negociations;
    this.payment['accountNumber'] = this.paymentForm.value.accountNumber;
    this.payment.refinancing = this.paymentForm.value.refinancing;
    this.payment.amountToRefinanced = this.refinancingForm.value.amountToBeRefinanced;
    this.payment.duration = this.refinancingForm.value.duration;
  }

  mapPaymentToForm(payment: PaymentOperation) {
    this.paymentForm.setValue({
      negociations: [],
      accountNumber: payment['accountNumber'],
      refinancing: payment.refinancing,
      refinancingForm: {
        amountToBeRefinanced: payment.amountToRefinanced,
        duration: payment.duration
      }
    });
    if (payment.refinancing) {
      this.paymentForm
        .get('refinancingForm')
        .get('amountToBeRefinanced')
        .setValidators([
          Validators.required,
          PaymentValidators.amountRefinancingValidator(payment.amountPayment),
          Validators.min(0)
        ]);
    }
    this.negociations.setValue([]);
    payment.negociations.map((n: Negociation) => {
      this.negociations.push(
        this.createNewColleagueForm(
          payment.amountPayment,
          n.id,
          n.negociationType,
          n.indicativeExchangeRate,
          n.negotiatedAmount,
          n.ticketReference,
          n.colleague,
          new Date(n.valueDate),
          payment.id
        )
      );
    });
  }
}
