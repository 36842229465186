import { Component, OnInit, Input } from '@angular/core';
import { LettreCredit } from 'src/app/models/lettre-credit';
import { PaymentOperation } from 'src/app/models/paymentOperation';
import { Util } from 'src/app/util';

@Component({
  selector: 'app-payment-recap',
  templateUrl: './payment-recap.component.html',
  styleUrls: ['./payment-recap.component.css']
})
export class PaymentRecapComponent implements OnInit {
  @Input() model: LettreCredit;
  @Input() payment: PaymentOperation;
  constructor() {}

  ngOnInit() {}
  setRegulationStatus(): string {
    return Util.setStatus('recap', this.payment.status).text;
  }

  setPaymentTypeText(): string {
    return Util.setPaymentTypeText(this.payment.paymentType);
  }

  negociationTrackBy(index: number, obj: any): any {
    return index;
  }
}
