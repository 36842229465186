import { Component, OnInit, OnDestroy } from "@angular/core";
import { NotificationService } from "src/app/services/notification.service";
import { NotificationDetail } from "src/app/models/notification-detail";
import { Page } from "src/app/models/page";
import { Subscription } from "rxjs";
import { takeUntil } from 'rxjs/operators';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';
@Component({
  selector: "app-notif-list",
  templateUrl: "./notif-list.component.html",
  styleUrls: ["./notif-list.component.css"]
})
export class NotifListComponent implements OnInit, OnDestroy {
  notifications: Array<NotificationDetail>;
  pageSize = 10;
  page: Page = { currentPage: 0, totalPages: null, totalElements: null };
  private isNotificationRefreshedSubscription: Subscription;
  constructor(private notificationService: NotificationService) {}

  ngOnInit() {
    this.isNotificationRefreshedSubscription = this.notificationService.isNotificationRefreshed.subscribe(
      _value => {
        this.page.currentPage = 0;
        this.getPage();
      }
    );
  }

  getNotifications(currentPage: number) {
    this.notificationService
      .getNotifications(currentPage, this.pageSize)
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(notifications => {
        this.notifications = notifications.contentList;
        this.page.totalPages = notifications.totalPages;
      });
  }

  markAsRead(notification: NotificationDetail) {
    this.notificationService.markAsRead(notification.id, true).subscribe(() => {
      this.notifications.map((notif, index) => {
        if (notification.id === notif.id) {
          this.notifications[index].read = true;
        }
      });
    });
  }

  getPage() {
    this.getNotifications(this.page.currentPage);
  }

  ngOnDestroy(): void {
    if (this.isNotificationRefreshedSubscription) {
      this.isNotificationRefreshedSubscription.unsubscribe();
    }
  }
}
