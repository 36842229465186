import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'sortCredoc'
})
export class SortCredocPipe implements PipeTransform {
  transform(lcs: Array<any>): Array<any> {
    if (!lcs) {
      return [];
    }

    const realization = lcs.filter(
      lc => lc['payments'] && lc['payments'].length > 0
    )[0];
    if (realization) {
      realization['payments'].map(pymt => {
        pymt['currency'] = realization['currency'];
        pymt['credocImportId'] = realization['id'];
        pymt['type'] = 'PAYMENT';
        lcs.push(pymt);

        if (pymt['refinancingExpiration']) {
          const exp = pymt['refinancingExpiration'];
          exp['currency'] = pymt['currency'];
          exp['credocImportId'] = pymt['credocImportId'];
          exp['paymentId'] = pymt['id'];
          exp['type'] = 'EXPIRATION';
          lcs.push(exp);
        }

        if (
          pymt['refinancingExtensions'] &&
          pymt['refinancingExtensions'].length > 0
        ) {
          pymt['refinancingExtensions'].map(ext => {
            ext['currency'] = pymt['currency'];
            ext['credocImportId'] = pymt['credocImportId'];
            ext['type'] = 'EXTENSION';
            lcs.push(ext);
          });
        }
      });
    }

    // Sort by addDate
    lcs = lcs.sort((lc1, lc2) => {
      return lc2.addDate - lc1.addDate;
    });

    return lcs;
  }
}
