import { Injectable, Injector } from '@angular/core';
import {
  HttpInterceptor,
  HttpHandler,
  HttpEvent,
  HttpErrorResponse,
  HttpRequest,
  HttpResponse
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { ErrorService } from './error.service';
import { VndError } from './types/VndError';
import { LoaderService } from 'src/app/services/loader.service';
import { UserService } from 'src/app/services/user.service';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  private requests: HttpRequest<any>[] = [];
  displayAlert: boolean;
  snackbarMessage_warning: string;
  public constructor(
    private injector: Injector,
    private loaderService: LoaderService,
    private userService: UserService
  ) {}
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any> | any> {
    return Observable.create(observer => {
      // And subscribe to the original observable to ensure the HttpRequest is made
      this.requests.push(req);
      this.showLoader();
      const subscription = next.handle(req).subscribe(
        event => {
          this.handleError(event);
          if (event instanceof HttpResponse) {
            this.removeRequest(req);
            observer.next(event);
          }
        },
        err => {
          if (err.status === 401 || err.status === 403 || (err.status === 500 && (err.error.message.includes("USER_DISABLED") || err.error.message.includes("USER_NOT_FOUND")))) {
            this.displayAlert = true;
            this.snackbarMessage_warning = 'Vos droits ont été modifiés, vous serez déconnecter immédaitement !';
            this.userService.logout();
          } else {
            this.handleError(err);
            this.removeRequest(req);
            observer.error(err);
          }

        },
        () => {
          this.removeRequest(req);
          observer.complete();
        }
      );
      // return teardown logic in case of cancelled requests
      return () => {
        this.removeRequest(req);
        subscription.unsubscribe();
      };
    });
  }

  removeRequest(req: HttpRequest<any>) {
    const i = this.requests.indexOf(req);
    if (i >= 0) {
      this.requests.splice(i, 1);
    }
    this.requests.length > 0 ? this.showLoader() : this.hideLoader();
  }

  handleError(resp: HttpEvent<any>): void {
    const errorService = this.injector.get(ErrorService);
    if (resp instanceof HttpErrorResponse) {
      const vndError = <VndError>resp.error;
      errorService.emitError(new VndError(vndError));
    }
  }

  private showLoader(): void {
    this.loaderService.show();
  }

  private hideLoader(): void {
    this.loaderService.hide();
  }
}
