import { Negociation } from './negociation';

import { Document } from '../models/document';

export class RefinancingExpiration {
  public id: number;
  public addDate: Date;
  public refinancingAmount: number;
  public camundaId: string;
  public status: string;
  public documentList: Document[] = new Array();
  public negociations: Negociation[] = new Array();
  constructor() {}
}
