import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'redirect',
  template: 'redirecting...'
})
export class RedirectComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    window.location.href = environment.urlPortal
  }

}
