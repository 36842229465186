import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
export class PaymentValidators {
  static amountRefinancingValidator(paymentAmount: number): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (control.parent === undefined) {
        return null;
      }
      if (isNaN(control.value)) {
        return {
          numericError: true
        };
      }
      if (!isNaN(paymentAmount) && control.parent && control.parent.parent) {
        const amounts = (control.parent.parent.controls['negociations']
          .controls as Array<any>).reduce((acc, curr) => {
          const negoAmount = curr.controls['negotiatedAmount'].value;
          if (!isNaN(negoAmount)) {
            acc += negoAmount;
          }
          return acc;
        }, 0);
        if (paymentAmount >= amounts + control.value) {
          return null;
        } else {
          return {
            sumError: true
          };
        }
      }
      return null;
    };
  }
  static amountNegociationsValidator(paymentAmount: number): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (control.parent === undefined) {
        return null;
      }
      if (isNaN(control.value)) {
        return {
          numericError: true
        };
      }
      if (!isNaN(paymentAmount) && control.parent && control.parent.parent) {
        const amounts = (control.parent.parent.controls as Array<any>).reduce(
          (acc, curr) => {
            const negoAmount = curr.controls['negotiatedAmount'].value;
            if (!isNaN(negoAmount)) {
              acc += negoAmount;
            }
            return acc;
          },
          0
        );
        let refinancingAmount = control.parent.parent.parent.get(
          'refinancingForm'
        );
        refinancingAmount = refinancingAmount
          ? refinancingAmount.get('amountToBeRefinanced').value
          : 0;
        if (paymentAmount >= amounts + refinancingAmount) {
          return null;
        } else {
          return {
            sumError: true
          };
        }
      }
      return null;
    };
  }

  static minDate(control: AbstractControl): ValidationErrors | null {
    if (control.value == null || control.value === undefined) {
      return null;
    }
    if (
      isNaN(Date.parse(control.value)) ||
      new Date().setHours(0, 0, 0, 0) > new Date(control.value).getTime()
    ) {
      return {
        invalidValue: true
      };
    }
    return null;
  }
}
