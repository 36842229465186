import { Component, OnInit, OnDestroy } from '@angular/core';
import { LettreCredit } from 'src/app/models/lettre-credit';
import { Subscription } from 'rxjs';
import { DataService } from 'src/app/services/data.service';
import { CreateLcService } from 'src/app/services/create-lc.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ProrogService } from 'src/app/services/prorog.service';
import { LastComment } from 'src/app/models/lastComment';
import { RemdocService } from 'src/app/services/remdoc.service';
import { Remdoc } from 'src/app/models/remdoc';
import { Products } from 'src/app/util';
import { IssuedTransfer } from 'src/app/models/issuedTransfer';
import { IssuedTransferService } from 'src/app/services/issued-transfer.service';
import { ClientView } from 'src/app/models/client-view';
import { ClientSharedService } from 'src/app/services/client-shared-service';

@Component({
  selector: 'app-prorog-area',
  templateUrl: './prorog-area.component.html',
  styleUrls: ['./prorog-area.component.css']
})
export class ProrogAreaComponent implements OnInit, OnDestroy {
  public credoc: LettreCredit;
  public remdoc: Remdoc;
  public issued: IssuedTransfer;
  public product: string;
  public products = Products;
  public lastComment: LastComment;
  private prorogDataSubscription: Subscription;
  private dataServiceSubscription: Subscription;
  currency: string;
  public id: string;
  public prorogationView: string;
  public taskId: string;
  public client: ClientView;
  EMPTY = '';
  constructor(
    private createLCService: CreateLcService,
    private dataService: DataService,
    private remdocService: RemdocService,
    private router: Router,
    private prorogService: ProrogService,
    private route: ActivatedRoute,
    private issuedService: IssuedTransferService,
    private clientSharedService: ClientSharedService
  ) {}

  ngOnInit() {
    this.client = this.clientSharedService.getClient();
    this.id = this.route.snapshot.params['id'];
    this.prorogDataSubscription = this.prorogService.currentProrogFormData.subscribe(
      data => {
        this.prorogationView = data.prorogationView;
      }
    );
    this.dataServiceSubscription = this.dataService.currentData.subscribe(
      data => {
        this.product = data.product;
        switch (data.product) {
          case Products.CREDOC_IMPORT:
            this.initLc(data.lcId);
            break;
          case Products.REMDOC_IMPORT:
            this.getRemdoc(data.lcId);
            break;
          case Products.TRANSFER_ISSUED:
            this.getIssued(data.lcId);
            break;
        }
        this.taskId = data.taskId;
        this.lastComment = data.lastComment;
      }
    );
  }

  getRemdoc(remdocId: string) {
    this.remdocService.getRemdoc(+remdocId, this.client.id).subscribe(res => {
      this.remdoc = res;
      this.setCurrency(this.remdoc.currency);
    });
  }

  getIssued(issuedId: string) {
    this.issuedService.getById(+issuedId, this.client.id).subscribe(res => {
      this.issued = res;
      this.setCurrency(this.issued.currency);
    });
  }

  returnToList() {
    this.router.navigate(['/lc/list']);
  }

  initLc(lcid: string) {
    this.createLCService.initCredoc(+lcid, this.client.id).subscribe(credoc => {
      this.credoc = credoc;
      this.setCurrency(credoc.currency);
    });
  }

  setCurrency(currency) {
    this.currency = this.currency ? this.currency : currency;
  }

  ngOnDestroy(): void {
    this.prorogService.changeProrogFormData({
      prorogationView: null
    });
    this.dataService.changeData({
      product: null,
      summary: false,
      taskId: null,
      paymentId: null,
      lcId: null,
      taskDefinitionKey: null,
      lastComment: { fullMessage: null, time: null, userId: null },
      action: null,
      decision: null
    });
    if (this.dataServiceSubscription) {
      this.dataServiceSubscription.unsubscribe();
    }
    if (this.dataServiceSubscription) {
      this.dataServiceSubscription.unsubscribe();
    }
    if (this.prorogDataSubscription) {
      this.prorogDataSubscription.unsubscribe();
    }
  }
}
