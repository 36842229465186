import { Beneficiary } from './beneficiary';

export class Applicant {
  public corporateName: string;
  public address: string;
  public phone: string;
  public email: string;
  public beneficiaries: Array<Beneficiary>;
  public accounts: string[];
  constructor() {}
}
