import { Component, OnInit, OnDestroy } from "@angular/core";
import { UserService } from "src/app/services/user.service";
import { Router } from "@angular/router";
import { NotificationService } from "src/app/services/notification.service";
import { NotificationDetail } from "src/app/models/notification-detail";
import { takeUntil } from "rxjs/operators";
import { componentDestroyed } from "@w11k/ngx-componentdestroyed";

@Component({
  selector: "app-side-menu-right",
  templateUrl: "./side-menu-right.component.html",
  styleUrls: ["./side-menu-right.component.css"]
})
export class SideMenuRightComponent implements OnInit, OnDestroy {
  public notification = 0;
  status = false;
  notifications: Array<NotificationDetail>;
  constructor(
    private userService: UserService,
    private notificationService: NotificationService,
    private router: Router
  ) {
    const stompClient = this.notificationService.connect();
    this.userService.getUserName().subscribe(username => {
      localStorage.setItem('username', username);
      stompClient.connect({}, _frame => {
        stompClient.debug = null
        // Subscribe to notification topic
        stompClient.subscribe("/topic/SOGETRADE_" + username, message => {
          // Update notifications attribute with the recent messsage sent from the server
          const lastNotif = this.notification;
          this.notification = JSON.parse(message.body);
          if (lastNotif !== this.notification) {
            this.notificationService.isNotificationRefreshed.next(
              this.notification
            );
          }
        });
      });
    });
  }
  ngOnInit() {
    this.notificationService.getNewNotifNumber().subscribe(value => {
      return (this.notification = value);
    });
  }
  toggleNotificationMenu() {
    this.status = !this.status;
    if (this.status) {
      this.getNotifications();
    }
  }
  getNotifications() {
    this.notificationService
      .resetNotifNumber()
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(() => (this.notification = null));
    this.notificationService
      .getNotifications(0, 5)
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(notifications => {
        this.notifications = notifications.contentList;
      });
  }
  markAsRead(notification: NotificationDetail) {
    this.notificationService.markAsRead(notification.id, true).subscribe(() => {
      this.notifications.map((notif, index) => {
        if (notification.id === notif.id) {
          this.notifications[index].read = true;
        }
      });
    });
  }
  newCredoc() {
    return this.router.navigate(["/lc"]);
  }
  newVirementEmis() {
    return this.router.navigate(["/issued-transfer/init/new"]);
  }
  logout(): void {
    this.userService.logout();
  }
  ngOnDestroy(): void { }
  newBeneficiary() {
    this.status = false;
    return this.router.navigate(["/beneficiary"]);
  }
  listModele() {
    this.status = false;
    return this.router.navigate(["lc/models/list"]);
  }
  routeToNotificationList() {
    this.status = false;
    this.router.navigate(["/notification/list"]);
  }
}
