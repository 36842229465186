// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
import { KeycloakConfig } from 'keycloak-angular';

// Add here your keycloak setup infos
const keycloakConfig: KeycloakConfig = {
  url: 'https://sso.digitalfactory.sgmaroc.com/auth',
  realm: 'Etrade',
  clientId: 'etrade-customer-client'
};
const credocRoles = [
  'CREDOC_IMPORT_INITIATOR',
  'CREDOC_IMPORT_CONSULTOR',
  'CREDOC_IMPORT_VALIDATOR'
];
const remdocRoles = [
  'REMDOC_IMPORT_INITIATOR',
  'REMDOC_IMPORT_CONSULTOR',
  'REMDOC_IMPORT_VALIDATOR'
];
const transferReceivedRoles = [
  'TRANSFER_RECEIVED_INITIATOR',
  'TRANSFER_RECEIVED_CONSULTOR',
  'TRANSFER_RECEIVED_VALIDATOR'
];

const transferIssuedRoles = [
  'TRANSFER_ISSUED_INITIATOR',
  'TRANSFER_ISSUED_CONSULTOR',
  'TRANSFER_ISSUED_VALIDATOR'
];

const beneficiaryRoles = [
  { name: 'BENEFECIARY_INITIATOR', label: 'Initier un bénéficiaire' },
  { name: 'BENEFECIARY_VALIDATOR', label: 'Valider un bénéficiaire' }
];
const rolesMapping: any = {
  CREDOC_IMPORT_INITIATOR: [
    'initier_credoc',
    'modifier_credoc',
    'annuler_credoc',
    'delete_credoc',
    'init_extension',
    'show_international_operations',
    'show_credoc',
    'show_credoc_import',
    'show_dashboard',
    'show_referentiel',
    'show_beneficiaries',
    'show_models'
  ],
  CREDOC_IMPORT_CONSULTOR: [
    'consulter_credoc',
    'show_international_operations',
    'show_credoc',
    'show_credoc_import',
    'show_referentiel',
    'show_beneficiaries'
  ],
  CREDOC_IMPORT_VALIDATOR: [
    'valider_credoc',
    'rejeter_credoc',
    'show_dashboard',
    'show_international_operations',
    'show_credoc',
    'show_credoc_import',
    'show_referentiel',
    'show_beneficiaries'
  ],
  BENEFECIARY_INITIATOR: [
    'initier_benef',
    'modifier_benef',
    'supprimer_benef',
    'show_referentiel',
    'show_beneficiaries',
    'add_beneficiary'
  ],
  BENEFECIARY_VALIDATOR: [
    'valider_benef',
    'rejeter_benef',
    'show_referentiel',
    'show_beneficiaries'
  ],
  REMDOC_IMPORT_INITIATOR: [
    'show_international_operations',
    'show_remdoc',
    'show_remdoc_import',
    'show_dashboard',
  ],
  REMDOC_IMPORT_CONSULTOR: [
    'show_international_operations',
    'show_remdoc',
    'show_remdoc_import'
  ],
  REMDOC_IMPORT_VALIDATOR: [
    'show_dashboard',
    'show_international_operations',
    'show_remdoc',
    'show_remdoc_import'
  ],
  TRANSFER_RECEIVED_INITIATOR: [
    'show_international_operations',
    'show_transfer_received',
    'show_dashboard'
  ],
  TRANSFER_RECEIVED_CONSULTOR: [
    'show_international_operations',
    'show_transfer_received'
  ],
  TRANSFER_RECEIVED_VALIDATOR: [
    'show_dashboard',
    'show_international_operations',
    'show_transfer_received'
  ],
  SUPER_USER: ['show_parameters'],
  TRANSFER_ISSUED_INITIATOR: [
    'show_international_operations',
    'show_transfer_issued',
    'show_dashboard',
    'initiate_transfer_issued',
    'init_transfer_issued_extension',
    'delete_transfer_issued'
  ],
  TRANSFER_ISSUED_CONSULTOR: [
    'show_international_operations',
    'show_transfer_issued'
  ],
  TRANSFER_ISSUED_VALIDATOR: [
    'show_dashboard',
    'show_international_operations',
    'show_transfer_issued'
  ]
};

export const environment = {
  production: true,
  keycloak: keycloakConfig,
  roles_mapping: rolesMapping,
  debugMode: false,
  // expiring delay alert in minute
  expiring_delay_alert: 25,
  credocRoles,
  remdocRoles,
  transferReceivedRoles,
  transferIssuedRoles,
  beneficiaryRoles,
  urlPortal:'https://mybusiness.sgmaroc.com/',
  servicePortal:'portal-customer/',
  url:'/sogetrade-customer'
};
