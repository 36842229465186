import { Component, OnInit, OnDestroy } from '@angular/core';
import { ModeleService } from 'src/app/services/modele.service';
import { Router, NavigationEnd } from '@angular/router';
import { Subscription } from 'rxjs';
import { ClientView } from 'src/app/models/client-view';
import { ClientSharedService } from 'src/app/services/client-shared-service';

@Component({
  selector: 'app-modele-list',
  templateUrl: './modele-list.component.html',
  styleUrls: ['./modele-list.component.css']
})
export class ModeleListComponent implements OnInit, OnDestroy {
  modeles: Array<any> = [];
  showDeleteModele = false;
  private navigationSubscription: Subscription;
  public client: ClientView;
  id: string;
  deleteModeleMessage =
    'Etês-vous sûr vouloir supprimer le modèle séléctionné ?';
  constructor(
    private router: Router, 
    private modeleService: ModeleService,
    private clientSharedService: ClientSharedService
    ) {
    this.navigationSubscription = this.router.events.subscribe((e: any) => {
        if (e instanceof NavigationEnd) {
          this.ngOnInit();
        }
      });
    }

  ngOnInit() {
    this.client = this.clientSharedService.getClient();
    this.getModeles();
  }

  ngOnDestroy(): void {
    if (this.navigationSubscription) {
     this.navigationSubscription.unsubscribe();
    }
   }

  openRecap(id: string) {
    this.router.navigate(['modele/recap/' + id]);
  }
  getModeles() {
    this.modeleService.getModeles(this.client.id).subscribe(modeles => {
      this.modeles = modeles;
    });
  }

  deleteModel() {
    this.modeleService.deleteModele(this.id, this.client.id).subscribe(() => this.getModeles());
    this.showDeleteModele = false;
  }
}
