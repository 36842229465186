import { Component, OnInit } from '@angular/core';
import { SigningSchemaService } from 'src/app/services/signing-schema.service';
import { productList } from 'src/app/models/productList';
import { ClientView } from 'src/app/models/client-view';
import { ClientSharedService } from 'src/app/services/client-shared-service';

@Component({
  selector: 'app-schema-list',
  templateUrl: './schema-list.component.html',
  styleUrls: ['./schema-list.component.css']
})
export class SchemaListComponent implements OnInit {
  public signingSchemaList: Array<SigningSchema> = [];
  public client: ClientView;
  constructor(
    private schemaSigningService: SigningSchemaService,
    private clientSharedService: ClientSharedService
  ) {}

  ngOnInit() {
    this.schemaSigningService
      .getSigningSchemas(this.client.id)
      .subscribe(listSchema => (this.signingSchemaList = listSchema));
  }

  setProductLabel(productName: string): string {
    return productList.filter(p => p.PREFIX_ROLE === productName)[0]
      .PRODUCT_NAME;
  }
}
