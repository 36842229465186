import { Component, OnInit, OnDestroy } from "@angular/core";
import { Router } from "@angular/router";
import { Subscription, of, iif } from "rxjs";
import { mergeMap, mapTo } from "rxjs/operators";
import { LoaderService } from "src/app/services/loader.service";
import { LoaderState } from "src/app/models/loader";

@Component({
  selector: "app-default-layout",
  templateUrl: "./default-layout.component.html",
  styleUrls: ["./default-layout.component.css"]
})
export class DefaultLayoutComponent implements OnInit {
  disableContent = false;
  title = "client";
  private subscription: Subscription;

  public constructor(
    private router: Router,
    private loaderService: LoaderService
  ) {}

  ngOnInit() {
    //this.router.navigate([""]);
    this.subscription = this.loaderService.loaderState.subscribe(
      (state: LoaderState) => {
        of(state)
          .pipe(
            mergeMap(() =>
              iif(() => !state.show, of(state).pipe(mapTo(null)), of(null))
            )
          )
          .subscribe(() => {
            this.disableContent = state.show;
          });
      }
    );
  }
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
