import { Component, Input, Output, EventEmitter, ViewChild, ElementRef, OnInit } from '@angular/core';

@Component({
  selector: 'app-dialog-confirm',
  templateUrl: './dialog-confirm.component.html',
  styleUrls: ['./dialog-confirm.component.css']
})
export class DialogConfirmComponent implements OnInit {
  @Input() message;
  @Input() validatorCilcked;
  @Input() parentDivClass;
  @Output() confirmed = new EventEmitter<string>();
  @Output() cancelled = new EventEmitter<string>();
  @Input() confirmButtonText  = null;
  @Input() valid = true;
  @ViewChild('parentDiv') parentDiv: ElementRef;
  @Input() displayConfirmDialog = false;
  constructor() {}
  ngOnInit(): void {
    if (this.parentDivClass && this.parentDivClass.split) {
      this.parentDivClass
        .split(' ')
        .map(cl => this.parentDiv.nativeElement.classList.add(cl));
    }
  }
  onConfirm() {
    this.confirmed.emit('CONFIRMED');
  }

  onCancel() {
    this.cancelled.emit('CANCELLED');
  }
}
