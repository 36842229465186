import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Task } from '../models/task';
import { ClientView } from '../models/client-view';
import { ClientSharedService } from './client-shared-service';
import { environment } from 'src/environments/environment';
const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json'
  })
};
@Injectable()
export class HomeService {

  

  constructor(private http: HttpClient,
    private clientSharedService: ClientSharedService) {}

  getTasks(clientId: number): Observable<Task[]> {
    return this.http.get<Task[]>(`${environment.url}/tasks/client/${clientId}`);
  }
  lockTask(camundaId: string, clientId: number): Observable<void> {
    return this.http.post<void>(
      `${environment.url}/lock/${camundaId}/client/${clientId}`,
      httpOptions
    );
  }

  unlockTask(camundaId: string): Observable<void> {
    return this.http.post<void>(
      `${environment.url}/unlock/` + camundaId,
      httpOptions
    );
  }
}
