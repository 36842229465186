import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import SockJs from 'sockjs-client';
import Stomp from 'stompjs';
import { Notification } from '../models/notification';
import { environment } from 'src/environments/environment';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json'
  })
};

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  public isNotificationRefreshed: BehaviorSubject<number> = new BehaviorSubject<
    number
  >(null);
  constructor(private http: HttpClient) {}

  // Open connection with the back-end socket
  public connect() {
    const socket = new SockJs(`${environment.url}/socket/`);

    const stompClient = Stomp.over(socket);
    stompClient.debug = null
    return stompClient;
  }

  getNewNotifNumber(): Observable<number> {
    return this.http.get<number>(
      `${environment.url}/user-notification/numberUnread`,
      httpOptions
    );
  }

  resetNotifNumber() {
    return this.http.put<{}>(
      `${environment.url}/user-notification/reset`,
      httpOptions
    );
  }

  getNotifications(
    page: number,
    size: number
  ): Observable<Notification> {
    return this.http.get<Notification>(
      `${environment.url}/user-notification/list?page=${page}&size=${size}`,
      httpOptions
    );
  }
  markAsRead(id: number, read: boolean): Observable<void> {
    return this.http.put<void>(
      `${environment.url}/user-notification/${id}/markAs/${read}`,
      httpOptions
    );
  }
}
