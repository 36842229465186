import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { RemdocService } from 'src/app/services/remdoc.service';
import { CreateLcService } from 'src/app/services/create-lc.service';
import { Subscription } from 'rxjs';
import { Remdoc } from 'src/app/models/remdoc';
import { PaymentOperation } from 'src/app/models/paymentOperation';
import { ClientView } from 'src/app/models/client-view';
import { ClientSharedService } from 'src/app/services/client-shared-service';

@Component({
  selector: 'app-payment-rdi-recap',
  templateUrl: './payment-rdi-recap.component.html',
  styleUrls: ['./payment-rdi-recap.component.css']
})
export class PaymentRdiRecapComponent implements OnInit, OnDestroy {
  private getRemdocSubscription: Subscription;
  private getPaymentSubscription: Subscription;
  public remdoc: Remdoc;
  public payment: PaymentOperation;
  public client: ClientView
  constructor(
    private route: ActivatedRoute,
    private remdocService: RemdocService,
    private createLcService: CreateLcService,
    private clientSharedService: ClientSharedService
  ) {}

  ngOnInit() {
    this.client = this.clientSharedService.getClient();
    const remdocId = this.route.snapshot.params['remdocId'];
    const paymentId = this.route.snapshot.params['paymentId'];
    this.getRemdoc(remdocId);
    this.getRdiPayment(paymentId);
  }

  ngOnDestroy() {
    if (this.getRemdocSubscription) {
      this.getRemdocSubscription.unsubscribe();
    }
    if (this.getPaymentSubscription) {
      this.getPaymentSubscription.unsubscribe();
    }
  }

  getRemdoc(remdocId: number) {
    this.getRemdocSubscription = this.remdocService
      .getRemdoc(remdocId, this.client.id)
      .subscribe(res => {
        this.remdoc = res;
      });
  }

  getRdiPayment(rdiPaymentId: number) {
    this.getPaymentSubscription = this.createLcService
      .getPayment(rdiPaymentId, this.client.id)
      .subscribe(payment => {
        this.payment = payment;
      });
  }
}
