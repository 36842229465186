import { Directive, ElementRef, Input, HostListener } from '@angular/core';
import { Util } from '../util';

@Directive({
  selector: '[appExcludeSpecialChar]'
})
export class ExcludeSpecialCharDirective {

  @Input() isSpecialChar: boolean;

  constructor(private el: ElementRef) { }

  @HostListener('keypress', ['$event']) onKeyPress(event) {
    return !Util.specialCharactersRegEx().test(event.key);
  }

  @HostListener('paste', ['$event']) blockPaste(event: KeyboardEvent) {
    this.validateFields(event);
  }

  validateFields(event) {
    setTimeout(() => {

      this.el.nativeElement.value = this.el.nativeElement.value.replace(Util.specialCharactersRegEx(), '').replace(/\s/g, '');
      event.preventDefault();

    }, 100);
  }
}
