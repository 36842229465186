import { Component, OnInit, Input } from '@angular/core';
import { Util } from 'src/app/util';

@Component({
  selector: 'app-negociations-recap',
  templateUrl: './negociations-recap.component.html',
  styleUrls: ['./negociations-recap.component.css']
})
export class NegociationsRecapComponent implements OnInit {
  @Input() currency: string;
  @Input() model: any;
  constructor() {}

  ngOnInit() {}

  getNegociationTypeText(type): string {
    return Util.getNegociationTypeText(type);
  }
  negociationTrackBy(index: number, _obj: any): any {
    return index;
  }
}
