import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable, of } from 'rxjs';

import { Document } from '../models/document';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DocumentService {
  constructor(private http: HttpClient) {}

  // Credoc import docs
  getUrlCredocImportDocument(id: number, clientId: number): Observable<HttpResponse<Blob>> {
    return this.http.get(
      `${environment.url}/credocImports/clients/${clientId}/downloadDocuments/${id}`,
      { responseType: 'blob', observe: 'response' }
    );
  }

  // Pyament docs
  getUrlPaymentDocument(id: number, clientId: number): Observable<HttpResponse<Blob>> {
    return this.http.get(
      `${environment.url}/credocImports/payment/downloadDocuments/${id}/client/${clientId}`,
      { responseType: 'blob', observe: 'response' }
    );
  }

   // Pyament docs
   getUrlRemdocDocument(id: number, clientId: number): Observable<HttpResponse<Blob>> {
    return this.http.get(
      `${environment.url}/remdocImports/downloadDocuments/${id}/client/${clientId}`,
      { responseType: 'blob', observe: 'response' }
    );
  }


  // Expiration docs
  getUrlExpirationDocument(id: number, clientId: number): Observable<HttpResponse<Blob>> {
    return this.http.get(
      `${environment.url}/refinancing/downloadDocuments/${id}/client/${clientId}`,
      {
        responseType: 'blob',
        observe: 'response'
      }
    );
  }
  // Received transfer docs
  getUrlReceivedTransferDocument(id: number, clientId: number): Observable<HttpResponse<Blob>> {
    return this.http.get(
      `${environment.url}/transferReceived/clients/${clientId}/downloadDocuments/${id}`,
      {
        responseType: 'blob',
        observe: 'response'
      }
    );
  }
  getUrlIssuedTransferDocument(id: number, clientId: number): Observable<HttpResponse<Blob>> {
    return this.http.get(
      `${environment.url}/transferIssued/clients/${clientId}/downloadDocuments/${id}`,
      {
        responseType: 'blob',
        observe: 'response'
      }
    );
  }
  removeCredocImportDocumentFromLC(documentId: number, clientId: number): Observable<void> {
    return this.http.delete<void>(
      `${environment.url}/credocImports/clients/${clientId}/removeDocuments/${documentId}`
    );
  }

  removePaymentDocumentFromLC(documentId: number, clientId: number): Observable<void> {
    return this.http.delete<void>(
      `${environment.url}/credocImports/payment/removeDocuments/${documentId}/client/${clientId}`
    );
  }

  removeExpirationDocumentFromLC(documentId: number, clientId: number): Observable<void> {
    return this.http.delete<void>(
      `${environment.url}/refinancing/removeDocuments/${documentId}/client/${clientId}`
    );
  }

  removeRtDocs(documentId: number, clientId: number): Observable<void> {
    return this.http.delete<void>(
      `${environment.url}/transferReceived/client/${clientId}/removeDocuments/${documentId}`
    );
  }

  removeITDocs(documentId: number, clientId: number): Observable<void> {
    return this.http.delete<void>(
      `${environment.url}/transferIssued/clients/${clientId}/removeDocuments/${documentId}`
    );
  }

  uploadDocuments(id: number, documents: Document[], path: string) {
    const formData = new FormData();
    // filter unsaved docs
    documents = documents.filter(
      doc => doc.file !== null && doc.file !== undefined
    );
    documents.forEach((doc: Document, i) => {
      if (doc.file) {
        formData.append(`file[${i}]`, doc.file);
        formData.append(`type[${i}]`, doc.type);
      }
    });
    if (documents.length > 0) {
      return this.http.post<Document>(path + id, formData);
    }
    return of(null);
  }

  uploadDocs(documents: Document[], path: string) {
    const formData = new FormData();
    // filter unsaved docs
    documents = documents.filter(
      doc => doc.file !== null && doc.file !== undefined
    );
    documents.forEach((doc: Document, i) => {
      if (doc.file) {
        formData.append(`file[${i}]`, doc.file);
        formData.append(`type[${i}]`, doc.type);
      }
    });
    if (documents.length > 0) {
      return this.http.post<Document>(path, formData);
    }
    return of(null);
  }
}
