import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { CreateLcService } from 'src/app/services/create-lc.service';
import { iif, of } from 'rxjs';
import { LettreCredit } from 'src/app/models/lettre-credit';
import { PaymentOperation } from 'src/app/models/paymentOperation';
import { Negociation } from 'src/app/models/negociation';
import { Status, Operations, Util, NegociationTypes } from 'src/app/util';
import { map, mergeMap, delay } from 'rxjs/operators';
import { AlertComponent } from 'src/app/core/alert/alert.component';
import { PaymentOperationComponent } from '../../payment-operation/payment-form/payment-operation.component';
import { LastComment } from 'src/app/models/lastComment';
import { DialogSignComponent } from 'src/app/core/dialog-sign/dialog-sign.component';
import { ClientSharedService } from 'src/app/services/client-shared-service';
import { ClientView } from 'src/app/models/client-view';

@Component({
  selector: 'app-summary',
  templateUrl: './summary.component.html',
  styleUrls: ['./summary.component.css']
})
export class SummaryComponent implements OnInit {
  @Input() model: LettreCredit;
  @Input() summary;
  @Input() lastComment: LastComment;
  @Input() taskDefinitionKey;
  @Input() operation;
  @Input() assignee;
  @Input() decision;
  @Input() payment: PaymentOperation;
  datePickerConfig = Util.date_input_config;
  @ViewChild('confirmationContainer') confirmationContainer: ElementRef;
  @ViewChild('cancellationConfirmationContainer')
  cancellationConfirmationContainer: ElementRef;
  @ViewChild('paymentFormComp') paymentFormComp: PaymentOperationComponent;
  @ViewChild('summary_alert') summaryAlert: AlertComponent;
  @ViewChild('app_dialog_sign') appDialogSign: DialogSignComponent;
  @ViewChild('app_dialog_realization_sign')
  appDialogRealizationSign: DialogSignComponent;
  @ViewChild('app_dialog_cancellation_sign')
  appDialogCancellationSign: DialogSignComponent;
  @ViewChild('#app_dialog_payment_sign')
  appDialogPaymentSign: DialogSignComponent;

  public client: ClientView;

  validatorCilcked: { button: string };
  validateMessage =
    'Vous êtes sur le point de valider cette opération. Êtes-vous sûr de vouloir continuer ?';
  signMessage =
    'Vous êtes sur le point de signer cette opération. Entrer la code de signature.';
  rejectDefinitifMessage =
    'Vous êtes sur le point de rejeter définitivement cette opération. Veuillez saisir ci-dessous votre motif pour valider votre choix.';
  rejectTempMessage =
    'Vous êtes sur le point de rejeter provisoirement cette opération. Veuillez saisir ci-dessous votre motif pour valider votre choix.';
  submitMessage =
    'Vous êtes sur le point de soumettre cette demande pour signature. Êtes-vous sûr de vouloir continuer ?';
  submitCancellationMessage = `Vous êtes sur le point de soumettre l'annulation de cette demande pour signature.
  Êtes-vous sûr de vouloir continuer ?`;
  confirmRejectMessage =
    'Vous êtes sur le point de rejecter définitivement cette opération.';
  validatePaymentMessage =
    'Quelle est votre décision concernant ces instructions de paiements ?';
  constructor(
    private createLCService: CreateLcService,
    private router: Router,
    private clientSharedService: ClientSharedService
  ) {}

  ngOnInit() {
    this.client = this.clientSharedService.getClient();
    this.validatorCilcked = { button: '' };
    this.cancellationConfirmationContainer.nativeElement.style.display = this.isValidator()
      ? 'block'
      : 'none';
  }
  negociationTrackBy(index: number, obj: any): any {
    return index;
  }
  addNegociation() {
    const neg = new Negociation(NegociationTypes.FIXING);
    neg.paymentId = this.payment.id;
    this.payment.negociations.push(neg);
  }
  onValidatePayment(action) {
    if (action === 'VALIDATE') {
      this.createLCService
        .validatePayment(
          this.payment,
          this.model.dpResponse,
          this.model.challengeKey,
          this.client.id
        )
        .subscribe(
          () => {
            this.summaryAlert.snackbarData.display = { isDisplay: true };
            this.summaryAlert.snackbarData.message =
              'Le réglement a bien été signé.';
            this.summaryAlert.snackbarData.reference = this.model.requestId;
            setTimeout(() => {
              this.summaryAlert.snackbarData.display = { isDisplay: false };
              this.returnToTasks();
            }, 2000);
          },
          error => {
            this.appDialogPaymentSign.onRefresh(null);
          }
        );
    } else if (action === 'REJECT') {
      this.createLCService
        .rejectPayment(this.payment, this.client.id)
        .subscribe(() => this.returnToTasks());
    }
  }

  isPayment() {
    return this.operation === Operations.PAYMENT;
  }

  isViewRecapAction() {
    return this.operation === 'view_recap';
  }

  isViewRecapPaymentAction() {
    return this.operation === 'view_recap_payment';
  }

  animate(panel) {
    if (panel.style.display === 'block') {
      panel.style.display = 'none';
    } else {
      panel.style.display = 'block';
    }
  }

  setRealizationStatus(): string {
    return Util.setStatus('credoc_list', this.taskDefinitionKey).text;
  }

  setRegulationStatus(): string {
    return Util.setStatus('credoc_list', this.payment.status).text;
  }

  isRealization(): boolean {
    return this.operation === Operations.REALIZATION;
  }

  validateRealization() {
    if (this.isValidator()) {
      return (this.validatorCilcked.button = 'VALIDATED');
    } else {
      this.onValidationAction('VALIDATED');
    }
  }

  isCancellation(): boolean {
    return this.operation === Operations.CANCELLATION;
  }

  submitCancellation() {
    this.cancellationConfirmationContainer.nativeElement.style.display =
      'block';
  }

  setOperationText(): string {
    return Util.setOperationText(this.model.operation);
  }

  returnToList() {
    this.router.navigate(['/lc/list']);
  }

  onCancellationInitiated() {
    this.cancellationConfirmationContainer.nativeElement.style.display = 'none';
    this.createLCService.cancelCredoc(this.model.id, this.client.id).subscribe(res => {
      this.model.requestId = res.requestId;
      this.summaryAlert.snackbarData.display.isDisplay = true;
      this.summaryAlert.snackbarData.message = `L'annulation de la demande a été soumis pour signature.`;
      this.summaryAlert.snackbarData.reference = this.model.requestId;
      setTimeout(() => {
        this.summaryAlert.snackbarData.display.isDisplay = false;
        this.router.navigate(['/tasks']);
      }, 3000);
    });
  }

  onCancelCancellationInitiation() {
    this.cancellationConfirmationContainer.nativeElement.style.display = 'none';
  }

  isDecisionPresent(): boolean {
    return (
      this.taskDefinitionKey === Status.TO_SIGN ||
      this.taskDefinitionKey === Status.TO_REGULARIZE
    );
  }

  setDecisionText(): string {
    return Util.seDecisionText(this.decision);
  }

  onValidateCancellation(action: string) {
    if (action === 'VALIDATE') {
      this.createLCService
        .acceptCancellation(this.model, this.client.id)
        .subscribe(
          () => this.returnToTasks(),
          error => this.appDialogCancellationSign.onRefresh(null)
        );
    } else if (action === 'REJECT') {
      this.createLCService
        .rejectCancellation(this.model, this.client.id)
        .subscribe(() => this.returnToTasks());
    }
  }

  setPaymentTypeText(): string {
    return Util.setPaymentTypeText(this.payment.paymentType);
  }
  isRefinancingText(): string {
    return this.payment.refinancing ? 'Oui' : 'Non';
  }

  getNegociationTypeText(type): string {
    return Util.getNegociationTypeText(type);
  }

  isValidator() {
    return (
      typeof this.taskDefinitionKey === 'string' &&
      this.taskDefinitionKey === Status.TO_SIGN
    );
  }

  cancelSubmission() {
    this.summary.isSummary = false;
  }

  cancelValidation() {
    this.validatorCilcked.button = '';
  }

  onValidationAction(decision: string) {
    switch (this.taskDefinitionKey) {
      case Status.TO_REGULARIZE:
      case Status.TO_VALIDATE:
        if (decision === 'VALIDATED') {
          this.createLCService
            .acceptReservesRealization(this.model.id, this.model.taskId, this.client.id)
            .subscribe(
              () => {
                this.returnToTasks();
              },
              _error => {
                this.appDialogRealizationSign.onRefresh(null);
              }
            );
        } else if (decision === 'REJECTED') {
          this.createLCService
            .rejectReservesRealization(this.model.id, this.model.taskId, this.client.id)
            .subscribe(() => {
              this.returnToTasks();
            });
        }
        break;
      // validor
      case Status.TO_SIGN:
        if (decision === 'VALIDATED') {
          this.createLCService
            .acceptDecisionRealization(
              this.model.id,
              this.model.taskId,
              this.model.dpResponse,
              this.model.challengeKey,
              this.client.id
            )
            .subscribe(() => {
              this.returnToTasks();
            });
        } else if (decision === 'REJECTED') {
          this.createLCService
            .rejectDecisionRealization(+this.model.id, this.model.taskId, this.client.id)
            .subscribe(() => {
              this.returnToTasks();
            });
        }
        break;
      default:
    }
  }
  onValidateAction(validateAction: string) {
    this.signServerCall(validateAction);
  }
  returnToTasks() {
    this.router.navigate(['/tasks']);
  }

  signServerCall(action) {
    this.createLCService
      .signLC(
        this.model.taskId,
        this.model.id,
        action,
        this.model.comment,
        null,
        this.model.dpResponse,
        this.model.challengeKey,
        this.client.id
      )
      .subscribe(
        () => {
          this.returnToTasks();
        },
        _error => {
          if (this.appDialogSign) {
            this.appDialogSign.onRefresh(null);
          }
        }
      );
  }

  onSignAction() {
    this.signServerCall('SIGNED');
  }

  onSendAction() {
    this.confirmationContainer.nativeElement.style.display = 'none';
    this.createLCService
      .saveLC(this.model, this.client.id)
      .pipe(
        map(res => {
          this.summaryAlert.snackbarData.reference = res.requestId;
          this.model.id = res.id;
          this.model.requestId = res.requestId;
          return res;
        })
      )
      .pipe(
        mergeMap(lc =>
          iif(
            () =>
              this.model.documentList.filter(
                doc => doc.file !== null && doc.file !== undefined
              ).length > 0,
            this.createLCService.uploadCredocJoinedDocs(
              lc.id,
              this.client.id,
              lc.requestId,
              this.model.documentList
            ),
            of(null)
          )
        )
      )
      .pipe(
        map(documentList => {
          if (documentList !== null) {
            this.model.documentList = documentList;
          }
          return this.model;
        })
      )
      .pipe(
        mergeMap(lc => {
          return this.createLCService.submitLC(this.model, this.client.id);
        })
      )
      .pipe(
        map(lc => {
          this.summaryAlert.snackbarData.display.isDisplay = true;
          this.summaryAlert.snackbarData.message =
            'Votre dossier a été soumis pour signature.';
          this.summaryAlert.snackbarData.reference = this.model.requestId;
          return lc;
        }),
        delay(6000)
      )
      .subscribe(lc => {
        this.summaryAlert.snackbarData.display.isDisplay = false;
        this.returnToTasks();
      });
  }
}
