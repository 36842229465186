import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpResponse } from "@angular/common/http";
import { LettreCredit } from "src/app/models/lettre-credit";
import { Observable, iif, of } from "rxjs";
import { mergeMap } from "rxjs/operators";
import { Document } from "../models/document";
import { PaymentOperation } from "../models/paymentOperation";
import { DocumentService } from "./document.service";
import { environment } from 'src/environments/environment';

const httpOptions = {
  headers: new HttpHeaders({
    "Content-Type": "application/json"
  })
};

@Injectable()
export class CreateLcService {
  createLCUrl = `${environment.url}/credocImports`;

  constructor(
    private http: HttpClient,
    private documentService: DocumentService
  ) {}

  saveLC(credoc: LettreCredit, clientId: number): Observable<LettreCredit> {
    return this.http.post<LettreCredit>(
      `${environment.url}/credocImports/clients/${clientId}`,
      credoc,
      httpOptions
    );
  }

  submitNegociations(payment: PaymentOperation, clientId: number) {
    return this.http
      .put<number>(
        `${environment.url}/credocImports/payment/submission/client/${clientId}`,
        payment,
        httpOptions
      )
      .pipe(
        mergeMap(paymentResp => {
          if (paymentResp && payment.documentList.length > 0) {
            return this.uploadPaymentDocs(paymentResp, payment.documentList, clientId);
          }
          return of(null);
        })
      );
  }

  submitLC(credoc: LettreCredit,clientId: number): Observable<LettreCredit> {
    // call to complete task
    return this.http.post<LettreCredit>(
      `${environment.url}/credocImports/clients/${clientId}/submit`,
      credoc,
      httpOptions
    );
  }

  acceptReservesRealization(credocImportId: string, taskId: string, clientId: number) {
    return this.http.post<void>(
      `${environment.url}/credocImports/clients/${clientId}/realization/acceptation/reserves`,
      { id: credocImportId, taskId },
      httpOptions
    );
  }

  rejectReservesRealization(credocImportId, taskId: string,  clientId: number) {
    return this.http.post<void>(
      `${environment.url}/credocImports/clients/${clientId}/realization/rejection/reserves`,
      { id: credocImportId, taskId },
      httpOptions
    );
  }

  acceptDecisionRealization(
    credocImportId: string,
    taskId: string,
    dpResponse: string,
    challengeKey: string,
    clientId: number
  ) {
    return this.http.post<void>(
      `${environment.url}/credocImports/clients/${clientId}/realization/acceptation/decision`,
      { id: credocImportId, taskId, dpResponse, challengeKey },
      httpOptions
    );
  }

  rejectDecisionRealization(credocImportId: number, taskId: string, clientId: number) {
    return this.http.post<void>(
      `${environment.url}/credocImports/clients/${clientId}/realization/rejection/decision`,
      { id: credocImportId, taskId },
      httpOptions
    );
  }

  signLC(
    taskId: string,
    demandeId: string,
    event: string,
    comment: string,
    decision: string,
    dpResponse: string,
    challengeKey: string,
    clientId: number
  ) {
    return this.http.post<void>(
      `${environment.url}/credocImports/clients/${clientId}/validator/status`,
      {
        demandeId,
        event,
        taskId,
        comment,
        decision,
        dpResponse,
        challengeKey
      },
      httpOptions
    );
  }

  initCredoc(credocId: number, clientId: number): Observable<LettreCredit> {
    let url: string = this.createLCUrl + "/clients";
    url += "/" + clientId;
    url += "/by" ;
    if (credocId !== -1) {
      url += "/" + credocId;
    } else {
      url += "/-1";
    }
    return this.http.get<LettreCredit>(url);
  }

  getPayment(paymentId, clientId: number): Observable<PaymentOperation> {
    return this.http.get<PaymentOperation>(
      `${environment.url}/credocImports/payment/${paymentId}/client/${clientId}`
    );
  }

  validatePayment(
    payment: PaymentOperation,
    dpResponse: string,
    challengeKey: string,
    clientId: number
  ): Observable<void> {
    return this.http.put<void>(
      `${environment.url}/credocImports/payment/validation/client/${clientId}`,
      {
        id: payment.id,
        taskId: payment.taskId,
        dpResponse,
        challengeKey
      },
      httpOptions
    );
  }

  rejectPayment(payment: PaymentOperation, clientId: number): Observable<void> {
    return this.http.put<void>(
      `${environment.url}/credocImports/payment/rejection/client/${clientId}`,
      {
        id: payment.id,
        taskId: payment.taskId,
        comment: payment["comment"]
      },
      httpOptions
    );
  }

  uploadCredocJoinedDocs(
    credocId: string,
    clientId: number,
    requestId: string,
    documents: Document[]
  ): Observable<Document> {
    const formData = new FormData();
    // filter unsaved docs
    documents = documents.filter(
      doc => doc.file !== null && doc.file !== undefined
    );
    documents.forEach((doc: Document, i) => {
      if (doc.file) {
        formData.append(`file[${i}]`, doc.file);
        formData.append(`type[${i}]`, doc.type);
      }
    });
    if (documents.length > 0) {
      return this.http.post<Document>(
        `${environment.url}/credocImports/clients/${clientId}/uploadDocuments/${credocId}`,
        formData
      );
    }
  }

  uploadPaymentDocs(paymentId: number, documents: Document[], clientId: number) {
    return this.documentService.uploadDocs(
      documents,
      `${environment.url}/credocImports/payment/uploadDocuments/${paymentId}/client/${clientId}`
    );
  }

  uploadRtDocs(ReceivedTransferId: number, documents: Document[], clientId: number) {
    return this.documentService.uploadDocs(
      documents,
      `${environment.url}/transferReceived/client/${clientId}/uploadDocuments/${ReceivedTransferId}`
    );
  }

  getLCs(clientId: number): Observable<LettreCredit[]> {
    return this.http.get<LettreCredit[]>(
      `${environment.url}/credocImports/clients/${clientId}`,
      httpOptions
    );
  }

  cancelCredoc(credocId: string, clientId: number) {
    return this.http.post<LettreCredit>(
      `${environment.url}/credocImports/clients/${clientId}/canceling/${credocId}`,
      httpOptions
    );
  }

  rejectCancellation(credoc: LettreCredit, clientId: number): Observable<void> {
    const { id, taskId } = credoc;
    return this.http.post<void>(
      `${environment.url}/credocImports/clients/${clientId}/canceling/rejection`,
      { id, taskId },
      httpOptions
    );
  }
  acceptCancellation(credoc: LettreCredit, clientId: number): Observable<void> {
    const { id, taskId, dpResponse, challengeKey } = credoc;
    return this.http.post<void>(
      `${environment.url}/credocImports/clients/${clientId}/canceling/validation`,
      { id, taskId, dpResponse, challengeKey },
      httpOptions
    );
  }
  verifyAmount(
    productKey: string,
    amount: number,
    currency: string,
    clientId: number
  ): Observable<{ sigingSchemaExist: boolean; valueExchange: number }> {
    return this.http.get<{ sigingSchemaExist: boolean; valueExchange: number }>(
      `${environment.url}/clients/client/${clientId}/exchange/signingschema?productKey=${productKey}&amount=${"" +
        amount}&currency=${currency}`,
      httpOptions
    );
  }

  exchangeAmount(amount: number, currency: string, clientId: number): Observable<number> {
    return this.http.get<number>(
      `${environment.url}/clients/${clientId}/rate/exchange?amount=${"" +
        amount}&currency=${currency}`,
      httpOptions
    );
  }

  getRemdocImportAccounts(clientId: number): Observable<string[]> {
    return this.http.get<string[]>(
      `${environment.url}/clients/client/${clientId}/remdocImport/accounts`,
      httpOptions
    );
  }

  getCredocImportAccounts(clientId: number): Observable<string[]> {
    return this.http.get<string[]>(
      `${environment.url}/clients/client/${clientId}/credocImport/accounts`,
      httpOptions
    );
  }

  getTransferReceivedAccounts(clientId: number): Observable<string[]> {
    return this.http.get<string[]>(
      `${environment.url}/clients/client/${clientId}/transferReceived/accounts`,
      httpOptions
    );
  }

  
  getTransferIssuedAccounts(clientId: number): Observable<string[]> {
    return this.http.get<string[]>(
      `${environment.url}/clients/client/${clientId}/transferIssued/accounts`,
      httpOptions
    );
  }


  getIncoterms(clientId: number): Observable<string[]> {
    return this.http.get<string[]>(
      `${environment.url}/credocImports/clients/${clientId}/incoterms`,
      httpOptions
    );
  }

  printReport(credocId: string, clientId: number): Observable<HttpResponse<Blob>> {
    return this.http.get(
      `${environment.url}/credocImports/clients/${clientId}/reporting/${credocId}`,
      { responseType: 'blob', observe: 'response' }
    );
  }

  generatePaymentReport(paymentId: number, clientId: number): Observable<HttpResponse<Blob>> {
    return this.http.get(
      `${environment.url}/credocImports/payment/${paymentId}/client/${clientId}/reporting`,
      { responseType: 'blob', observe: 'response' }
    );
  }

  deleteCredocImport(credocId: string, clientId: number) {
    return this.http.delete(
      `${environment.url}/credocImports/clients/${clientId}/deleting/${credocId}`,
      httpOptions
    );
  }

}
