import { KeycloakService, KeycloakEventType } from 'keycloak-angular';
import { UserService } from 'src/app/services/user.service';
import { Injectable, OnDestroy } from '@angular/core';
import { AuthorizedActions } from './AuthorizedActions';
import { environment } from 'src/environments/environment';
import { Observable, BehaviorSubject, timer, Subscription } from 'rxjs';
import { switchMap, map, mapTo } from 'rxjs/operators';

const TOKEN_EXPIRED_ALERT = environment.expiring_delay_alert;
@Injectable({
  providedIn: 'root'
})
export class AuthorizationService {
  constructor(private keycloakService: KeycloakService, private userService: UserService) {
    // check if token is refreshed
    if (TOKEN_EXPIRED_ALERT) {
      this.isTokenRefreshed().subscribe();
    }
  }

  public isTokenRefreshedSubject: BehaviorSubject<void> = new BehaviorSubject<
    void
  >(null);
  public isAuthorizeTo(action: AuthorizedActions):  Observable<string[]> {
    return this.userService.getUserRoles();
  }

  /**
   *
   * @param roles list of user roles
   * Check if the current user have at least one role in the given list
   */
  public haveOneOfRoles(roles: Array<string>): Observable<string[]> {
    return this.userService.getUserRoles();
  }


  public isTokenRefreshed(): Observable<void> {
    return this.keycloakService.keycloakEvents$.pipe(
      map(keycloakEvent => {
        if (keycloakEvent.type === KeycloakEventType.OnAuthRefreshSuccess) {
          this.isTokenRefreshedSubject.next(null);
        }
      })
    );
  }
  public tokenWillExpire(): Observable<any> {
    return this.isTokenRefreshedSubject.pipe(
      switchMap(() => timer(TOKEN_EXPIRED_ALERT * 60 * 1000).pipe(mapTo(true)))
    );
  }
}
