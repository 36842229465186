import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { LettreCredit } from 'src/app/models/lettre-credit';

@Component({
  selector: 'app-show-diff',
  templateUrl: './show-diff.component.html',
  styleUrls: ['./show-diff.component.css']
})
export class ShowDiffComponent implements OnInit, OnChanges {
  @Input() labelParent: string;
  @Input() label: string;
  @Input() model_: LettreCredit;
  @Input() fieldName_: string;
  @Input() trueValue: string;
  @Input() falseValue: string;
  @Input() type_: string;

  fields: string[];
  newValue: string;
  oldValue: string;

  constructor() {}
  ngOnChanges() {
    this.fields = this.fieldName_.split('.');
    this.displayValue();
  }
  ngOnInit() {}
  displayValue() {
    let modelVal;
    if (this.model_) {
      modelVal = this.fields.reduce((acc: any, curr) => {
        if (acc && acc !== undefined) {
          return acc[curr];
        }
      }, this.model_);
    }
    this.newValue = modelVal;

    let modelOldVal;
    if (this.model_ && this.model_.parent) {
      modelOldVal = this.fields.reduce((acc: any, curr) => {
        if (acc && acc !== undefined) {
          return acc[curr];
        }
      }, this.model_.parent);
    }
    this.oldValue = modelOldVal;
  }
}
