import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ProrogationRecap } from '../models/prorogationRecap';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class ProrogService {
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };
  private prorogFormData = new BehaviorSubject({
    prorogationView: null
  });
  currentProrogFormData = this.prorogFormData.asObservable();
  constructor(private http: HttpClient) {}

  changeProrogFormData(data: { prorogationView: string }) {
    this.prorogFormData.next(data);
  }

  askForProrogation(
    paymentId: number,
    extensionDuration: number,
    clientId: number
  ): Observable<{}> {
    return this.http.post(
      `${environment.url}/refinancing/extension/initiation/${paymentId}/${extensionDuration}/client/${clientId}`,
      this.httpOptions
    );
  }

  askForIssuedProrogation(id: number, duration: number, clientId: number): Observable<Object> {
    return this.http.post(
      `${environment.url}/refinancing/transferIssued/extension/initiation/${id}/${duration}/client/${clientId}`,
      this.httpOptions
    );
  }

  updateProrogation(extensionDuration, extensionId, taskId, clientId: number): Observable<{}> {
    return this.http.put(
      `${environment.url}/refinancing/extension/update/${extensionDuration}/client/${clientId}`,
      {
        id: extensionId,
        taskId
      },
      this.httpOptions
    );
  }

  getProrgInitiationData(id: number) {
    return this.http.get(`${environment.url}/refinancing/data/${id}`);
  }

  getIssuedProrgInitiationData(id: number ,clientId: number) {
    return this.http.get(
      `${environment.url}/refinancing/transferIssued/data/${id}/client/${clientId}`
    );
  }

  getProrogData(prorogId, clientId: number): Observable<ProrogationRecap> {
    return this.http.get<ProrogationRecap>(
      `${environment.url}/refinancing/extension/data/${prorogId}/client/${clientId}`
    );
  }

  signProrogation(id, dpResponse, challengeKey, clientId: number): Observable<{}> {
    return this.http.post(
      `${environment.url}/refinancing/extension/validator/acceptation/client/${clientId}`,
      {
        id,
        dpResponse,
        challengeKey
      },
      this.httpOptions
    );
  }
  rejectProrog(id, comment, clientId: number) {
    return this.http.post(
      `${environment.url}/refinancing/extension/validator/rejection/client/${clientId}`,
      {
        id,
        comment
      },
      this.httpOptions
    );
  }

  tempRejectProrog(id, comment, clientId: number) {
    return this.http.post(
      `${environment.url}/refinancing/extension/validator/regularization/client/${clientId}`,
      {
        id,
        comment
      },
      this.httpOptions
    );
  }
}
