import { Component, OnInit, Input, NgZone, OnDestroy } from '@angular/core';
import { Util } from 'src/app/util';
import { Document } from 'src/app/models/document';
import { ErrorService } from 'src/app/core/error-utils/error.service';
import { VndError } from 'src/app/core/error-utils/types/VndError';
import { ModelType } from 'src/app/models/modelType';
import { DocumentService } from 'src/app/services/document.service';
import { Observable, Subscription } from 'rxjs';
import { ClientView } from 'src/app/models/client-view';
import { ClientSharedService } from 'src/app/services/client-shared-service';
@Component({
  selector: 'app-create-join-docs',
  templateUrl: './create-join-docs.component.html',
  styleUrls: ['./create-join-docs.component.css']
})
export class CreateJoinDocsComponent implements OnInit, OnDestroy {
  @Input()
  public model: any;

  @Input()
  public modelType: ModelType;

  private removeDocSubscription: Subscription;

  public client: ClientView;

  docsDropzone: any;
  CREDOC_IMPORT = 'CREDOC_IMPORT';
  config = {
    clickable: true,
    maxFiles: 100,
    autoReset: null,
    errorReset: null,
    cancelReset: null,
    autoProcessQueue: false,
    autoQueue: false,
    previewsContainer: '.dropzone-previews',
    acceptedFiles: 'image/*,.xlsx,.xls,.pdf,.doc,.docx',
    accept: (file: File, done) => {
      this.zone.run(() => {
        if (this.model.documentList == null) {
          this.model.documentList = [];
        }

        if (
          this.model.documentList.findIndex(doc => doc.name === file.name) !==
          -1
        ) {
          this.errorService.emitError(
            new VndError({ logref: 'FILE_ALREADY_PRESENT' })
          );
        } else {
          this.model.documentList.push({
            id: null,
            folderName: null,
            name: file.name,
            type: Util.removeExtFromName(file.name),
            file
          });
        }
      });
    }
  };
  constructor(
    private zone: NgZone,
    private docService: DocumentService,
    private errorService: ErrorService, 
    private clientSharedService: ClientSharedService
  ) {}

  ngOnInit() {
    this.client = this.clientSharedService.getClient();
  }

  ngOnDestroy() {
    if (this.removeDocSubscription) {
      this.removeDocSubscription.unsubscribe();
    }
  }

  removeDoc(index) {
    this.model.documentList.splice(index, 1);
  }

  removeDocFromModel(doc: Document) {
    if (doc.id === null) {
      const index = this.model.documentList.findIndex(
        document => document.name === doc.name
      );
      this.removeDoc(index);
    } else {
      let removeDoc$: Observable<void>;

      // Set the observable value
      switch (this.modelType) {
        case ModelType.CREDOC_IMPORT:
          removeDoc$ = this.docService.removeCredocImportDocumentFromLC(doc.id, this.client.id);
          break;

        case ModelType.PAYMENT:
          removeDoc$ = this.docService.removePaymentDocumentFromLC(doc.id, this.client.id);
          break;

        case ModelType.EXPIRATION:
          removeDoc$ = this.docService.removeExpirationDocumentFromLC(doc.id, this.client.id);
          break;
          case ModelType.RECEIVED_TRANSFER:
            removeDoc$ = this.docService.removeRtDocs(doc.id , this.client.id);
            break;
            case ModelType.ISSUED_TRANSFER:
              removeDoc$ = this.docService.removeITDocs(doc.id , this.client.id);
              break;
        default:
          return;
      }

      this.removeDocSubscription = removeDoc$.subscribe(sucess => {
        const index = this.model.documentList.findIndex(
          document => document.id === doc.id
        );
        this.removeDoc(index);
      });
    }
  }
}
