import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { Client } from "../models/client";
import { ClientView } from "../models/client-view";

@Injectable({
    providedIn: 'root'
  })
export class ClientSharedService {

  constructor() { }

  private _client:ClientView;
 
    addClient(client: ClientView) {
        this._client = client;
    }
 
    getClient(): ClientView {
        return this._client;
    }

}

