import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { LastComment } from 'src/app/models/lastComment';

@Component({
  selector: 'app-last-comment',
  templateUrl: './last-comment.component.html',
  styleUrls: ['./last-comment.component.css']
})
export class LastCommentComponent implements OnInit, OnDestroy {
  @Input() lastComment: LastComment;
  mo = 'mo';
  constructor() {}

  ngOnInit() {}
  ngOnDestroy(): void {
    if (this.lastComment) {
      this.lastComment = null;
    }
  }
}
