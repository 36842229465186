export class User {
  public cin: string;
  public email: string;
  public firstname: string;
  public id: number;
  public keycloakRoles: Array<string>;
  public lastname: string;
  public username: string;
  public enabled: boolean;
  constructor() {}
}