import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { TasksComponent } from './core/tasks/tasks.component';
import { LcComponent } from './components/lc/lc-comp/lc.component';
import { BeneficiaryComponent } from './components/beneficiary/beneficiary.component';
import { ListComponent } from './components/lc/list/list.component';
import { RemdocListComponent } from './components/remdoc/list/list.component';
import { ManageComponent } from './components/beneficiary/manage/manage.component';
import { ProrogAreaComponent } from './components/lc/prorogation/prorog-area/prorog-area.component';
import { ExpirAreaComponent } from './components/lc/refinancing-expiration/expir-area/expir-area.component';
import { RemdocRecapInitiatorComponent } from './components/remdoc/opening/recap-initiator/recap-initiator.component';
import { RemdocRecapValidatorComponent } from './components/remdoc/opening/recap-validator/recap-validator.component';
import { PaymentRdiInitiatorComponent } from './components/remdoc/payment/payment-rdi-initiator/payment-rdi-initiator.component';
import { PaymentRdiValidatorComponent } from './components/remdoc/payment/payment-rdi-validator/payment-rdi-validator.component';
import { RdiRecapComponent } from './components/remdoc/rdi-recap/rdi-recap.component';
import { ModeleListComponent } from './components/lc/modele/modele-list/modele-list.component';
import { ModeleRecapComponent } from './components/lc/modele/modele-recap/modele-recap.component';
import { PaymentRdiRecapComponent } from './components/remdoc/payment/payment-rdi-recap/payment-rdi-recap.component';
import { HomeComponent } from './core/home/home.component';

import { CommingSoonComponent } from './components/comming-soon/comming-soon.component';
import { CommingSoonLayoutComponent } from './components/comming-soon-layout/comming-soon-layout.component';
import { AppComponent } from './app.component';
import { DefaultLayoutComponent } from './components/default-layout/default-layout.component';
import { ClientAreaComponent } from './core/client-area/client-area.component';
import { TraceEventComponent } from './core/trace-event/trace-event.component';
import { ReassignmentAreaComponent } from './core/reassignment-area/reassignment-area.component';
import { RtListComponent } from './components/received-transfer/rt-list/rt-list.component';
import { RtTreatmentComponent } from './components/received-transfer/rt-treatment/rt-treatment.component';
import { RtValidationComponent } from './components/received-transfer/rt-validation/rt-validation.component';
import { NotifListComponent } from './components/notification/notif-list/notif-list.component';
import { InitIssuedTransferComponent } from './components/issued-transfer/init-issued-transfer/init-issued-transfer.component';
import { IssuedListComponent } from './components/issued-transfer/issued-list/issued-list.component';
import { IssuedValidationComponent } from './components/issued-transfer/issued-validation/issued-validation.component';
import { IssuedProrogListRecapComponent } from './components/issued-transfer/issued-prorog-list-recap/issued-prorog-list-recap.component';
import { IssuedListRecapComponent } from './components/issued-transfer/issued-list-recap/issued-list-recap.component';
import { RedirectComponent } from './core/redirect/redirect.component';

const routes: Routes = [
  {
    path: '',
    component: AppComponent,
    children: [
      {
        path: '',
        component: DefaultLayoutComponent,
        children: [
          { path: 'tasks', component: TasksComponent },
          { path: 'portail', component: HomeComponent },
          { path: 'lc', component: LcComponent },
          { path: 'lc/list', component: ListComponent },
          { path: 'lc/models/list', component: ModeleListComponent },
          { path: 'beneficiary', component: BeneficiaryComponent },
          { path: 'beneficiary/manage', component: ManageComponent },
          { path: 'beneficiary/:id', component: BeneficiaryComponent },
          { path: 'prorogation/form/:id', component: ProrogAreaComponent },
          { path: 'prorogation/recap/:id', component: ProrogAreaComponent },
          { path: 'modele/recap/:id', component: ModeleRecapComponent },

          { path: 'expiration/form/:id', component: ExpirAreaComponent },
          { path: 'expiration/recap/:id', component: ExpirAreaComponent },
          { path: 'remdoc/recap/:id', component: RdiRecapComponent },
          { path: 'remdoc/list', component: RemdocListComponent },
          { path: 'received-transfer/list', component: RtListComponent },
          { path: 'notification/list', component: NotifListComponent },
          {
            path: 'remdoc/initiator/:id',
            component: RemdocRecapInitiatorComponent
          },
          {
            path: 'receivedTransfer/initiator/:id',
            component: RtTreatmentComponent
          },
          {
            path: 'receivedTransfer/validator/:id',
            component: RtValidationComponent
          },
          {
            path: 'remdoc/validator/:id',
            component: RemdocRecapValidatorComponent
          },
          {
            path: 'remdoc/payment/initiator/:remdocId',
            component: PaymentRdiInitiatorComponent
          },
          {
            path: 'remdoc/payment/validator/:remdocId',
            component: PaymentRdiValidatorComponent
          },
          {
            path: 'remdoc/payment/recap/:remdocId/:paymentId',
            component: PaymentRdiRecapComponent
          },
          {
            path: 'params/client',
            component: ClientAreaComponent
          },
          {
            path: 'params/client/events',
            component: TraceEventComponent
          },
          {
            path: 'params/client/reassignment',
            component: ReassignmentAreaComponent
          },
          {
            path: 'issued-transfer/init/new',
            component: InitIssuedTransferComponent
          },
          {
            path: 'issued-transfer/list',
            component: IssuedListComponent
          },
          {
            path: 'issued-transfer/list-recap/issued',
            component: IssuedListRecapComponent
          },
          {
            path: 'issued-transfer/list-recap/ext',
            component: IssuedProrogListRecapComponent
          },
          {
            path: 'issued-transfer/init/edit/:id',
            component: InitIssuedTransferComponent
          },
          {
            path: 'issued-transfer/validate/:id',
            component: IssuedValidationComponent
          },
          { path: '**', component: RedirectComponent },

        ],
        runGuardsAndResolvers: 'always',
      },
      {
        path: '',
        component: CommingSoonLayoutComponent,
        children: [{ path: 'comming-soon', component: CommingSoonComponent }]
      }
    ]
  },

  { path: '**', redirectTo: '' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
