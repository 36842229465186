import { LettreCredit } from './lettre-credit';
import { Negociation } from './negociation';
import { Document } from './document';
import { RefinancingExtension } from './refinancingExtension';
import { RefinancingExpiration } from './refinancingExpiration';

export class PaymentOperation {
  public id: number;
  public paymentType: string;
  public amountPayment: number;
  public refinancing: boolean;
  public amountToRefinanced: number;
  public duration: number;
  public referenceWf: string;
  public CamundaId: string;
  public credocImport: LettreCredit;
  public documentList: Document[] = new Array();
  public negociations: Negociation[] = new Array();
  public taskId: string;
  public status: string;
  public addDate: Date;
  public credocImportId: number;
  public refinancingExtensions: RefinancingExtension[];
  public refinancingExpiration: RefinancingExpiration;
  public isRefinancingExtensible: boolean;
  public isRefinancingExpired: boolean;
  public referenceWfRefinancing: string;
  public remdocImportId: number;
  public accountNumber: string;
  public instructions: string;
  constructor() {}
}
