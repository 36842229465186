import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Subscription } from 'rxjs';
import {
  FormGroup,
  Validators,
  FormArray,
  FormControl,
  FormBuilder
} from '@angular/forms';
import { NegociationTypes, Util } from 'src/app/util';
import { PaymentValidators } from '../lc/payment-operation/validators/validators';
import { Negociation } from 'src/app/models/negociation';

@Component({
  selector: 'app-negociations-form',
  templateUrl: './negociations-form.component.html',
  styleUrls: ['./negociations-form.component.css']
})
export class NegociationsFormComponent implements OnInit, OnDestroy {
  private negociationTypeChangeSubscription: Subscription;
  private negotiatedAmountChangeSubscrition: Subscription;
  datePickerConfig = Util.date_input_config;
  negociations: FormArray;
  @Input() parentForm: FormGroup;
  @Input() model: any;
  @Input() amount: number;
  refinancingForm: FormGroup;
  negocialtionRefinancingForm: FormGroup;
  constructor(private fb: FormBuilder) {}

  ngOnInit() {
    this.negocialtionRefinancingForm = this.fb.group({});
    this.negociations = this.fb.array([], [Validators.required]);
    this.refinancingForm = this.fb.group({
      amountToBeRefinanced: [null],
      duration: [null, [Validators.min(0)]]
    });
    this.negocialtionRefinancingForm.addControl(
      'negociations',
      this.negociations
    );
    this.negocialtionRefinancingForm.addControl(
      'refinancingForm',
      this.refinancingForm
    );
    this.negocialtionRefinancingForm.addControl(
      'refinancing',
      this.fb.control(null, [Validators.required])
    );
    this.refinancing.valueChanges.subscribe(newVal =>
      this.onRefinancingValueChange(newVal)
    );
  }
  mapNegociationsToForm(model: any) {
    if (!this.amount) {
      this.amount = model.amount;
    }
    this.refinancing.setValue(model.refinancing);
    this.refinancingForm.setValue({
      amountToBeRefinanced: model.refinancingAmount,
      duration: model.duration
    });
    if (model.refinancing) {
      this.refinancingForm.get('amountToBeRefinanced').clearValidators();
      this.refinancingForm
        .get('amountToBeRefinanced')
        .setValidators([
          Validators.required,
          PaymentValidators.amountRefinancingValidator(this.amount),
          Util.specialCharValidator(),
          Validators.min(0)
        ]);
    }
    this.negociations.setValue([]);
    if (!model.negociations || model.negociations.length === 0) {
      this.addNegociationControl(this.model);
    } else {
      model.negociations.map((n: Negociation) => {
        this.negociations.push(
          this.createNewColleagueForm(
            model.amount,
            n.id,
            n.negociationType,
            n.indicativeExchangeRate,
            n.negotiatedAmount,
            n.ticketReference,
            n.colleague,
            new Date(n.valueDate),
            model.id
          )
        );
      });
    }
  }

  get refinancing(): FormControl {
    return this.negocialtionRefinancingForm.get('refinancing') as FormControl;
  }

  setNegociationsValidators(
    issuedTransferAmount: number,
    collFrm: FormGroup,
    newVal: string
  ) {
    switch (newVal) {
      case NegociationTypes.FIXING:
        collFrm.get('indicativeExchangeRate').clearValidators();
        collFrm.get('negotiatedAmount').clearValidators();
        collFrm.get('ticketReference').clearValidators();
        collFrm.get('colleague').clearValidators();
        collFrm.get('valueDate').clearValidators();
        collFrm.get('indicativeExchangeRate').setValue(null);
        collFrm.get('negotiatedAmount').setValue(null);
        collFrm.get('ticketReference').setValue(null);
        collFrm.get('colleague').setValue(null);
        collFrm.get('valueDate').setValue(null);
        collFrm.get('indicativeExchangeRate').updateValueAndValidity();
        collFrm.get('negotiatedAmount').updateValueAndValidity();
        collFrm.get('ticketReference').updateValueAndValidity();
        collFrm.get('colleague').updateValueAndValidity();
        collFrm.get('valueDate').updateValueAndValidity();
        break;
      case NegociationTypes.NEGOTIATE_SGMA:
        collFrm
          .get('indicativeExchangeRate')
          .setValidators([
            Validators.required,
            Validators.min(0)
          ]);
        collFrm
          .get('negotiatedAmount')
          .setValidators([
            Validators.required,
            PaymentValidators.amountNegociationsValidator(issuedTransferAmount),
            Util.specialCharValidator(),
            Validators.min(0)
          ]);
        collFrm
          .get('ticketReference')
          .setValidators([Validators.required, Util.specialCharValidator()]);
        collFrm.get('colleague').clearValidators();
        collFrm.get('valueDate').clearValidators();
        collFrm.get('colleague').setValue(null);
        collFrm.get('valueDate').setValue(null);
        collFrm.get('indicativeExchangeRate').updateValueAndValidity();
        collFrm.get('negotiatedAmount').updateValueAndValidity();
        collFrm.get('ticketReference').updateValueAndValidity();
        collFrm.get('colleague').updateValueAndValidity();
        collFrm.get('valueDate').updateValueAndValidity();
        break;
      case NegociationTypes.NEGOTIATE_COLLEAGUE:
        collFrm.get('indicativeExchangeRate').clearValidators();
        collFrm
          .get('indicativeExchangeRate')
          .setValidators([
            Validators.required,
            Validators.min(0)
          ]);
        collFrm
          .get('negotiatedAmount')
          .setValidators([
            Validators.required,
            Util.specialCharValidator(),
            PaymentValidators.amountNegociationsValidator(issuedTransferAmount),
            Validators.min(0)
          ]);
        collFrm
          .get('ticketReference')
          .setValidators([Util.specialCharValidator(), Validators.required]);
        collFrm
          .get('colleague')
          .setValidators([Util.specialCharValidator(), Validators.required]);
        collFrm
          .get('valueDate')
          .setValidators([
            Validators.required,
            Util.specialCharValidator(),
            PaymentValidators.minDate
          ]);
        collFrm.get('indicativeExchangeRate').updateValueAndValidity();
        collFrm.get('negotiatedAmount').updateValueAndValidity();
        collFrm.get('ticketReference').updateValueAndValidity();
        collFrm.get('colleague').updateValueAndValidity();
        collFrm.get('valueDate').updateValueAndValidity();
        break;
    }
  }

  removeNegociation(i: number): void {
    this.negociations.removeAt(i);
  }

  negociationsTrackBy(index: number, _obj: any): any {
    return index;
  }

  isWithColleague(negociation) {
    return (
      negociation.value.negociationType === NegociationTypes.NEGOTIATE_COLLEAGUE
    );
  }

  isWithSGMA(negociation) {
    return (
      negociation.value.negociationType === NegociationTypes.NEGOTIATE_SGMA
    );
  }

  addNegociationControl(model: any) {
    this.negociations.push(
      this.createNewColleagueForm(
        this.amount,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        model.id
      )
    );
  }

  createNewColleagueForm(
    amountPayment,
    id,
    negociationType,
    indicativeExchangeRate,
    negotiatedAmount,
    ticketReference,
    colleague,
    valueDate,
    issuedTransferId
  ): FormGroup {
    const collFrm: FormGroup = this.fb.group({
      negociationType: [negociationType, [Validators.required]],
      id: [id],
      indicativeExchangeRate: [indicativeExchangeRate],
      negotiatedAmount: [negotiatedAmount],
      ticketReference: [ticketReference],
      colleague: [colleague],
      valueDate: [valueDate],
      transferIssuedId: [issuedTransferId]
    });
    if (negociationType != null) {
      this.setNegociationsValidators(amountPayment, collFrm, negociationType);
    }

    this.negociationTypeChangeSubscription = collFrm
      .get('negociationType')
      .valueChanges.subscribe(newValue => {
        this.setNegociationsValidators(this.amount, collFrm, newValue);
      });

    this.negotiatedAmountChangeSubscrition = (collFrm.get(
      'negotiatedAmount'
    ) as FormControl).valueChanges.subscribe(_newVal => {
      const a = (this.parentForm as FormGroup).get('amountToBeRefinanced');
      if (a) {
        a.updateValueAndValidity();
      }
    });
    return collFrm;
  }

  setMinDate(): Date {
    return new Date();
  }

  onRefinancingValueChange(newVal: boolean): void {
    if (newVal) {
      this.setRefinancingFormValidators(this.amount);
    } else {
      this.refinancingForm.get('amountToBeRefinanced').clearValidators();
      this.refinancingForm.get('duration').clearValidators();
      this.refinancingForm.get('amountToBeRefinanced').setValue(null);
      this.refinancingForm.get('duration').setValue(null);
    }
  }
  setRefinancingFormValidators(amount: number) {
    this.refinancingForm
      .get('amountToBeRefinanced')
      .setValidators([
        Validators.required,
        PaymentValidators.amountRefinancingValidator(amount),
        Util.specialCharValidator(),
        Validators.min(0)
      ]);
    this.refinancingForm
      .get('duration')
      .setValidators([
        Validators.required,
        Validators.min(0),
        Util.specialCharValidator()
      ]);
  }
  ngOnDestroy() {
    if (this.negociationTypeChangeSubscription) {
      this.negociationTypeChangeSubscription.unsubscribe();
    }
    if (this.negotiatedAmountChangeSubscrition) {
      this.negotiatedAmountChangeSubscrition.unsubscribe();
    }
  }
}
