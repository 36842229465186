import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { LettreCredit } from 'src/app/models/lettre-credit';
import { CreateLcService } from 'src/app/services/create-lc.service';
import { Util } from 'src/app/util';
import { Subscription } from 'rxjs';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { ClientView } from 'src/app/models/client-view';
import { ClientSharedService } from 'src/app/services/client-shared-service';

@Component({
  selector: 'app-create-transport-modalities',
  templateUrl: './create-transport-modalities.component.html',
  styleUrls: ['./create-transport-modalities.component.css']
})
export class CreateTransportModalitiesComponent implements OnInit, OnDestroy {
  @Input() public model: LettreCredit;
  public incoterms: string[];
  public client: ClientView;
  datePickerConfig = Util.date_input_config;
  private getIncotermsSubscription: Subscription;
  OTHER = 'Autre';
  constructor(
    private createLCService: CreateLcService,
    private localeService: BsLocaleService, 
    private clientSharedService: ClientSharedService
  ) {}
  ngOnInit() {
    this.client = this.clientSharedService.getClient();
    this.localeService.use('fr');
    this.getIncotermsSubscription = this.createLCService
      .getIncoterms(this.client.id)
      .subscribe(
        incoterms => {
          this.incoterms = incoterms;
        },
        err => {
          this.incoterms = [
            'EXW',
            'FAS',
            'FOB',
            'FCA',
            'CIF',
            'CIP',
            'CFR',
            'Autre'
          ];
        }
      );
  }
  ngOnDestroy(): void {
    if (this.getIncotermsSubscription) {
      this.getIncotermsSubscription.unsubscribe();
    }
  }
  setMaxDate(): Date {
    const mxdt: any = this.model.validityDate;
    return mxdt instanceof Date ? mxdt : new Date(this.model.validityDate);
  }
  setMinDate(): Date {
    return new Date();
  }
  onExpeditionDeadlineChange(date, target) {
    const maxDate: Date = this.setMaxDate();
    const minDate: Date = this.setMinDate();
    if (
      date instanceof Date &&
      !isNaN(date.getDate()) &&
      !isNaN(minDate.getDate()) &&
      !isNaN(maxDate.getDate()) &&
      date >= minDate &&
      maxDate >= minDate &&
      maxDate >= date
    ) {
      const dt: any = date;
      this.model.expeditionLastDate = dt;
    } else {
      this.model.expeditionLastDate = null;
      target['value'] = null;
    }
  }
}
