import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-validation-buttons',
  templateUrl: './validation-buttons.component.html',
  styleUrls: ['./validation-buttons.component.css']
})
export class ValidationButtonsComponent implements OnInit {
  @Input() validatorCilcked;
  constructor() {}

  ngOnInit() {}
}
