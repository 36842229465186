import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { IdentityChallenge } from '../models/identityChallenge';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class IdentityService {
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };
  
  constructor(private http: HttpClient) {}

  getChallenge(): Observable<IdentityChallenge> {
    return this.http.get<IdentityChallenge>(`${environment.url}/identity/challenge`);
  }
}
