import { Component, OnInit } from '@angular/core';
import { Client } from 'src/app/models/client';
import { ClientView } from 'src/app/models/client-view';
import { ClientSharedService } from 'src/app/services/client-shared-service';
import { ClientService } from 'src/app/services/client.service';

@Component({
  selector: 'app-client-area',
  templateUrl: './client-area.component.html',
  styleUrls: ['./client-area.component.css']
})
export class ClientAreaComponent implements OnInit {
  client: Client;
  public clientView: ClientView;

  constructor(
    private clientService: ClientService,
    private clientSharedService: ClientSharedService
  ) {}

  ngOnInit() {
    this.clientView = this.clientSharedService.getClient();
    this.clientService.getClient(this.clientView.id).subscribe(client => {
      this.client = client;
    });
  }
}
