import { Component, OnInit, Input } from '@angular/core';
import { LettreCredit } from 'src/app/models/lettre-credit';

@Component({
  selector: 'app-payment-instructions',
  templateUrl: './payment-instructions.component.html',
  styleUrls: ['./payment-instructions.component.css']
})
export class PaymentInstructionsComponent implements OnInit {
  @Input() edited: boolean;
  @Input() isEditedVesion: boolean;
  @Input() model: LettreCredit;
  OUR_CHARGE = 'OUR_CHARGE';
  BENEFICIARY_CHARGES = 'BENEFICIARY_CHARGES';
  SHARED_CHARGES = 'SHARED_CHARGES';
  SINGLE_PAYMENT = 'SINGLE_PAYMENT';
  MIXED_PAYMENT = 'MIXED_PAYMENT';
  DATE_OF_INVOICE = 'DATE_OF_INVOICE';
  EXPEDITION_DATE = 'EXPEDITION_DATE';
  OTHER = 'OTHER';
  constructor() {}

  ngOnInit() {}
}
