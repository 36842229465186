import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Beneficiary } from 'src/app/models/beneficiary';
import { BeneficiaryService } from 'src/app/services/beneficiary.service';
import { Subscription } from 'rxjs';
import { DialogSignComponent } from 'src/app/core/dialog-sign/dialog-sign.component';
import { NavigationEnd, Router } from '@angular/router';
import { ClientView } from 'src/app/models/client-view';
import { ClientSharedService } from 'src/app/services/client-shared-service';
@Component({
  selector: 'app-manage',
  templateUrl: './manage.component.html',
  styleUrls: ['./manage.component.css']
})
export class ManageComponent implements OnInit, OnDestroy {
  public list_benef: Array<Beneficiary> = new Array<Beneficiary>();
  public indicatorBebef = new Array<boolean>();
  public SHOWSIGNDIALOG = false;
  public client: ClientView;
  VALIDATE = 'VALIDATE';
  ON_HOLD = 'ON_HOLD';
  benef_path = '/beneficiary/';
  DELETE = 'DELETE';
  ADD = 'ADD';
  UPDATE = 'UPDATE';
  REJECTED = 'REJECTED';
  signMessage =
    'Vous êtes sur le point de signer cette opération. Entrer la code de signature.';
  selectedBenef: Beneficiary;
  getBenefsSubscription: Subscription;
  navigationSubscription: Subscription;
  @ViewChild('app_dialog_benef_sign')
  appDialogBenefSign: DialogSignComponent;
  public signingModel: {
    challengeKey: string;
    dpResponse: string;
  };
  constructor(
    private beneficiaryService: BeneficiaryService, 
    private router: Router,
    private clientSharedService: ClientSharedService) {
    this.navigationSubscription = this.router.events.subscribe((e: any) => {
        if (e instanceof NavigationEnd) {
          this.ngOnInit();
        }
      });
    }

  ngOnInit() {
    this.client = this.clientSharedService.getClient();
    this.signingModel = { dpResponse: null, challengeKey: null };
    this.getAll();
  }

  getAll() {
    this.getBenefsSubscription = this.beneficiaryService.getAll(this.client.id).subscribe(
      list_b => {
        this.list_benef = list_b;
        this.indicatorBebef = this.list_benef.map(benef => false);
      },
      err => {

      }
    );
  }

  hasTosign(benef: Beneficiary): boolean {
    return ['ADD', 'UPDATE'].includes(benef.status);
  }

  validate(benef: Beneficiary) {
    this.selectedBenef = benef;
    if (this.hasTosign(benef)) {
      this.SHOWSIGNDIALOG = true;
    } else {
      this.validateBenef();
    }
  }

  validateBenef() {
    if (this.selectedBenef) {
      this.beneficiaryService
        .validate(
          Object.assign({}, this.selectedBenef),
          this.signingModel.dpResponse,
          this.signingModel.challengeKey,
          this.client.id
        )
        .subscribe(
          id_benef => {
            this.selectedBenef = null;
            this.SHOWSIGNDIALOG = false;
            this.getAll();
          },
          err => {
            this.appDialogBenefSign.onRefresh(null);
          }
        );
    }
  }

  reject(index_benef: number) {
    this.beneficiaryService.reject(this.list_benef[index_benef], this.client.id).subscribe(
      id_benef => {
        this.getAll();
      },
      err => {}
    );
  }

  remove(idBeneficiary: number) {
    this.beneficiaryService.remove(idBeneficiary, this.client.id).subscribe(
      res => {
        this.getAll();
      },
      err => {}
    );
  }

  ngOnDestroy(): void {
    if (this.getBenefsSubscription) {
      this.getBenefsSubscription.unsubscribe();
    }
    if (this.navigationSubscription) {
      this.navigationSubscription.unsubscribe();
    }
  }
}
