import { User } from 'src/app/models/user';
import { Injectable } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';
import { from, Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  private hasRole = false;
  private roles = [];
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };
  constructor(
    private keycloakService: KeycloakService,
    private http: HttpClient
  ) {}

  public getUsers(clientId: number): Observable<Array<any>> {
    return this.http.get<Array<any>>(
      `${environment.url}/user/${clientId}`,
      this.httpOptions
    );
  }

  public getUser(username: string, clientId: number): Observable<any> {
    return this.http.get<any>(`${environment.url}/user/client/${clientId}`, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      params: { usernameSearched: username }
    });
  }


  public hasAnyRoles(roles: string[]): boolean {
    this.getUserRoles().subscribe(rols => {
      this.hasRole = roles.reduce((acc: boolean, curr: string) => {
        return (acc =
          acc || rols.includes(curr));
      }, false);
    });
    return this.hasRole;
  }

  public userHasAnyRoles(roles: string[]): Promise<boolean> {
    return this.getUserRoles().toPromise().then(rols => {
      return roles.reduce((acc: boolean, curr: string) => {
        return (acc =
          acc || rols.includes(curr));
      }, false);
    });
  }

  public getUserRoles():  Observable<string[]> {
    const roles = localStorage.getItem('roles');
    if (roles !== null ) {
       return of(JSON.parse(roles));
    }
  }

  public initUserCredentialsInLocalStorage(clientId: number):  Observable<string[]> {
      return this.getUserName().pipe(
        switchMap(username => this.getUser(username, clientId)),
        map((user: User) => {
          user.keycloakRoles.forEach( r => {
            this.roles.push(r);
          });
          localStorage.setItem('roles', JSON.stringify(this.roles));
          return this.roles;
        }));
  }


  public getFullName():  Observable<string> {
    const fullName = localStorage.getItem('fullName');
    if (fullName !== null ) {
       return of(JSON.parse(fullName));
    }
  }

  public setFullName(clientId: number):  Observable<string> {

      return this.getUserName().pipe(
        switchMap(username => this.getUser(username, clientId)),
        map((user: User) => {
          const fullName = user.firstname + ' '  + user.lastname;
          localStorage.setItem('fullName', JSON.stringify(fullName));
          return fullName;
        }));
  }
  /*public getFullName(): Observable<string> {
    return from(this.keycloakService.loadUserProfile()).pipe(
      map(userProfile => userProfile.firstName + ' ' + userProfile.lastName)
    );
  }*/

  public getUserName(): Observable<string> {
    return from(this.keycloakService.loadUserProfile()).pipe(
      map(userProfile => userProfile.username)
    );
  }

  public logout(): void {
    localStorage.clear();
    this.keycloakService.logout();
  }
}
