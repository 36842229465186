import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Trace } from '../models/trace';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TraceService {
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };

  constructor(private http: HttpClient) {}

  getAllTrace(currentPage: number, clientId: number): Observable<Array<Trace>> {
    return this.http.post<Array<Trace>>(
      `${environment.url}/traces/page/${currentPage}/client/${clientId}`,
      {},
      this.httpOptions
    );
  }

  getTraceByFilter(
    currentPage: number,
    filter: Trace,
    clientId: number
  ): Observable<Array<Trace>> {
    return this.http.post<Array<Trace>>(
      `${environment.url}/traces/page/${currentPage}/client/${clientId}`,
      filter,
      this.httpOptions
    );
  }
}
