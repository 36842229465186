import { Component, OnInit, Input } from '@angular/core';
import { Remdoc } from 'src/app/models/remdoc';
import { Util } from 'src/app/util';
import { RdiTypes } from 'src/app/models/rditype';

@Component({
  selector: 'app-remdoc-common-recap',
  templateUrl: './recap.component.html',
  styleUrls: ['./recap.component.css']
})
export class RemdocCommonRecapComponent implements OnInit {
  public type: string;
  @Input() remdoc: Remdoc;
  @Input() displaySentence: boolean;
  constructor() {}
  ngOnInit() {
    this.type = 'initiator';
  }
  getStatus(status: string) {
    return Util.setStatus('recap', status).text;
  }
  setRdiType(): string {
    return Util.setRdiType(RdiTypes[this.remdoc.type]);
  }
}
