export enum RdiTypes {
  AT_SIGHT,
  BY_ACCEPTANCE_BILL_CUSTOMER,
  BY_ACCEPTANCE_BILL_VENDOR,
  BY_AVAL_BILL_CUSTOMER,
  BY_AVAL_BILL_VENDOR,
  BY_ACCEPTANCE,
  BY_ACCEPTANCE_DOWNSTRAM

}
