import { Component, OnInit, Input } from '@angular/core';
import { Util } from 'src/app/util';
import { RefinancingExpiration } from 'src/app/models/refinancingExpiration';
import { ExpirService } from 'src/app/services/expir.service';
import { ClientView } from 'src/app/models/client-view';
import { ClientSharedService } from 'src/app/services/client-shared-service';

@Component({
  selector: 'app-expir-inner-recap',
  templateUrl: './expir-inner-recap.component.html',
  styleUrls: ['./expir-inner-recap.component.css']
})
export class ExpirInnerRecapComponent implements OnInit {
  @Input() expiration: RefinancingExpiration;
  @Input() currency: string;
  @Input() id: number;
  public client: ClientView;
  constructor(
    private expirService: ExpirService,
    private clientSharedService: ClientSharedService) {}

  ngOnInit() {
    this.client = this.clientSharedService.getClient();
    if (this.id && !this.expiration) {
      this.expirService
        .getExpiration(this.id, this.client.id)
        .subscribe((expiration: RefinancingExpiration) => {
          this.expiration = expiration;
        });
    }
  }

  setRegulationStatus(): string {
    return Util.setStatus('recap', this.expiration.status).text;
  }

  getNegociationTypeText(type): string {
    return Util.getNegociationTypeText(type);
  }
}
