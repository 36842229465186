import { Component, OnInit } from '@angular/core';
import { ClientView } from 'src/app/models/client-view';
import { ClientSharedService } from 'src/app/services/client-shared-service';
import { GroupService } from 'src/app/services/group.service';

@Component({
  selector: 'app-list-group',
  templateUrl: './list-group.component.html',
  styleUrls: ['./list-group.component.css']
})
export class ListGroupComponent implements OnInit {
  constructor(
    private groupService: GroupService,
    private clientSharedService: ClientSharedService
    ) {}
  public groupList: Array<Group> = [];
  public client: ClientView;

  ngOnInit() {
    this.client = this.clientSharedService.getClient();
    this.groupService
      .getGroups(this.client.id)
      .subscribe(listGroup => (this.groupList = listGroup));
  }
}
