import { Component, OnInit, Input } from '@angular/core';
import { ReceivedTransfer } from 'src/app/models/receivedTransfer';

@Component({
  selector: 'app-rt-recap',
  templateUrl: './rt-recap.component.html',
  styleUrls: ['./rt-recap.component.css']
})
export class RtRecapComponent implements OnInit {
  @Input() receivedTransfer: ReceivedTransfer;
  constructor() {}
  ngOnInit() {}
}
