import { Component, OnInit, Input } from '@angular/core';
import { Remdoc } from 'src/app/models/remdoc';

@Component({
  selector: 'app-inner-rdi-recap',
  templateUrl: './inner-rdi-recap.component.html',
  styleUrls: ['./inner-rdi-recap.component.css']
})
export class InnerRdiRecapComponent implements OnInit {
  @Input() remdoc: Remdoc;
  constructor() {}
  ngOnInit() {}
}
