import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Task } from '../models/task';
import { environment } from 'src/environments/environment';


const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json'
  })
};

@Injectable({
  providedIn: 'root'
})
export class TasksService {

  constructor(private http: HttpClient) { }

  getTaskByRequestId(requestId: string, clientId: number): Observable<Task> {
    return this.http.get<Task>(
      `${environment.url}/tasks/${requestId}/client/${clientId}`,
      httpOptions
    );
  }
}
