import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { LastComment } from 'src/app/models/lastComment';
import { ReceivedTransfer } from 'src/app/models/receivedTransfer';
import { AlertComponent } from 'src/app/core/alert/alert.component';
import { ReceivedTransferService } from 'src/app/services/received-transfer.service';
import {
  FormBuilder,
  FormArray,
  Validators,
  FormGroup,
  FormControl,
  AbstractControl,
  ValidationErrors
} from '@angular/forms';
import { Subscription } from 'rxjs';
import { NegociationTypes, Status, Util } from 'src/app/util';
import { PaymentValidators } from '../../lc/payment-operation/validators/validators';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { ActivatedRoute, Router } from '@angular/router';
import { CreateLcService } from 'src/app/services/create-lc.service';
import { ExpirValidators } from '../../lc/refinancing-expiration/expir-form/validators';
import { Negociation } from 'src/app/models/negociation';
import { HomeService } from 'src/app/services/home.service';
import { DataService } from 'src/app/services/data.service';
import * as FileSaver from 'file-saver';
import { ClientView } from 'src/app/models/client-view';
import { ClientSharedService } from 'src/app/services/client-shared-service';

@Component({
  selector: 'app-rt-treatment',
  templateUrl: './rt-treatment.component.html',
  styleUrls: ['./rt-treatment.component.css']
})
export class RtTreatmentComponent implements OnInit, OnDestroy {
  taskChanged = false;
  lastComment: LastComment;
  @ViewChild('instructions_alert')
  instructionsAlert: AlertComponent;
  receivedTransfer: ReceivedTransfer;
  private negociationTypeSubscription: Subscription;
  private dataSubscription: Subscription;
  datePickerConfig = Util.date_input_config;
  public client: ClientView;
  accounts: Array<string> = [];
  submitInstructionsMessage =
    'Vous êtes sur le point de soumettre ces instructions pour signature.';
  saveRtMessage = 'Le virement reçu a bien été enregistré.';
  instructions: FormArray;
  confirmationShown = false;
  negociations: FormArray;
  getAccountsSubscription: Subscription;
  constructor(
    private dataService: DataService,
    private rtService: ReceivedTransferService,
    private fb: FormBuilder,
    private homeService: HomeService,
    private localeService: BsLocaleService,
    private activatedRoute: ActivatedRoute,
    private createLcService: CreateLcService,
    private router: Router,
    private clientSharedService: ClientSharedService
  ) {}

  ngOnInit() {
    this.client = this.clientSharedService.getClient();
    this.getAccounts();
    this.localeService.use('fr');
    this.getRt(this.activatedRoute.snapshot.params['id']);
    this.dataSubscription = this.dataService.currentData.subscribe(data => {
      this.lastComment = data.lastComment;
    });
  }

  unlockTask() {
    this.homeService.unlockTask(this.receivedTransfer.camundaId).subscribe();
  }

  ngOnDestroy(): void {
    if (
      this.receivedTransfer &&
      !this.taskChanged &&
      this.receivedTransfer.status === Status.TO_TREAT
    ) {
      this.unlockTask();
    }
    if (this.negociationTypeSubscription) {
      this.negociationTypeSubscription.unsubscribe();
    }
    if (this.dataSubscription) {
      this.dataSubscription.unsubscribe();
    }
    if (this.getAccountsSubscription) {
      this.getAccountsSubscription.unsubscribe();
    }
  }

  getRt(id: number) {
    this.rtService.getRt(id, this.client.id).subscribe(rt => {
      this.receivedTransfer = rt;
      this.mapRtToForm(rt);
    });
  }
  createForms() {
    this.instructions = this.fb.array([], [this.uniqueAccount]);
    this.negociations = this.fb.array([]);
  }
  instructionsTrackBy(index: number, obj: any): any {
    return index;
  }

  negociationsTrackBy(index: number, obj: any): any {
    return index;
  }

  removeInstruction(i: number): void {
    this.instructions.removeAt(i);
  }

  removeNegociation(i: number): void {
    this.negociations.removeAt(i);
  }

  mapFormToRt() {
    this.receivedTransfer.negociations = this.negociations.value;
    this.receivedTransfer.accounts = this.instructions.value;
  }

  submitInstructions() {
    if (this.instructions.valid && this.negociations.valid) {
      this.taskChanged = true;
      this.mapFormToRt();
      this.rtService.submitRt(this.receivedTransfer, this.client.id).subscribe(
        _res => this.rtCallBack(this.submitInstructionsMessage, true),
        err => {
        }
      );
    }
  }

  saveInstructions() {
    this.taskChanged = true;
    this.mapFormToRt();
    this.rtService.saveRt(this.receivedTransfer, this.client.id).subscribe(
      _res => {
        this.rtCallBack(this.saveRtMessage, false);
        this.getRt(this.receivedTransfer.id);
      },
      err => {

      }
    );
  }

  printRt() {
    this.rtService.printReport(this.receivedTransfer.id, this.client.id).subscribe(data => {
      const fileName: string = data.headers.get('FileName');
      FileSaver.saveAs(data.body, fileName);
    });
  }

  getFilteredAccounts() {
    return this.accounts.filter(
      account =>
        this.instructions.value
          .map(instruction => instruction.account)
          .indexOf(account) < 0
    );
  }

  rtCallBack(message: string, isSubmitting: boolean) {
    this.instructionsAlert.snackbarData = {
      reference: this.receivedTransfer.requestId,
      message,
      display: { isDisplay: true }
    };
    setTimeout(() => {
      this.instructionsAlert.snackbarData.display.isDisplay = false;
      if (isSubmitting) {
        this.cancel();
      }
    }, 2000);
  }

  cancel() {
    return this.router.navigate(['/tasks']);
  }

  getAccounts() {
    this.getAccountsSubscription = this.createLcService
      .getTransferReceivedAccounts(this.client.id)
      .subscribe(
        accounts => {
          this.accounts = accounts;
        },
        _err => {}
      );
  }

  submitsaveInstructions() {}

  addNegociationControl() {
    const receivedTransferId = this.receivedTransfer
      ? this.receivedTransfer.id
      : null;
    this.negociations.push(
      this.createNewColleagueForm(
        this.receivedTransfer.amount,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        receivedTransferId
      )
    );
  }

  createNewColleagueForm(
    amountPayment,
    id,
    negociationType,
    indicativeExchangeRate,
    negotiatedAmount,
    ticketReference,
    colleague,
    valueDate,
    paymentId
  ): FormGroup {
    const collFrm: FormGroup = this.fb.group({
      negociationType: [negociationType, [Validators.required]],
      id: [id],
      indicativeExchangeRate: [
        indicativeExchangeRate,
        [Util.specialCharValidator()]
      ],
      negotiatedAmount: [negotiatedAmount, [Util.specialCharValidator()]],
      ticketReference: [ticketReference, [Util.specialCharValidator()]],
      colleague: [colleague, [Util.specialCharValidator()]],
      valueDate: [valueDate, [Util.specialCharValidator()]],
      paymentId: [paymentId, Validators.required]
    });
    if (negociationType != null) {
      this.setNegociationsValidators(amountPayment, collFrm, negociationType);
    }
    this.negociationTypeSubscription = (collFrm.get(
      'negociationType'
    ) as FormControl).valueChanges.subscribe(newVal => {
      this.setNegociationsValidators(amountPayment, collFrm, newVal);
    });
    return collFrm;
  }
  setNegociationsValidators(
    amountPayment: number,
    collFrm: FormGroup,
    newVal: string
  ) {
    switch (newVal) {
      case NegociationTypes.FIXING:
        collFrm.get('indicativeExchangeRate').clearValidators();
        collFrm.get('negotiatedAmount').clearValidators();
        collFrm.get('ticketReference').clearValidators();
        collFrm.get('colleague').clearValidators();
        collFrm.get('valueDate').clearValidators();
        collFrm.get('indicativeExchangeRate').setValue(null);
        collFrm.get('negotiatedAmount').setValue(null);
        collFrm.get('ticketReference').setValue(null);
        collFrm.get('colleague').setValue(null);
        collFrm.get('valueDate').setValue(null);
        collFrm.get('indicativeExchangeRate').updateValueAndValidity();
        collFrm.get('negotiatedAmount').updateValueAndValidity();
        collFrm.get('ticketReference').updateValueAndValidity();
        collFrm.get('colleague').updateValueAndValidity();
        collFrm.get('valueDate').updateValueAndValidity();
        break;
      case NegociationTypes.NEGOTIATE_SGMA:
        collFrm
          .get('indicativeExchangeRate')
          .setValidators([
            Validators.required,
            Validators.min(0)
          ]);
        collFrm
          .get('negotiatedAmount')
          .setValidators([
            Validators.required,
            ExpirValidators.amountNegociationsValidator(amountPayment),
            Util.specialCharValidator(),
            Validators.min(0)
          ]);
        collFrm
          .get('ticketReference')
          .setValidators([Validators.required, Util.specialCharValidator()]);
        collFrm.get('colleague').clearValidators();
        collFrm.get('valueDate').clearValidators();
        collFrm.get('colleague').setValue(null);
        collFrm.get('valueDate').setValue(null);
        collFrm.get('indicativeExchangeRate').updateValueAndValidity();
        collFrm.get('negotiatedAmount').updateValueAndValidity();
        collFrm.get('ticketReference').updateValueAndValidity();
        collFrm.get('colleague').updateValueAndValidity();
        collFrm.get('valueDate').updateValueAndValidity();
        break;
      case NegociationTypes.NEGOTIATE_COLLEAGUE:
        collFrm.get('indicativeExchangeRate').clearValidators();
        collFrm
          .get('indicativeExchangeRate')
          .setValidators([
            Validators.required,
            Validators.min(0)
          ]);
        collFrm
          .get('negotiatedAmount')
          .setValidators([
            Validators.required,
            ExpirValidators.amountNegociationsValidator(amountPayment),
            Util.specialCharValidator(),
            Validators.min(0)
          ]);
        collFrm
          .get('ticketReference')
          .setValidators([Validators.required, Util.specialCharValidator()]);
        collFrm
          .get('colleague')
          .setValidators([Validators.required, Util.specialCharValidator()]);
        collFrm
          .get('valueDate')
          .setValidators([
            Validators.required,
            PaymentValidators.minDate,
            Util.specialCharValidator()
          ]);
        collFrm.get('indicativeExchangeRate').updateValueAndValidity();
        collFrm.get('negotiatedAmount').updateValueAndValidity();
        collFrm.get('ticketReference').updateValueAndValidity();
        collFrm.get('colleague').updateValueAndValidity();
        collFrm.get('valueDate').updateValueAndValidity();
        break;
    }
  }

  uniqueAccount(control: AbstractControl): ValidationErrors | null {
    if (control.value == null || control.value === undefined) {
      return null;
    }
    if (!control.value.length) {
      return null;
    }
    const accounts = control.value.map(inst => inst.account);
    if (new Set(accounts).size !== accounts.length) {
      return {
        invalidValue: true
      };
    }
    return null;
  }

  maxPercentage(control: AbstractControl): ValidationErrors | null {
    if (control.value == null || control.value === undefined) {
      return null;
    }
    if (
      !control.parent ||
      !control.parent.parent ||
      !control.parent.parent.value ||
      !control.parent.parent.value.length
    ) {
      return null;
    }
    const totalPercentage = control.parent.parent.value.reduce(
      (acc, curr) => (acc += curr.rate),
      0
    );
    if (totalPercentage > 70) {
      return {
        maxPercentage: true
      };
    }
    return null;
  }

  setMinDate(): Date {
    return new Date();
  }

  isWithColleague(negociation) {
    return (
      negociation.value.negociationType === NegociationTypes.NEGOTIATE_COLLEAGUE
    );
  }

  isWithSGMA(negociation) {
    return (
      negociation.value.negociationType === NegociationTypes.NEGOTIATE_SGMA
    );
  }
  addInstruction(
    accountNUmber: string,
    rateValue: number,
    instructionId: number
  ) {
    const account = this.fb.control(accountNUmber, [Validators.required]);
    const rate = this.fb.control(rateValue, [
      Validators.required,
      this.maxPercentage,
      Util.specialCharValidator()
    ]);
    const instruction = this.fb.group({
      id: [instructionId],
      account,
      rate
    });
    this.instructions.push(instruction);
    this.instructions.updateValueAndValidity();
  }
  isFormInvalid(): boolean {
    return (
      !this.instructions ||
      !this.negociations ||
      !this.instructions.valid ||
      !this.negociations.valid
    );
  }
  mapRtToForm(rt: ReceivedTransfer) {
    this.createForms();
    rt.accounts.forEach(account => {
      this.addInstruction(account.account, account.rate, account.id);
    });
    if (!rt.negociations || rt.negociations.length === 0) {
      this.addNegociationControl();
    } else {
      rt.negociations.map((n: Negociation) => {
        this.negociations.push(
          this.createNewColleagueForm(
            rt.amount,
            n.id,
            n.negociationType,
            n.indicativeExchangeRate,
            n.negotiatedAmount,
            n.ticketReference,
            n.colleague,
            new Date(n.valueDate),
            rt.id
          )
        );
      });
    }
  }
}
