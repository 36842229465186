import { Component, OnInit, OnDestroy } from '@angular/core';
import { ErrorService } from '../error.service';
import { AppError } from '../types/AppError';
import { map, delay, mergeMap, mapTo } from 'rxjs/operators';
import { of, iif, Subscription } from 'rxjs';

@Component({
  selector: 'app-notification-error',
  templateUrl: './notification-error.component.html',
  styleUrls: ['./notification-error.component.css']
})
export class NotificationErrorComponent implements OnInit, OnDestroy {
  private static ALERT_DELAY = 5000;
  public displayAlert = false;
  error: AppError;
  private errorNotifierSubscription: Subscription;
  constructor(private errorService: ErrorService) {}

  ngOnInit() {
    this.errorNotifierSubscription = this.errorService.errorNotifer
      .pipe(
        map((error: AppError) => {
          if (error !== null) {
            this.error = error;
            this.displayAlert = true;
          }
          return this.displayAlert;
        })
      )
      .pipe(
        mergeMap(display =>
          iif(
            () => display,
            of(this.displayAlert).pipe(
              mapTo(!display),
              delay(NotificationErrorComponent.ALERT_DELAY)
            ),
            of(display)
          )
        )
      )
      .subscribe(emitted => (this.displayAlert = emitted));
  }

  close() {
    this.displayAlert = false;
  }

  ngOnDestroy() {
    if (this.errorNotifierSubscription) {
      this.errorNotifierSubscription.unsubscribe();
    }
  }
}
