import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { RemdocService } from 'src/app/services/remdoc.service';
import { DataService } from 'src/app/services/data.service';
import { Remdoc } from 'src/app/models/remdoc';
import { Subscription } from 'rxjs';
import { PaymentOperation } from 'src/app/models/paymentOperation';
import { CreateLcService } from 'src/app/services/create-lc.service';
import { AlertComponent } from 'src/app/core/alert/alert.component';
import { DialogSignComponent } from 'src/app/core/dialog-sign/dialog-sign.component';
import { ClientView } from 'src/app/models/client-view';
import { ClientSharedService } from 'src/app/services/client-shared-service';

@Component({
  selector: 'app-payment-rdi-validator',
  templateUrl: './payment-rdi-validator.component.html',
  styleUrls: ['./payment-rdi-validator.component.css']
})
export class PaymentRdiValidatorComponent implements OnInit, OnDestroy {
  public id: number;
  public remdoc: Remdoc;
  private dataServiceSubscription: Subscription;
  private getRemdocSubscription: Subscription;
  private getPaymentSubscription: Subscription;
  signVariables: { dpResponse: string; challengeKey: string };
  public payment: PaymentOperation;
  public client: ClientView
  confirmRejectMessage =
    'Vous êtes sur le point de rejecter provisoirement ces instructions de paiement. Êtes-vous sûr de vouloir continuer ?';
  validateMessage =
    'Vous êtes sur le point de valider cette opération. Êtes-vous sûr de vouloir continuer ?';
  tempRejectMessage =
    'Veuillez saisir ci-dessous votre motif pour valider votre choix.';
  validatePaymentMessage =
    'Quelle est votre décision concernant ces instructions de paiements ?';
  signMessage =
    'Vous êtes sur le point de signer cette opération. Entrer la code de signature.';

  validatorCilcked: { button: string };
  @ViewChild('rdi_payment_validator_alert')
  rdiPaymentValidatorAlert: AlertComponent;
  @ViewChild('app_dialog_payment_sign')
  appDialogPaymentSign: DialogSignComponent;
  constructor(
    private route: ActivatedRoute,
    private remdocService: RemdocService,
    private dataService: DataService,
    private createLcService: CreateLcService,
    private router: Router,
    private clientSharedService: ClientSharedService
  ) {}

  ngOnInit() {
    this.client = this.clientSharedService.getClient();
    this.signVariables = { challengeKey: null, dpResponse: null };
    this.validatorCilcked = { button: '' };
    const remdocId = this.route.snapshot.params['remdocId'];
    this.getRemdoc(remdocId);
    this.dataServiceSubscription = this.dataService.currentData.subscribe(
      data => {
        this.getRdiPayment(+data.paymentId);
      }
    );
  }

  getRemdoc(remdocId: number) {
    this.getRemdocSubscription = this.remdocService
      .getRemdoc(remdocId, this.client.id)
      .subscribe(res => {
        this.remdoc = res;
      });
  }

  getRdiPayment(rdiPaymentId: number) {
    this.getPaymentSubscription = this.createLcService
      .getPayment(rdiPaymentId, this.client.id)
      .subscribe(payment => {
        this.payment = payment;
      });
  }

  cancelValidation() {
    this.validatorCilcked.button = '';
  }

  onValidatePayment(action) {
    if (action === 'VALIDATE') {
      this.createLcService
        .validatePayment(
          this.payment,
          this.signVariables.dpResponse,
          this.signVariables.challengeKey,
          this.client.id
        )
        .subscribe(
          () => {
            this.rdiPaymentValidatorAlert.snackbarData.display = {
              isDisplay: true
            };
            this.rdiPaymentValidatorAlert.snackbarData.message =
              'Le réglement a bien été signé.';
            this.rdiPaymentValidatorAlert.snackbarData.reference = this.remdoc.requestId;
            setTimeout(() => {
              this.rdiPaymentValidatorAlert.snackbarData.display = {
                isDisplay: false
              };
              this.returnToTasks();
            }, 2000);
          },
          error => this.appDialogPaymentSign.onRefresh(null)
        );
    } else if (action === 'REJECT') {
      this.createLcService
        .rejectPayment(this.payment, this.client.id)
        .subscribe(() => this.returnToTasks());
    }
  }

  returnToTasks() {
    this.router.navigate(['/tasks']);
  }

  ngOnDestroy(): void {
    this.dataService.changeData({
      product: null,
      summary: false,
      paymentId: null,
      taskId: null,
      lcId: null,
      taskDefinitionKey: null,
      lastComment: { fullMessage: null, time: null, userId: null },
      action: null,
      decision: null
    });
    if (this.dataServiceSubscription) {
      this.dataServiceSubscription.unsubscribe();
    }
    if (this.getRemdocSubscription) {
      this.getRemdocSubscription.unsubscribe();
    }
    if (this.getPaymentSubscription) {
      this.getPaymentSubscription.unsubscribe();
    }
  }
}
