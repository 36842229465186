import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';


@Component({
  selector: 'app-alert-warning',
  templateUrl: './alert-warning.component.html',
  styleUrls: ['./alert-warning.component.css']
})
export class AlertWarningComponent implements OnInit {

  @Input()
  public display;

  @Input()
  public message: string;

  constructor() { }

  close() {
    this.display.isDisplay = false;
  }

  ngOnInit() {
  }

}

