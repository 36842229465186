import {
  Component,
  OnInit,
  OnDestroy,
  EventEmitter,
  Output,
  Input
} from '@angular/core';
import { IssuedTransfer } from 'src/app/models/issuedTransfer';
import {
  FormBuilder,
  Validators,
  FormGroup,
  FormArray,
  FormControl
} from '@angular/forms';
import { PaymentValidators } from '../../lc/payment-operation/validators/validators';
import { Util, Products } from 'src/app/util';
import { Subscription, BehaviorSubject } from 'rxjs';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { CreateLcService } from 'src/app/services/create-lc.service';
import { IssuedValidators } from './issued-validators';
import { ClientView } from 'src/app/models/client-view';
import { ClientSharedService } from 'src/app/services/client-shared-service';

@Component({
  selector: 'app-issued-transfer-form',
  templateUrl: './issued-transfer-form.component.html',
  styleUrls: ['./issued-transfer-form.component.css']
})
export class IssuedTransferFormComponent implements OnInit, OnDestroy {
  issuedTransfer: IssuedTransfer;
  private negociationTypeChangeSubscription: Subscription;
  charges = Util.charges;
  witholdings: { code: string; label: string }[] = Util.witholdings;
  private negotiatedAmountChangeSubscrition: Subscription;
  datePickerConfig = Util.date_input_config;
  exchangeValue: number;
  @Output() amountChange = new EventEmitter<number>();
  public exchangeValueSetter: BehaviorSubject<number> = new BehaviorSubject<
    number
  >(null);
  private amountSubscription: Subscription;
  currencies: string[];
  public amountMAD: number;
  public client: ClientView;
  issuedTransferForm: FormGroup;
  constructor(
    private fb: FormBuilder,
    private localeService: BsLocaleService,
    private createLcService: CreateLcService,
    private clientSharedService: ClientSharedService
  ) {}
  ngOnInit() {
    this.client = this.clientSharedService.getClient();
    this.exchangeValueSetter.subscribe(value => {
      this.exchangeValue = value;
    });
    this.currencies = Util.currencies;
    this.amountMAD = 0;
    this.localeService.use('fr');
    this.issuedTransfer = new IssuedTransfer();
    this.issuedTransferForm = this.fb.group({
      type: ['COMMERCIAL', [Validators.required]],
      amount: [
        null,
        [Validators.required, Util.specialCharValidator()],
        [
          IssuedValidators.amountValidator(
            this,
            this.createLcService,
            Products.TRANSFER_ISSUED,
            this.client.id
          )
        ]
      ],
      currency: [null, [Validators.required]],
      reason: [null, [Validators.required, Util.specialCharValidator()]],
      instructions: [null, [Util.specialCharValidator()]],
      execusionDate: [null, [Validators.required, Util.specialCharValidator()]],
      chargesOutside: [null, [Validators.required]],
      witholding: [null],
      importationTitles: this.fb.array(
        [],
        [Validators.required, Validators.minLength(1)]
      )
    });
    this.currency.valueChanges.subscribe(_value => {
      this.amount.updateValueAndValidity();
    });
    this.type.valueChanges.subscribe(value => {
      this.importationTitles.clearValidators();
      this.witholding.clearValidators();
      if (value === 'COMMERCIAL') {
        this.importationTitles.enable();
        this.importationTitles.setValidators([
          Validators.required,
          Validators.minLength(1)
        ]);
      }
      if (value === 'FINANCIAL') {
        this.importationTitles.clearValidators();
        this.importationTitles.setValidators(null);
        this.importationTitles.disable();
        this.witholding.setValidators([Validators.required]);
      }
      this.importationTitles.updateValueAndValidity();
      this.witholding.updateValueAndValidity();
    });
    this.amount.valueChanges.subscribe(newValue =>
      this.amountChange.emit(newValue)
    );
  }

  mapIssuedToForm(transferIssued: IssuedTransfer) {
    this.issuedTransfer = transferIssued;
    this.issuedTransferForm.setValue({
      type: transferIssued.type,
      amount: transferIssued.amount,
      currency: transferIssued.currency,
      reason: transferIssued.reason,
      witholding: transferIssued.witholding,
      chargesOutside: transferIssued.chargesOutside,
      execusionDate: transferIssued.execusionDate
        ? new Date(transferIssued.execusionDate)
        : null,
      importationTitles: [],
      instructions: transferIssued['instructions']
        ? transferIssued['instructions']
        : ''
    });
    if (this.execusionDate.value) {
      this.execusionDate.markAsTouched();
    }
    this.importationTitles.setValue([]);
    if (
      !transferIssued['importationTitles'] ||
      transferIssued['importationTitles'].length === 0
    ) {
      this.addTitle('');
    } else {
      transferIssued['importationTitles'].forEach((impTitle: string) =>
        this.addTitle(impTitle)
      );
    }
  }

  addTitle(titleValue) {
    const ctrl = <FormArray>this.importationTitles;
    const ctrlItem = this.fb.control(titleValue, [
      Validators.required,
      Validators.maxLength(19),
      Validators.minLength(19)
    ]);
    ctrlItem.setValue(titleValue);
    ctrl.push(ctrlItem);
    ctrl.updateValueAndValidity();
  }

  importationTitlesTrackBy(index: number, _obj: any): any {
    return index;
  }

  removeImportationTitle(i: number): void {
    this.importationTitles.removeAt(i);
  }

  verifyAmount(amount, currency) {
    this.amountSubscription = this.createLcService
      .verifyAmount(Products.CREDOC_IMPORT, amount, currency, this.client.id)
      .subscribe(val => {
        this.amountMAD = val.valueExchange;
      });
  }

  get type(): FormControl {
    return this.issuedTransferForm.get('type') as FormControl;
  }

  get amount(): FormControl {
    return this.issuedTransferForm.get('amount') as FormControl;
  }

  get currency(): FormControl {
    return this.issuedTransferForm.get('currency') as FormControl;
  }

  get referenceWf(): FormControl {
    return this.issuedTransferForm.get('referenceWf') as FormControl;
  }

  get reason(): FormControl {
    return this.issuedTransferForm.get('reason') as FormControl;
  }

  get chargesOutside(): FormControl {
    return this.issuedTransferForm.get('chargesOutside') as FormControl;
  }

  get execusionDate(): FormControl {
    return this.issuedTransferForm.get('execusionDate') as FormControl;
  }

  get importationTitles(): FormArray {
    return this.issuedTransferForm.get('importationTitles') as FormArray;
  }

  get instructions(): FormArray {
    return this.issuedTransferForm.get('instructions') as FormArray;
  }

  get witholding(): FormArray {
    return this.issuedTransferForm.get('witholding') as FormArray;
  }

  setRegulationStatus(): string {
    return 'staussss';
  }

  ngOnDestroy(): void {
    if (this.negotiatedAmountChangeSubscrition) {
      this.negotiatedAmountChangeSubscrition.unsubscribe();
    }
    if (this.negociationTypeChangeSubscription) {
      this.negociationTypeChangeSubscription.unsubscribe();
    }
    if (this.amountSubscription) {
      this.amountSubscription.unsubscribe();
    }
  }

  mapFormToIssuedTransfer() {
    const issuedFormValue = this.issuedTransferForm.value;
    const issuedObject = this.issuedTransfer;
    this.issuedTransfer = { ...issuedObject, ...issuedFormValue };
  }

  setMinDate(): Date {
    return new Date();
  }
}
