import { Component, OnInit, ViewChild, OnDestroy, Input } from '@angular/core';
import { Beneficiary } from 'src/app/models/beneficiary';
import { ClientService } from 'src/app/services/client.service';
import { Bank } from 'src/app/models/bank';
import { IssuedTransferFormComponent } from '../issued-transfer-form/issued-transfer-form.component';
import { IssuedTransferService } from 'src/app/services/issued-transfer.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertComponent } from 'src/app/core/alert/alert.component';
import { Response } from 'src/app/models/response';
import { LastComment } from 'src/app/models/lastComment';
import { Subscription } from 'rxjs';
import { DataService } from 'src/app/services/data.service';
import { Util } from 'src/app/util';
import { NegociationsFormComponent } from '../../negociations-form/negociations-form.component';
import { FormGroup } from '@angular/forms';
import * as FileSaver from 'file-saver';
import { IssuedTransfer } from 'src/app/models/issuedTransfer';
import { ClientView } from 'src/app/models/client-view';
import { ClientSharedService } from 'src/app/services/client-shared-service';

@Component({
  selector: 'app-init-issued-transfer',
  templateUrl: './init-issued-transfer.component.html',
  styleUrls: ['./init-issued-transfer.component.css']
})
export class InitIssuedTransferComponent implements OnInit, OnDestroy {
  @ViewChild('issuedForm') issuedForm: IssuedTransferFormComponent;
  @ViewChild('negociationsComp') negociationsComp: NegociationsFormComponent;
  @Input() model: IssuedTransfer;
  requestId: string;
  saveSnackbarMessage = `L'opération a été soumise pour enregistrée`;
  submitSnackbarMessage = `L'opération a été soumise pour signature`;
  lastComment: LastComment;
  @ViewChild('successAlert') successAlert: AlertComponent;
  private dataServiceSubscription: Subscription;
  private getDocumentListSubscription: Subscription;
  title: string;
  issuedTransferId;
  saved: boolean = false;
  applicantModel: { applicant: any; accountClient: string } = {
    applicant: null,
    accountClient: null
  };
  beneficiaryModel: {
    beneficiary: Beneficiary;
    beneficiaryBankRequired: boolean;
    applicant: { beneficiaries: Array<Beneficiary> };
  } = {
    beneficiary: null,
    beneficiaryBankRequired: null,
    applicant: { beneficiaries: null }
  };

  public client: ClientView;

  constructor(
    private clientService: ClientService,
    private activatdRoute: ActivatedRoute,
    private issuedTransferService: IssuedTransferService,
    private router: Router,
    private dataService: DataService,
    private clientSharedService: ClientSharedService
  ) {
    this.updateNegociationsValidators = this.updateNegociationsValidators.bind(
      this
    );
  }
  ngOnInit() {
    this.client = this.clientSharedService.getClient();
    const id = this.activatdRoute.snapshot.params['id'];
    this.issuedTransferId = id;
    this.title = 'Nouveau virement émis';
    this.clientService.getClient(this.client.id).subscribe(client => {
      this.applicantModel.applicant = client;
    });
    this.clientService.getBeneficiaries(this.client.id).subscribe(beneficiaries => {
      this.beneficiaryModel.applicant.beneficiaries = beneficiaries;
      if (id) {
        this.saved = true;
        this.getIssuedTransfer(id, beneficiaries);
      } else {
        this.negociationsComp.addNegociationControl(
          this.issuedForm.issuedTransfer
        );
        this.issuedForm.addTitle('');
      }
      this.beneficiaryModel.beneficiary = new Beneficiary();
      this.beneficiaryModel.beneficiary.bank = new Bank();
    });
    this.dataServiceSubscription = this.dataService.currentData.subscribe(
      data => {
        this.lastComment = data.lastComment;
      }
    );
  }

  isTISaved(): boolean {
    return this.saved;
  }


  printReport( ) {
    this.clientService.getClient(this.client.id).subscribe(client => {
      this.applicantModel.applicant = client;
    });
    this.issuedTransferService.printReport(this.issuedForm.issuedTransfer.id.toString(), this.client.id).subscribe(data => {
      const fileName: string = data.headers.get('FileName');
      FileSaver.saveAs(data.body, fileName);
    });
  }
  updateNegociationsValidators(newAmount: number) {
    this.negociationsComp.negociations.controls.forEach((_control, index) => {
      this.negociationsComp.setNegociationsValidators(
        newAmount,
        this.negociationsComp.negociations.controls[index] as FormGroup,
        this.negociationsComp.negociations.controls[index].value[
          'negociationType'
        ]
      );
    });
    this.negociationsComp.setRefinancingFormValidators(newAmount);
  }
  private getIssuedTransfer(id: any, beneficiaries: Beneficiary[]) {
    this.issuedTransferService.getById(id, this.client.id).subscribe(transferIssued => {
      this.issuedForm.mapIssuedToForm(transferIssued);
      this.negociationsComp.mapNegociationsToForm(transferIssued);
      this.negociationsComp.refinancing.setValue(transferIssued.refinancing);
      this.applicantModel.accountClient = transferIssued.accountNumber;
      const el = beneficiaries.filter(
        benef => benef.id === transferIssued.beneficiaryId
      );
      if (el && el.length) {
        this.beneficiaryModel.beneficiary = el[0];
      }
    });
  }

  private getDocumentList(id: any) {
    this.getDocumentListSubscription = this.issuedTransferService
      .getById(id, this.client.id)
      .subscribe(transferIssued => {
        this.issuedForm.issuedTransfer.documentList =
          transferIssued.documentList;
      });
  }

  isSectionActive(panel): string {
    const active = panel.style.display === 'block';
    return active ? 'active' : '';
  }

  isSectionCompleted(indicator) {
    return indicator ? 'completed' : '';
  }

  togglePanel(panel: HTMLElement) {
    return Util.togglePanel(panel);
  }

  ngOnDestroy(): void {
    this.dataService.changeData({
      product: null,
      summary: null,
      lcId: null,
      paymentId: null,
      taskDefinitionKey: null,
      lastComment: null,
      action: null,
      decision: null,
      taskId: null
    });
    if (this.dataServiceSubscription) {
      this.dataServiceSubscription.unsubscribe();
    }
    if (this.getDocumentListSubscription) {
      this.getDocumentListSubscription.unsubscribe();
    }
    if (this.lastComment) {
      this.lastComment = null;
    }
  }

  saveIssued() {
    this.completeIssued();
    this.issuedTransferService.save(this.issuedForm.issuedTransfer, this.client.id).subscribe(
      (res: Response) => {
        this.issuedForm.issuedTransfer.id = res.id;
        this.getDocumentList(res.id);
        this.terminateAction(this.saveSnackbarMessage, res.requestId, false);
        this.saved = true;
      },
      err => {

      }
    );
  }

  submitIssued() {
    this.completeIssued();
    this.issuedTransferService
      .submit(this.issuedForm.issuedTransfer, this.client.id)
      .subscribe((res: Response) => {
        this.terminateAction(this.submitSnackbarMessage, res.requestId, true);
      });
  }

  terminateAction(message: string, requestId: string, redirect: boolean) {
    this.successAlert.openAlert(message, requestId);
    setTimeout(() => {
      this.successAlert.closeAlert();
      return redirect ? this.router.navigate(['/tasks']) : null;
    }, 2000);
  }

  completeIssued() {
    this.issuedForm.mapFormToIssuedTransfer();
    this.issuedForm.issuedTransfer.refinancingAmount = this.negociationsComp.refinancingForm.value.amountToBeRefinanced;
    this.issuedForm.issuedTransfer.duration = this.negociationsComp.refinancingForm.value.duration;
    this.issuedForm.issuedTransfer.negociations = this.negociationsComp.negociations.value;
    this.issuedForm.issuedTransfer.refinancing = this.negociationsComp.refinancing.value;
    if (this.beneficiaryModel.beneficiary) {
      this.issuedForm.issuedTransfer.beneficiaryId = this.beneficiaryModel.beneficiary.id;
    }
    this.issuedForm.issuedTransfer.accountNumber = this.applicantModel.accountClient;
  }

  issuedTransferFormValid() {
    return (
      this.isApplicantSectionValid() &&
      this.isBenefSectionValid() &&
      this.isGeneralSectionValid() &&
      this.isNegociationsRefinancingSectionValid() &&
      this.isDocSectionValid()
    );
  }

  isApplicantSectionValid() {
    return (
      this.applicantModel &&
      this.applicantModel.applicant &&
      this.applicantModel.applicant.id &&
      this.applicantModel.applicant &&
      this.applicantModel.accountClient
    );
  }

  isBenefSectionValid() {
    return (
      this.beneficiaryModel &&
      this.beneficiaryModel.beneficiary &&
      this.beneficiaryModel.beneficiary.id
    );
  }

  isGeneralSectionValid() {
    return (
      this.issuedForm &&
      this.issuedForm.issuedTransferForm &&
      this.issuedForm.issuedTransferForm.valid
    );
  }

  isDocSectionValid() {
    return (
      this.issuedForm &&
      this.issuedForm.issuedTransfer &&
      this.issuedForm.issuedTransfer.documentList &&
      this.issuedForm.issuedTransfer.documentList.length > 0
    );
  }

  
  isNegociationsRefinancingSectionValid() {
    if((this.negociationsComp.refinancingForm &&
      this.negociationsComp.refinancingForm.valid &&
      this.negociationsComp.refinancing &&
      this.negociationsComp.refinancing.valid) ||  this.negociationsComp &&
      this.negociationsComp.negociations &&
      this.negociationsComp.negociations.valid) {
      return true ;
    } else 
    return false;
  }
}
