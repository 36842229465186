import { Component, OnInit } from '@angular/core';
import { ClientView } from 'src/app/models/client-view';
import { ClientSharedService } from 'src/app/services/client-shared-service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-list-user',
  templateUrl: './list-user.component.html',
  styleUrls: ['./list-user.component.css']
})
export class ListUserComponent implements OnInit {
  public usersList: Array<any> = [];
  public client: ClientView;
  constructor(
    private userService: UserService,
    private clientSharedService: ClientSharedService
    ) {}
  ngOnInit() {
    this.client = this.clientSharedService.getClient();
    this.userService
      .getUsers(this.client.id)
      .subscribe(listUsers => (this.usersList = listUsers));
  }

  viewUser(user: any, clientId: number) {
    this.userService
      .getUser(user['username'], clientId)
      .subscribe(detail => (user.detail = detail));
  }

  showDetail(user: any, clientId: number) {
    user.selected = !user.selected;
    if (user.selected && !user.detail) {
      this.viewUser(user, clientId);
    }
  }
}
