export const productList: Array<ProductItem> = [
  {
    PREFIX_ROLE: 'CREDOC_IMPORT',
    PRODUCT_NAME: 'Crédit documentaire Import'
  },
  {
    PREFIX_ROLE: 'REMDOC_IMPORT',
    PRODUCT_NAME: 'Remise documentaire Import'
  },
  {
    PREFIX_ROLE: 'TRANSFER_RECEIVED',
    PRODUCT_NAME: 'Virement reçu'
  },
  {
    PREFIX_ROLE: 'TRANSFER_ISSUED',
    PRODUCT_NAME: 'Virement émis'
  }
];

export interface ProductItem {
  PREFIX_ROLE: string;
  PRODUCT_NAME: string;
}
