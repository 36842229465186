import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import {
  FormBuilder,
  Validators,
  FormGroup,
  FormControl
} from '@angular/forms';
import { Util } from 'src/app/util';

@Component({
  selector: 'app-dialog-reject',
  templateUrl: './dialog-reject.component.html',
  styleUrls: ['./dialog-reject.component.css']
})
export class DialogRejectComponent implements OnInit {
  @Input() message;
  @Input() validatorCilcked;
  @Input() model;
  @Input() confirmRejectMessage: string;
  @Input() event;
  @Output() rejected = new EventEmitter<string>();
  rejectForm: FormGroup;
  constructor(private fb: FormBuilder) {}
  ngOnInit(): void {
    this.rejectForm = this.fb.group({
      comment: this.fb.control(null, [
        Validators.required,
        Util.specialCharValidator()
      ])
    });
  }
  get comment(): FormControl {
    return this.rejectForm.get('comment') as FormControl;
  }
  reject(event: string) {
    this.model.comment = this.comment.value;
    this.rejected.emit(event);
  }
  formValid(): boolean {
    return this.rejectForm.valid;
  }
}
