import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Beneficiary } from '../models/beneficiary';
import { Observable, of } from 'rxjs';
import { Bank } from '../models/bank';
import { mergeMap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json'
  })
};

@Injectable({
  providedIn: 'root'
})
export class BeneficiaryService {
  constructor(private http: HttpClient) {}

  saveBenficiary(beneficiary: Beneficiary, clientId: number): Observable<Beneficiary> {
    const body: FormData = new FormData();

    Object.keys(beneficiary).forEach(key => {
      if (key === 'bank') {
        Object.keys(beneficiary[key]).forEach(k => {
          body.append('bank.' + k, beneficiary[key][k]);
        });
      } else {
        body.append(key, beneficiary[key]);
      }
    });
    return this.http.post<Beneficiary>(`${environment.url}/beneficiaries/client/${clientId}`, body);
  }

  getAll(clientId: number): Observable<Array<Beneficiary>> {
    return this.http.get<Array<Beneficiary>>(
      `${environment.url}/beneficiaries/client/${clientId}`,
      httpOptions
    );
  }

  get(benef_id: number, clientId: number): Observable<Beneficiary> {
    if (benef_id !== undefined) {
      return this.http.get<Beneficiary>(
        `${environment.url}/beneficiaries/client/${clientId}/${benef_id}`,
        httpOptions
      );
    }
    const beneficiary: Beneficiary = new Beneficiary();
    beneficiary.bank = new Bank();
    return of(beneficiary);
  }

  reject(beneficiary: Beneficiary, clientId: number): Observable<Number> {
    return this.http.post<Number>(
      `${environment.url}/beneficiaries/client/${clientId}/reject`,
      beneficiary,
      httpOptions
    );
  }

  validate(
    beneficiary: Beneficiary,
    dpResponse: string,
    challengeKey: string,
    clientId: number
  ): Observable<void> {
    const body: FormData = new FormData();
    return of(this.dataURItoBlob(beneficiary.logo)).pipe(
      mergeMap(file => {
        beneficiary.logo = file;
        Object.keys(beneficiary).forEach(key => {
          if (key === 'bank') {
            Object.keys(beneficiary[key]).forEach(k => {
              if (beneficiary[key][k] !== null) {
                body.append('bank.' + k, beneficiary[key][k]);
              }
            });
          } else {
            if (beneficiary[key] !== null) {
              body.append(key, beneficiary[key]);
            }
          }
        });
        body.append('dpResponse', dpResponse);
        body.append('challengeKey', challengeKey);
        return this.http.post<void>(`${environment.url}/beneficiaries/client/${clientId}/validate`, body);
      })
    );
  }

  blobToFile(theBlob: Blob, type: string, fileName: string): File {
    const blob: any = theBlob;
    blob.lastModifiedDate = new Date();
    blob.options = { type: type };
    blob.fileName = fileName;
    return <File>theBlob;
  }

  dataURItoBlob(logo: any) {
    if (logo === undefined) {
      return null;
    }
    if (logo instanceof File) {
      return logo;
    }
    const byteString = atob(logo);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);

    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([arrayBuffer], { type: 'image/png' });
    return this.blobToFile(blob, 'image/png', 'logo');
  }

  update(beneficiary: Beneficiary, clientId: number): Observable<void> {
    const body: FormData = new FormData();
    return of(this.dataURItoBlob(beneficiary.logo)).pipe(
      mergeMap(file => {
        beneficiary.logo = file;
        Object.keys(beneficiary).forEach(key => {
          if (key === 'bank') {
            Object.keys(beneficiary[key]).forEach(k => {
              if (beneficiary[key][k] !== null) {
                body.append('bank.' + k, beneficiary[key][k]);
              }
            });
          } else {
            if (beneficiary[key] !== null) {
              body.append(key, beneficiary[key]);
            }
          }
        });
        return this.http.post<void>(
          `${environment.url}/beneficiaries/client/${clientId}/update`,
          body
        );
      })
    );
  }

  remove(idBeneficiary: number, clientId: number): Observable<void> {
    return this.http.delete<void>(
      `${environment.url}/beneficiaries/client/${clientId}/${idBeneficiary}`,
      httpOptions
    );
  }
}
