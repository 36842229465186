import { Component, Input, OnInit } from '@angular/core';
import { LettreCredit } from 'src/app/models/lettre-credit';
import { Util } from 'src/app/util';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';

@Component({
  selector: 'app-create-general',
  templateUrl: './create-general.component.html',
  styleUrls: ['./create-general.component.css']
})
export class CreateGeneralComponent implements OnInit {
  bsValue = new Date();

  myDateValue: Date;
  datePickerConfig = Util.date_input_config;
  constructor(private localeService: BsLocaleService) {}
  @Input() public model: LettreCredit;
  isValidityDateValid() {
    return Util.diffDays(0, this.model.validityDate, this.model.addDate);
  }
  ngOnInit() {
    this.localeService.use('fr');
    this.myDateValue = new Date();
  }

  onvalidityDateInputChange(date, target) {
    const minDate: Date = new Date();
    if (date instanceof Date && !isNaN(date.getDate()) && date >= minDate) {
      const dt: any = date;
      this.model.validityDate = dt;
    } else {
      this.model.validityDate = null;
      target['value'] = null;
    }
  }
  setMinDate(): Date {
    return new Date();
  }
}
