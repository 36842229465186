import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { LastComment } from '../models/lastComment';

@Injectable({
  providedIn: 'root'
})
export class DataService {
  private dataSource = new BehaviorSubject({
    summary: false,
    lcId: null,
    taskDefinitionKey: null,
    lastComment: { fullMessage: null, time: null, userId: null },
    action: null,
    decision: null,
    paymentId: null,
    product: null,
    taskId: null
  });
  currentData = this.dataSource.asObservable();

  constructor() {}

  changeData(data: {
    summary: boolean;
    lcId: string;
    taskDefinitionKey: string;
    lastComment: LastComment;
    action: string;
    decision: string;
    paymentId: string;
    product: string;
    taskId: string;
  }) {
    this.dataSource.next(data);
  }
}
