import { Component, OnInit } from '@angular/core';
import { Modele } from 'src/app/models/modele';
import { ModeleService } from 'src/app/services/modele.service';
import { ActivatedRoute } from '@angular/router';
import { ClientSharedService } from 'src/app/services/client-shared-service';
import { ClientView } from 'src/app/models/client-view';

@Component({
  selector: 'app-modele-recap',
  templateUrl: './modele-recap.component.html',
  styleUrls: ['./modele-recap.component.css']
})
export class ModeleRecapComponent implements OnInit {
  credoc: any;
  public client: ClientView;
  constructor(
    private modeleService: ModeleService,
    private route: ActivatedRoute,
    private clientSharedService: ClientSharedService
  ) {}

  ngOnInit() {
    this.client = this.clientSharedService.getClient();
    this.selectModele(this.route.snapshot.params['id'], this.client.id );
  }

  selectModele(modeleId: string, clientId: number) {
    this.modeleService.getModele(+modeleId, clientId ).subscribe((modele: Modele) => {
      this.credoc = modele;
    });
  }
}
