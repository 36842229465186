import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json'
  })
};
@Injectable({
  providedIn: 'root'
})
export class SigningSchemaService {
  constructor(private http: HttpClient) {}

  getSigningSchemas(clientId: number) {
    return this.http.get<Array<SigningSchema>>(
      `${environment.url}/client/${clientId}`,
      httpOptions
    );
  }
}
