import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { SnackbarData } from 'src/app/models/snackbar-data';

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.css']
})
export class AlertComponent implements OnInit {
  snackbarData: SnackbarData;
  EMPTY = '';
  constructor() {}

  closeAlert() {
    this.snackbarData = {
      display: { isDisplay: false },
      reference: null,
      message: null
    };
  }
  openAlert(message, reference) {
    this.snackbarData = {
      display: { isDisplay: true },
      reference,
      message
    };
  }

  ngOnInit() {
    this.closeAlert();
  }
}
