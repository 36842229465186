import { BrowserModule } from "@angular/platform-browser";
import { NgModule, LOCALE_ID, APP_INITIALIZER } from "@angular/core";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { CreateComponent } from "./components/lc/create/create.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { registerLocaleData } from "@angular/common";
import localeFr from "@angular/common/locales/fr";
import { CreateApplicantComponent } from "./components/lc/create-applicant/create-applicant.component";
import { CreateBenefBankComponent } from "./components/lc/create-benef-bank/create-benef-bank.component";
import { CreateLcService } from "./services/create-lc.service";
import { HomeService } from "./services/home.service";
import { CreateGeneralComponent } from "./components/lc/create-general/create-general.component";

import { CreateBeneficiaryComponent } from "./components/lc/create-beneficiary/create-beneficiary.component";
import { CreateGoodsComponent } from "./components/lc/create-goods/create-goods.component";

import { SideMenuComponent } from "./core/side-menu/side-menu.component";
import { SideMenuRightComponent } from "./core/side-menu-right/side-menu-right.component";
import { FooterComponent } from "./core/footer/footer.component";
import { CreateTransportModalitiesComponent } from "./components/lc/create-transport-modalities/create-transport-modalities.component";
import { CreateRequiredDocsComponent } from "./components/lc/create-required-docs/create-required-docs.component";
import { TasksComponent } from "./core/tasks/tasks.component";
import { CreatePaymentInstructionsComponent } from "./components/lc/create-payment-instructions/create-payment-instructions.component";
import { CreateJoinDocsComponent } from "./components/lc/create-join-docs/create-join-docs.component";
import { SqueezeBoxModule } from "squeezebox/dist";
import { AlertComponent } from "./core/alert/alert.component";
import { SummaryComponent } from "./components/lc/summary/summary-comp/summary.component";
import { GeneralComponent } from "./components/lc/summary/general/general.component";
import { ClientComponent } from "./components/lc/summary/client/client.component";
import { SummaryLcBeneficiaryComponent } from "./components/lc/summary/beneficiary/beneficiary.component";
import { LcComponent } from "./components/lc/lc-comp/lc.component";
import { DropzoneModule } from "ngx-dropzone-wrapper";
import { DROPZONE_CONFIG } from "ngx-dropzone-wrapper";
import { DropzoneConfigInterface } from "ngx-dropzone-wrapper";
import { ClickOutsideModule } from "ng4-click-outside";
import { PaymentInstructionsComponent } from "./components/lc/summary/payment-instructions/payment-instructions.component";
import { TransportModalitiesComponent } from "./components/lc/summary/transport-modalities/transport-modalities.component";
import { GoodsComponent } from "./components/lc/summary/goods/goods.component";
import { RequiredDocsComponent } from "./components/lc/summary/required-docs/required-docs.component";
import { DialogRejectComponent } from "./core/dialog-reject/dialog-reject.component";
import { DialogConfirmComponent } from "./core/dialog-confirm/dialog-confirm.component";
import { ValidationButtonsComponent } from "./core/validation-buttons/validation-buttons.component";
import { FormComponent } from "./components/beneficiary/form/form.component";
import { BeneficiaryComponent } from "./components/beneficiary/beneficiary.component";
import { ListComponent } from "./components/lc/list/list.component";
import { ManageComponent } from "./components/beneficiary/manage/manage.component";
import { KeycloakService, KeycloakAngularModule } from "keycloak-angular";
import { initializer } from "./util";
import { IsAuthorizedToDirective } from "./core/auth-utils/directive/is-authorized-to.directive";
import { AttachedDocsComponent } from "./core/attached-docs/attached-docs.component";
import { UserService } from "./services/user.service";
import { ShowDiffComponent } from "./components/lc/summary/show-diff/show-diff.component";
import { RemdocCommonRecapComponent } from "./components/remdoc/opening/recap/recap.component";
import { MomentModule } from "ngx-moment";
import { NgxSelectModule } from "ngx-select-ex";

import { PerfectScrollbarModule } from "ngx-perfect-scrollbar";
import { PERFECT_SCROLLBAR_CONFIG } from "ngx-perfect-scrollbar";
import { PerfectScrollbarConfigInterface } from "ngx-perfect-scrollbar";

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
  scrollYMarginOffset: 10
};

import { NgxMaskModule } from "ngx-mask";
import { DatepickerModule, BsDatepickerModule } from "ngx-bootstrap/datepicker";
import { defineLocale } from "ngx-bootstrap/chronos";
import { frLocale } from "ngx-bootstrap/locale";
import { NotificationErrorComponent } from "./core/error-utils/notification-error/notification-error.component";
import { HttpErrorInterceptor } from "./core/error-utils/http-error-interceptor";
import { SortCredocPipe } from "./components/lc/list/sort-credoc.pipe";
import { CurrencyMaskModule } from "ng2-currency-mask";
import {
  CurrencyMaskConfig,
  CURRENCY_MASK_CONFIG
} from "ng2-currency-mask/src/currency-mask.config";
import { AlertWarningComponent } from "./core/alert-warning/alert-warning.component";
import { RecapComponent } from "./components/lc/lc-recap/recap.component";
import { ProrogAreaComponent } from "./components/lc/prorogation/prorog-area/prorog-area.component";
import { ProrogFormComponent } from "./components/lc/prorogation/prorog-form/prorog-form.component";
import { ProrogRecapComponent } from "./components/lc/prorogation/prorog-recap/prorog-recap.component";
import { ExpirAreaComponent } from "./components/lc/refinancing-expiration/expir-area/expir-area.component";
import { ExpirFormComponent } from "./components/lc/refinancing-expiration/expir-form/expir-form.component";
import { ExpirRecapComponent } from "./components/lc/refinancing-expiration/expir-recap/expir-recap.component";
import { PaymentOperationComponent } from "./components/lc/payment-operation/payment-form/payment-operation.component";
import { PaymentRecapComponent } from "./components/lc/payment-operation/payment-recap/payment-recap.component";
import { RemdocListComponent } from "./components/remdoc/list/list.component";
import { RemdocRecapInitiatorComponent } from "./components/remdoc/opening/recap-initiator/recap-initiator.component";
import { RemdocRecapValidatorComponent } from "./components/remdoc/opening/recap-validator/recap-validator.component";
import { ModeleComponent } from "./components/lc/modele/popin/modele.component";
import { PaymentRdiInitiatorComponent } from "./components/remdoc/payment/payment-rdi-initiator/payment-rdi-initiator.component";
import { PaymentRdiValidatorComponent } from "./components/remdoc/payment/payment-rdi-validator/payment-rdi-validator.component";
import { ProrogInnerRecapComponent } from "./components/lc/prorogation/prorog-inner-recap/prorog-inner-recap.component";
import { DialogSignComponent } from "./core/dialog-sign/dialog-sign.component";
import { RdiRecapComponent } from "./components/remdoc/rdi-recap/rdi-recap.component";
import { ModeleRecapComponent } from "./components/lc/modele/modele-recap/modele-recap.component";
import { ModeleListComponent } from "./components/lc/modele/modele-list/modele-list.component";
import { InnerRdiRecapComponent } from "./components/remdoc/inner-rdi-recap/inner-rdi-recap.component";
import { ExpirInnerRecapComponent } from "./components/lc/refinancing-expiration/expir-inner-recap/expir-inner-recap.component";
import { RdiPaymentRecapComponent } from "./components/remdoc/payment/rdi-payment-recap/rdi-payment-recap.component";
import { PaymentRdiRecapComponent } from "./components/remdoc/payment/payment-rdi-recap/payment-rdi-recap.component";
import { HomeComponent } from "./core/home/home.component";
import { CommingSoonComponent } from "./components/comming-soon/comming-soon.component";
import { CommingSoonLayoutComponent } from "./components/comming-soon-layout/comming-soon-layout.component";
import { DefaultLayoutComponent } from "./components/default-layout/default-layout.component";
import { ListGroupComponent } from "./core/list-group/list-group.component";
import { ListUserComponent } from "./core/list-user/list-user.component";
import { SchemaListComponent } from "./core/schema-list/schema-list.component";
import { ClientAreaComponent } from "./core/client-area/client-area.component";
import { UserDetailsComponent } from "./core/user-details/user-details.component";
import { TraceEventComponent } from "./core/trace-event/trace-event.component";
import { PaginationComponent } from "./components/pagination/pagination.component";
import { ReassignmentAreaComponent } from "./core/reassignment-area/reassignment-area.component";
import { RtListComponent } from "./components/received-transfer/rt-list/rt-list.component";
import { RtTreatmentComponent } from "./components/received-transfer/rt-treatment/rt-treatment.component";
import { RtRecapComponent } from "./components/received-transfer/rt-recap/rt-recap.component";
import { RtValidationComponent } from "./components/received-transfer/rt-validation/rt-validation.component";
import { NotificationService } from "./services/notification.service";
import { NotifListComponent } from "./components/notification/notif-list/notif-list.component";
import { InitIssuedTransferComponent } from "./components/issued-transfer/init-issued-transfer/init-issued-transfer.component";
import { IssuedTransferFormComponent } from "./components/issued-transfer/issued-transfer-form/issued-transfer-form.component";
import { IssuedListComponent } from "./components/issued-transfer/issued-list/issued-list.component";
import { IssuedRecapComponent } from "./components/issued-transfer/issued-recap/issued-recap.component";
import { IssuedValidationComponent } from "./components/issued-transfer/issued-validation/issued-validation.component";
import { NegociationsRecapComponent } from "./core/negociations-recap/negociations-recap.component";
import { RefinancingRecapComponent } from "./core/refinancing-recap/refinancing-recap.component";
import { ChargesOutsideRecapComponent } from "./core/charges-outside-recap/charges-outside-recap.component";
import { LastCommentComponent } from "./core/last-comment/last-comment.component";
import { IssuedListRecapComponent } from "./components/issued-transfer/issued-list-recap/issued-list-recap.component";
import { IssuedProrogListRecapComponent } from "./components/issued-transfer/issued-prorog-list-recap/issued-prorog-list-recap.component";
import { ExcludeSpecialCharDirective } from "./directives/exclude-special-char.directive";
import { NegociationsFormComponent } from "./components/negociations-form/negociations-form.component";

import { HighchartsChartModule } from "highcharts-angular";
import { SlickModule } from "ngx-slick";
import { RedirectComponent } from "./core/redirect/redirect.component";

defineLocale("fr", frLocale);
const DEFAULT_DROPZONE_CONFIG: DropzoneConfigInterface = {
  url: "/"
};
registerLocaleData(localeFr, "fr");
const CustomCurrencyMaskConfig: CurrencyMaskConfig = {
  align: "left",
  allowNegative: false,
  decimal: ",",
  precision: 2,
  prefix: "",
  suffix: "",
  thousands: " "
};
@NgModule({
  declarations: [
    AppComponent,
    CreateComponent,
    CreateApplicantComponent,
    CreateBenefBankComponent,
    CreateGeneralComponent,
    CreateRequiredDocsComponent,
    CreateTransportModalitiesComponent,
    CreateBeneficiaryComponent,
    CreateGoodsComponent,
    SideMenuComponent,
    SideMenuRightComponent,
    FooterComponent,
    TasksComponent,
    RemdocCommonRecapComponent,
    CreatePaymentInstructionsComponent,
    CreateJoinDocsComponent,
    AlertComponent,
    SummaryComponent,
    GeneralComponent,
    ClientComponent,
    SummaryLcBeneficiaryComponent,
    LcComponent,
    PaymentInstructionsComponent,
    TransportModalitiesComponent,
    GoodsComponent,
    RequiredDocsComponent,
    DialogRejectComponent,
    DialogConfirmComponent,
    ValidationButtonsComponent,
    FormComponent,
    BeneficiaryComponent,
    ListComponent,
    ManageComponent,
    IsAuthorizedToDirective,
    AttachedDocsComponent,
    ShowDiffComponent,
    NotificationErrorComponent,
    SortCredocPipe,
    PaymentOperationComponent,
    AlertWarningComponent,
    RecapComponent,
    ProrogAreaComponent,
    ProrogFormComponent,
    ProrogRecapComponent,
    ExpirAreaComponent,
    ExpirFormComponent,
    ExpirRecapComponent,
    PaymentRecapComponent,
    RemdocListComponent,
    RemdocRecapInitiatorComponent,
    RemdocRecapValidatorComponent,
    ModeleComponent,
    PaymentRdiInitiatorComponent,
    PaymentRdiValidatorComponent,
    ProrogInnerRecapComponent,
    DialogSignComponent,
    ClientAreaComponent,
    RdiRecapComponent,
    ModeleListComponent,
    ModeleRecapComponent,
    InnerRdiRecapComponent,
    ExpirInnerRecapComponent,
    RdiPaymentRecapComponent,
    PaymentRdiRecapComponent,
    HomeComponent,
    CommingSoonComponent,
    CommingSoonLayoutComponent,
    DefaultLayoutComponent,
    ListGroupComponent,
    ListUserComponent,
    SchemaListComponent,
    UserDetailsComponent,
    TraceEventComponent,
    PaginationComponent,
    ReassignmentAreaComponent,
    RtListComponent,
    RtTreatmentComponent,
    RtRecapComponent,
    RtValidationComponent,
    NotifListComponent,
    InitIssuedTransferComponent,
    IssuedTransferFormComponent,
    IssuedListComponent,
    IssuedRecapComponent,
    IssuedValidationComponent,
    NegociationsRecapComponent,
    RefinancingRecapComponent,
    ChargesOutsideRecapComponent,
    LastCommentComponent,
    IssuedListRecapComponent,
    IssuedProrogListRecapComponent,
    ExcludeSpecialCharDirective,
    NegociationsFormComponent,
    RedirectComponent
  ],
  imports: [
    KeycloakAngularModule,
    HttpClientModule,
    BrowserModule,
    FormsModule,
    AppRoutingModule,
    SqueezeBoxModule,
    NgxSelectModule,
    DropzoneModule,
    ReactiveFormsModule,
    ClickOutsideModule,
    BsDatepickerModule.forRoot(),
    DatepickerModule.forRoot(),
    MomentModule,
    PerfectScrollbarModule,
    CurrencyMaskModule,
    NgxMaskModule.forRoot(),
    HighchartsChartModule,
    SlickModule.forRoot()
  ],
  providers: [
    CreateLcService,
    HomeService,
    UserService,
    { provide: LOCALE_ID, useValue: "fr" },
    {
      provide: DROPZONE_CONFIG,
      useValue: DEFAULT_DROPZONE_CONFIG
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initializer,
      multi: true,
      deps: [KeycloakService]
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true
    },
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    },

    { provide: CURRENCY_MASK_CONFIG, useValue: CustomCurrencyMaskConfig },
    NotificationService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
