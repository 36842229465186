import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
export class ExpirValidators {
  static amountNegociationsValidator(paymentAmount: number): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (control.parent === undefined) {
        return null;
      }
      if (isNaN(control.value)) {
        return {
          numericError: true
        };
      }
      if (!isNaN(paymentAmount) && control.parent && control.parent.parent) {
        const amounts = (control.parent.parent.controls as Array<any>).reduce(
          (acc, curr) => {
            const negoAmount = curr.controls['negotiatedAmount'].value;
            if (!isNaN(negoAmount)) {
              acc += negoAmount;
            }
            return acc;
          },
          0
        );
        if (paymentAmount >= amounts) {
          return null;
        } else {
          return {
            sumError: true
          };
        }
      }
      return null;
    };
  }
}
