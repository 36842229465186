import { Injectable } from '@angular/core';
import { Observable, iif, of } from 'rxjs';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { ReceivedTransfer } from '../models/receivedTransfer';
import { mergeMap } from 'rxjs/operators';
import { CreateLcService } from './create-lc.service';
import { environment } from 'src/environments/environment';
const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json'
  })
};

@Injectable({
  providedIn: 'root'
})
export class ReceivedTransferService {
  constructor(
    private http: HttpClient,
    private createlcService: CreateLcService
  ) {}

  rejectRt(receivedTransfer: ReceivedTransfer, clientId: number) {
    return this.http.post<void>(
      `${environment.url}/transferReceived/client/${clientId}/validator/reject`,
      {
        id: receivedTransfer.id,
        comment: receivedTransfer.comment
      },
      httpOptions
    );
  }

  submitRt(receivedTransfer: ReceivedTransfer, clientId: number) {
    return this.http
      .post<void>(
        `${environment.url}/transferReceived/client/${clientId}/submit`,
        receivedTransfer,
        httpOptions
      )
      .pipe(
        mergeMap(() =>
          iif(
            () => receivedTransfer.documentList.length > 0,
            this.createlcService.uploadRtDocs(
              receivedTransfer.id,
              receivedTransfer.documentList,
              clientId
            ),
            of(null)
          )
        )
      );
  }

  saveRt(receivedTransfer: ReceivedTransfer, clientId: number): Observable<void> {
    return this.http
      .post<void>(
        `${environment.url}/transferReceived/client/${clientId}/save`,
        receivedTransfer,
        httpOptions
      )
      .pipe(
        mergeMap(() =>
          iif(
            () => receivedTransfer.documentList.length > 0,
            this.createlcService.uploadRtDocs(
              receivedTransfer.id,
              receivedTransfer.documentList,
              clientId
            ),
            of(null)
          )
        )
      );
  }

  printReport(rtId: number, clientId: number): Observable<HttpResponse<Blob>> {
    return this.http.get(
      `${environment.url}/transferReceived/client/${clientId}/reporting/${rtId}`,
      { responseType: 'blob', observe: 'response' }
    );
  }

  validateRt(
    receivedTransfer: ReceivedTransfer,
    dpResponse: string,
    challengeKey: string,
    clientId: number
  ): Observable<void> {
    return this.http.post<void>(
      `${environment.url}/transferReceived/client/${clientId}/validator/accept`,
      {
        id: receivedTransfer.id,
        dpResponse,
        challengeKey
      },
      httpOptions
    );
  }

  getRtList(clientId: number): Observable<ReceivedTransfer[]> {
    return this.http.get<ReceivedTransfer[]>(
      `${environment.url}/transferReceived/client/${clientId}`,
      httpOptions
    );
  }
  getRt(id: number, clientId: number): Observable<ReceivedTransfer> {
    return this.http.get<ReceivedTransfer>(
      `${environment.url}/transferReceived/client/${clientId}/by/${id}`,
      httpOptions
    );
  }
}
