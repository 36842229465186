import { Component, OnInit } from '@angular/core';
import { ReassignmentService } from 'src/app/services/reassignment.service';
import { ReassignmentFilter } from 'src/app/models/reassignmentFilter';
import { Operation } from 'src/app/models/operation';
import { productList, ProductItem } from 'src/app/models/productList';
import { Reassignment } from 'src/app/models/reassignment';
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl
} from '@angular/forms';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { Util } from 'src/app/util';
import { ClientView } from 'src/app/models/client-view';
import { ClientSharedService } from 'src/app/services/client-shared-service';

@Component({
  selector: 'app-reassignment-area',
  templateUrl: './reassignment-area.component.html',
  styleUrls: ['./reassignment-area.component.css']
})
export class ReassignmentAreaComponent implements OnInit {
  public operations: Array<Operation> = null;
  public productList: Array<ProductItem> = productList;
  public initiators: Array<string> = null;
  public reassignmentForm: FormGroup;
  public initiatorsTarget;
  public allInitiators = false;
  public client: ClientView;
  datePickerConfig = Util.date_input_config;
  constructor(
    private reassignmentService: ReassignmentService,
    private fb: FormBuilder,
    private localeService: BsLocaleService,
    private clientSharedService: ClientSharedService
  ) { }

  ngOnInit() {
    this.client = this.clientSharedService.getClient();
    this.localeService.use('fr');
    this.reassignmentForm = this.fb.group({
      productControl: [null, Validators.required],
      initiatorControl: [null, Validators.required],
      referenceControl: [null],
      minDate: [null],
      maxDate: [null]
    });
  }


  getProcutLabel(productName) {
    return productList.filter(p => p.PREFIX_ROLE === productName)[0]
      .PRODUCT_NAME;
  }

  get productControl(): FormControl {
    return this.reassignmentForm.get('productControl') as FormControl;
  }

  get initiatorControl(): FormControl {
    return this.reassignmentForm.get('initiatorControl') as FormControl;
  }
  get minDate(): FormControl {
    return this.reassignmentForm.get('minDate') as FormControl;
  }

  get maxDate(): FormControl {
    return this.reassignmentForm.get('maxDate') as FormControl;
  }
  get referenceControl(): FormControl {
    return this.reassignmentForm.get('referenceControl') as FormControl;
  }
  onAllInitiators() {
    this.setAllInitiators(!this.allInitiators);
  }
  setAllInitiators(allInitiators) {
    this.allInitiators = allInitiators;
    if (this.allInitiators) {
      this.initiatorControl.setValue(this.initiators);
    } else {
      this.initiatorControl.setValue(null);
    }
  }
  getOperations() {
    const reassignmentFilter: ReassignmentFilter = {
      product: this.productControl.value,
      initiators: this.initiatorControl.value,
      reference: this.referenceControl.value,
      endDate: this.maxDate.value,
      startDate: this.minDate.value
    };

    this.reassignmentService
      .getOperations(reassignmentFilter, this.client.id)
      .subscribe(operations => (this.operations = operations));
  }

  getInitiatorsSource() {
    this.reassignmentService
      .getInitiatorsSource(this.productControl.value, this.client.id)
      .subscribe(initiators => {
        this.initiators = initiators;
        this.setAllInitiators(this.allInitiators);
        this.getInitiatorsTarget();
      });
  }

  getInitiatorsTarget() {
    this.reassignmentService
      .getInitiatorsTarget(this.productControl.value, this.client.id)
      .subscribe(initiators => {
        this.initiatorsTarget = initiators;
        //this.setAllInitiators(this.allInitiators);
      });
  }

  exclude(initiator: string): Array<String> {
    return this.initiators.filter( u => initiator !== u);
  }

  eraseFilters() {
    this.productControl.setValue(null);
    this.initiatorControl.setValue(null);
    this.referenceControl.setValue(null);
    this.minDate.setValue(null);
    this.maxDate.setValue(null);
    this.initiators = [];
  }

  reassign(operation: Operation) {
    const reassignment: Reassignment = {
      product: operation.product,
      reference: operation.reference,
      sourceInitiator: operation.initiator,
      targetInitiator: operation['targetInitiator']
    };
    this.reassignmentService.reassign(reassignment, this.client.id).subscribe(() => {
      this.getOperations();
      this.setAllInitiators(this.allInitiators);
    });
  }
  
}
