import { Component, OnInit, Input } from '@angular/core';
import { LettreCredit } from 'src/app/models/lettre-credit';

@Component({
  selector: 'app-transport-modalities',
  templateUrl: './transport-modalities.component.html',
  styleUrls: ['./transport-modalities.component.css']
})
export class TransportModalitiesComponent implements OnInit {
  @Input() model: LettreCredit;

  constructor() {}

  ngOnInit() {}
}
