import { Component, OnInit, Input } from '@angular/core';
import { LettreCredit } from 'src/app/models/lettre-credit';

@Component({
  selector: 'app-recap',
  templateUrl: './recap.component.html',
  styleUrls: ['./recap.component.css']
})
export class RecapComponent implements OnInit {
  @Input() model: LettreCredit;
  constructor() {}
  ngOnInit() {}
}
