import { Component, OnInit, Input, OnDestroy, ViewChild } from '@angular/core';
import {
  FormControl,
  FormGroup,
  Validators,
  FormBuilder
} from '@angular/forms';
import { Subscription, Observable } from 'rxjs';
import { ProrogService } from 'src/app/services/prorog.service';
import { Router } from '@angular/router';
import { ProrogationViews, Util } from 'src/app/util';
import { AlertComponent } from 'src/app/core/alert/alert.component';
import { Products } from 'src/app/util';
import { ClientView } from 'src/app/models/client-view';
import { ClientSharedService } from 'src/app/services/client-shared-service';

@Component({
  selector: 'app-prorog-form',
  templateUrl: './prorog-form.component.html',
  styleUrls: ['./prorog-form.component.css']
})
export class ProrogFormComponent implements OnInit, OnDestroy {
  public prorogForm: FormGroup;
  @Input() id: string;
  @Input() taskId: string;
  DAYS = ' Jours';
  refinAmount: number;
  refinDuration: number;
  cumulativeDuration: number;
  confirmationShown = false;
  status: string;
  @Input() product: string;
  @Input() currency: string;
  @Input() prorogationView: string;
  @ViewChild('prorog_form_alert') prorogFormAlert: AlertComponent;
  public submitProrogRequestMessage =
    'Vous êtes sur le point de soumettre la demande de prorogation. Êtes-vous sûr de vouloir continuer ?';
  private askFormProrogSubscription: Subscription;
  private getProrogInitiationDataSubscription: Subscription;
  private getProrogDataSubscription: Subscription;
  public client: ClientView;
  constructor(
    private fb: FormBuilder,
    private prorogService: ProrogService,
    private router: Router,
    private clientSharedService: ClientSharedService
  ) {}

  ngOnInit() {
    this.client = this.clientSharedService.getClient();
    this.showSnackbar = this.showSnackbar.bind(this);
    this.prorogForm = this.fb.group({
      duration: [null, [Validators.required, Validators.min(0)]]
    });
    this.getData();
  }
  getData() {
    if (this.prorogationView === ProrogationViews.PROROGATION_INITIATION) {
      this.getProrogInitiationData(+this.id);
    } else if (this.prorogationView === ProrogationViews.PROROGATION_UPDATE) {
      this.getProrogData(this.id);
    }
  }
  private getProrogInitiationData(id: number): any {
    let ob: Observable<Object>;
    switch (this.product) {
      case Products.TRANSFER_ISSUED:
        ob = this.prorogService.getIssuedProrgInitiationData(id, this.client.id);
        break;
      default:
        ob = this.prorogService.getProrgInitiationData(id);
        break;
    }
    this.getProrogInitiationDataSubscription = ob.subscribe(
      data => {
        this.setProrogVars(data);
      },
      err => {}
    );
  }

  private getProrogData(id: string): any {
    this.getProrogDataSubscription = this.prorogService
      .getProrogData(id, this.client.id)
      .subscribe(
        data => {
          this.setProrogVars(data);
        },
        err => {}
      );
  }

  private setProrogVars(data: Object) {
    this.refinDuration = data['refinancingDuration'];
    this.refinAmount = data['refinancingAmount'];
    this.cumulativeDuration = data['cumulativeDuration'];
    this.status = data['status'];
    this.duration.setValue(data['extensionDuration']);
  }

  setStatusText(): string {
    return status
      ? Util.setStatus('recap', status).text
      : 'Demande de prorogation en cours';
  }

  get duration(): FormControl {
    return this.prorogForm.get('duration') as FormControl;
  }

  onSubmitProrogRequest() {
    if (this.duration.value) {
      if (this.prorogationView === ProrogationViews.PROROGATION_INITIATION) {
        this.askForProrogation(this.showSnackbar);
      } else if (this.prorogationView === ProrogationViews.PROROGATION_UPDATE) {
        this.updateProrogation(this.showSnackbar);
      }
    }
  }

  private showSnackbar() {
    return val => {
      this.prorogFormAlert.snackbarData.display.isDisplay = true;
      this.prorogFormAlert.snackbarData.message = `L'opération a été soumise pour signature`;
      setTimeout(() => {
        this.prorogFormAlert.snackbarData.display.isDisplay = false;
        this.returnToList();
      }, 3000);
    };
  }

  updateProrogation(callback) {
    this.prorogService
      .updateProrogation(this.duration.value, this.id, this.taskId, this.client.id)
      .subscribe(callback());
  }

  askForProrogation(callback) {
    let ob: Observable<Object>;
    switch (this.product) {
      case Products.TRANSFER_ISSUED:
        ob = this.prorogService.askForIssuedProrogation(
          +this.id,
          this.duration.value,
          this.client.id
        );
        break;
      default:
        ob = this.prorogService.askForProrogation(
          +this.id,
          this.duration.value,
          this.client.id
        );
        break;
    }
    this.askFormProrogSubscription = ob.subscribe(callback());
  }

  onCancelProrogRequest() {
    this.returnToList();
  }

  returnToList() {
    if (this.prorogationView === ProrogationViews.PROROGATION_INITIATION) {
      switch (this.product) {
        case Products.CREDOC_IMPORT:
          this.router.navigate(['/lc/list']);
          break;
        case Products.REMDOC_IMPORT:
          this.router.navigate(['/remdoc/list']);
          break;
        case Products.TRANSFER_ISSUED:
          this.router.navigate(['/issued-transfer/list']);
          break;
      }
    } else if (this.prorogationView === ProrogationViews.PROROGATION_UPDATE) {
      this.router.navigate(['/tasks']);
    }
  }

  ngOnDestroy(): void {
    if (this.askFormProrogSubscription) {
      this.askFormProrogSubscription.unsubscribe();
    }
    if (this.getProrogDataSubscription) {
      this.getProrogDataSubscription.unsubscribe();
    }
    if (this.getProrogInitiationDataSubscription) {
      this.getProrogInitiationDataSubscription.unsubscribe();
    }
  }
}
