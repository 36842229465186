import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { TraceService } from 'src/app/services/trace.service';
import { Trace } from 'src/app/models/trace';
import { Util } from 'src/app/util';
import { FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { UserService } from 'src/app/services/user.service';
import { Page } from 'src/app/models/page';
import { ClientView } from 'src/app/models/client-view';
import { ClientSharedService } from 'src/app/services/client-shared-service';

@Component({
  selector: 'app-trace-event',
  templateUrl: './trace-event.component.html',
  styleUrls: ['./trace-event.component.css']
})
export class TraceEventComponent implements OnInit, OnDestroy {
  public datePickerConfig = Util.date_input_config;
  searchEventFormGroup: FormGroup;
  public usersName: string[] = ['user1', 'user3'];
  public model: Trace;
  public traces: Array<Trace>;
  private getTracesSubscriber: Subscription;
  page: Page = { currentPage: 0, totalPages: null, totalElements: null };
  public usersList: Array<any> = [];
  public contextList: Array<any> = [];
  public operationList: Array<any> = [];
  public client: ClientView

  constructor(
    private traceService: TraceService,
    private fb: FormBuilder,
    private userService: UserService,
    private clientsharedService: ClientSharedService
  ) {}

  ngOnInit() {
    this.client = this.clientsharedService.getClient();
    this.contextList = Util.contextType;
    this.userService
      .getUsers(this.client.id)
      .subscribe(listUsers => (this.usersList = listUsers));

    this.searchEventFormGroup = this.fb.group({
      userName: [null],
      context: [null],
      operation: [null],
      reference: [null],
      startDate: [null],
      endDate: [null]
    });
    this.model = new Trace();
    this.getTraces(this.model, this.client.id);
  }

  ngOnDestroy(): void {
    if (this.getTracesSubscriber) {
      this.getTracesSubscriber.unsubscribe();
    }
  }

  getOperationList(): void {
    this.operationList = [];
    if (this.context.value === Util.contextType[0].code) {
      this.operationList = Util.contextOperationCDType;
    } else if (this.context.value === Util.contextType[1].code) {
      this.operationList = Util.contextOperationRDType;
    } else if (this.context.value === Util.contextType[2].code) {
      this.operationList = Util.contextOperationBenefType;
    } else if (this.context.value === Util.contextType[3].code) {
      this.operationList = Util.contextOperationVRIType;
    } else if (this.context.value === Util.contextType[4].code) {
      this.operationList = Util.contextOperationITType;
    }
  }

  isEndDateNotValid(): boolean {
    if (this.startDate.value) {
      return this.startDate.value > this.endDate.value;
    } else {
      return false;
    }
  }

  get userName(): FormControl {
    return this.searchEventFormGroup.get('userName') as FormControl;
  }

  get context(): FormControl {
    return this.searchEventFormGroup.get('context') as FormControl;
  }

  get operation(): FormControl {
    return this.searchEventFormGroup.get('operation') as FormControl;
  }

  get reference(): FormControl {
    return this.searchEventFormGroup.get('reference') as FormControl;
  }

  get startDate(): FormControl {
    return this.searchEventFormGroup.get('startDate') as FormControl;
  }

  get endDate(): FormControl {
    return this.searchEventFormGroup.get('endDate') as FormControl;
  }

  getPage() {
    this.getTraces(this.model, this.client.id);
  }

  searchList() {
    if (this.isEndDateNotValid()) {
      return;
    }

    this.page.currentPage = 0;
    this.model.userName = this.userName.value;
    this.model.context = this.context.value;
    this.model.operation = this.operation.value;
    this.model.reference =
      this.reference.value === '' ? null : this.reference.value;
    this.model.startDate = this.startDate.value;
    this.model.endDate = this.endDate.value;
    this.getTraces(this.model, this.client.id);
  }

  getTraces(filter: Trace, clientId: number): void {
    this.getTracesSubscriber = this.traceService
      .getTraceByFilter(this.page.currentPage, filter, clientId)
      .subscribe(trace => {
        this.page.totalPages = trace['totalPages'];
        this.page.totalElements = trace['totalElements'];
        this.traces = trace['contentList'];
      });
  }

  getContextTypeText(key): string {
    return Util.contextType.filter(context => {
      return key === context.code;
    })[0].label;
  }

  getContextOperationTypeText(key): string {
    const result = Util.contextOperationCDType.filter(operation => {
      return key === operation.code;
    });

    if (result.length > 0) {
      return result[0].label;
    }
    return Util.contextOperationBenefType.filter(operation => {
      return key === operation.code;
    })[0].label;

    return null;
  }

  controleEndDate() {
    if (!this.startDate.value) {
      this.endDate.setValue(null);
    }
  }

  getDescriptionTraceTypeText(key): string {
    if (!key) {
      return null;
    }
    return Util.descriptionTraceType.filter(description => {
      return key === description.code;
    })[0].label;
  }
}
