import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { LettreCredit } from 'src/app/models/lettre-credit';
import { Util, Products } from 'src/app/util';
import { CreateLcService } from 'src/app/services/create-lc.service';
import { ClientView } from 'src/app/models/client-view';
import { ClientSharedService } from 'src/app/services/client-shared-service';

@Component({
  selector: 'app-create-payment-instructions',
  templateUrl: './create-payment-instructions.component.html',
  styleUrls: ['./create-payment-instructions.component.css']
})
export class CreatePaymentInstructionsComponent implements OnInit, OnDestroy {
  public paymentType: string; // single or mixte payment
  public paymentTypeSelected: string;
  charges = Util.charges;
  amountSubscriber;
  displayAmountLoader = false;
  isAmountFieldTouched = false;
  isAmountValid = false;
  paymentTypes = Util.paymentTypes;
  @Input() public model: LettreCredit;
  public currencies: string[];
  public amountMAD: number;
  public client: ClientView;
  SINGLE_PAYMENT = 'SINGLE_PAYMENT';
  MIXED_PAYMENT = 'MIXED_PAYMENT';
  OTHER = 'OTHER';
  constructor(
    private createLcService: CreateLcService,
    private clientSharedService: ClientSharedService
    ) {}

  ngOnInit(): void {
    this.client = this.clientSharedService.getClient();
    this.amountMAD = 0;
    this.currencies = Util.currencies;
    this.model.paymentType = this.SINGLE_PAYMENT;
  }
  ngOnDestroy(): void {
    if (this.amountSubscriber) {
      this.amountSubscriber.unsubscribe();
    }
  }
  onTolerencePlusChange(event) {
    if (!this.model.amountToleranceLess && +event.target.value <= 10) {
      this.model.amountToleranceLess = +event.target.value;
    }
  }

  getIsAmountValid() {
    return this.isAmountValid;
  }

  getDisplayAmountLoader() {
    return this.displayAmountLoader;
  }
  onAmountFocus() {
    this.isAmountFieldTouched = false;
  }
  onAmountBlur() {
    if (
      this.model.amount &&
      this.model.amount + '' !== '' &&
      this.model.currency &&
      this.model.currency !== ''
    ) {
      this.verifyAmount(this.model.amount, this.model.currency);
    }
  }
  verifyAmount(amount, currency) {
    this.displayAmountLoader = true;
    this.amountSubscriber = this.createLcService
      .verifyAmount(Products.CREDOC_IMPORT, amount, currency, this.client.id)
      .subscribe(val => {
        this.isAmountValid = val.sigingSchemaExist;
        this.displayAmountLoader = false;
        this.isAmountFieldTouched = true;
        this.amountMAD = val.valueExchange;
      });
  }
  selectPaymentType() {
    this.model.amountSightPayment = null;
    this.model.paymentAtSightType = false;
    this.model.paymentByAcceptanceType = false;
    this.model.paymentDueType = false;
    if (this.model.paymentTypeSelected === 'PAYMENT_AT_SIGHT') {
      this.model.paymentAtSightType = true;
    }

    if (this.model.paymentTypeSelected === 'PAYMENT_BY_ACCEPTANCE') {
      this.model.paymentByAcceptanceType = true;
    }

    if (this.model.paymentTypeSelected === 'PAYMENT_DUE') {
      this.model.paymentDueType = true;
    }
  }
  isAmountsValid() {
    return Util.compareAmounts(this.model);
  }
  initPaymentAtSightType() {
    this.model.paymentAtSightType = false;
    this.model.amountSightPayment = null;
  }

  initPaymentByAcceptanceType() {
    this.model.paymentByAcceptanceType = false;
    this.model.daysAcceptancePayment = null;
    this.model.amountAcceptancePayment = null;
  }

  initPaymentDueType() {
    this.model.paymentDueType = false;
    this.model.daysDuePayment = null;
    this.model.fromDuePayment = null;
    this.model.amountDuePayment = null;
  }

  choosePaymentType() {
    this.initPaymentAtSightType();
    this.initPaymentByAcceptanceType();
    this.initPaymentDueType();
  }

  togglePayment(payment: HTMLElement): void {
    payment.hidden = !payment.hidden;
    if (payment.getAttribute('name') === 'payment_due') {
      this.initPaymentDueType();
      if (!payment.hidden) {
        this.model.paymentDueType = true;
      }
    }

    if (payment.getAttribute('name') === 'payment_acceptation') {
      this.initPaymentByAcceptanceType();
      if (!payment.hidden) {
        this.model.paymentByAcceptanceType = true;
      }
    }

    if (payment.getAttribute('name') === 'payment_sight') {
      this.initPaymentAtSightType();
      if (!payment.hidden) {
        this.model.paymentAtSightType = true;
      }
    }
  }
}
