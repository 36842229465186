import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders,HttpResponse } from '@angular/common/http';
import { IssuedTransfer } from '../models/issuedTransfer';
import { mergeMap } from 'rxjs/operators';
import { iif, of, Observable, BehaviorSubject } from 'rxjs';
import { DocumentService } from './document.service';
import { Document } from '../models/document';
import { Response } from '../models/response';
import { RefinancingExtension } from '../models/refinancingExtension';
import { SwiftDocument } from '../models/swiftDocument';
import { environment } from 'src/environments/environment';
const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json'
  })
};
@Injectable({
  providedIn: 'root'
})
export class IssuedTransferService {
  private dataSource = new BehaviorSubject({
    issued: null,
    extId: null
  });
  currentData = this.dataSource.asObservable();

  constructor(
    private http: HttpClient,
    private documentService: DocumentService
  ) {}

  changeData(data: { issued: IssuedTransfer; extId: number }) {
    this.dataSource.next(data);
  }

  getById(id: any, clientId: number): Observable<IssuedTransfer> {
    return this.http.get<IssuedTransfer>(
      `${environment.url}/transferIssued/clients/${clientId}/by/${id}`,
      httpOptions
    );
  }

  delete(id: number, clientId: number): Observable<void> {
    return this.http.delete<void>(
      `${environment.url}/transferIssued/clients/${clientId}/${id}`,
      httpOptions
    );
  }

  tempRejectIssuedTransfer(
    id: number,
    comment: string,
    clientId: number
  ): Observable<IssuedTransfer> {
    return this.http.post<IssuedTransfer>(
      `${environment.url}/transferIssued/clients/${clientId}/validator/provisional/reject`,
      {
        id,
        comment
      },
      httpOptions
    );
  }

  rejectIssuedTransfer(
    id: number,
    comment: string,
    clientId: number
  ): Observable<IssuedTransfer> {
    return this.http.post<IssuedTransfer>(
      `${environment.url}/transferIssued/clients/${clientId}/validator/definitif/reject`,
      { id, comment },
      httpOptions
    );
  }

  validateIssuedTransfer(
    id: number,
    dpResponse: string,
    challengeKey: string,
    clientId: number
  ): Observable<IssuedTransfer> {
    return this.http.post<IssuedTransfer>(
      `${environment.url}/transferIssued/clients/${clientId}/validator/sign`,
      { id, dpResponse, challengeKey },
      httpOptions
    );
  }

  submit(issuedTransfer: IssuedTransfer, clientId: number): Observable<Response> {
    return this.http
      .post<Response>(
        `${environment.url}/transferIssued/clients/${clientId}/submit`,
        issuedTransfer,
        httpOptions
      )
      .pipe(
        mergeMap((res: Response) =>
          iif(
            () => issuedTransfer.documentList.length > 0,
            this.uploadItDocs(res.id, issuedTransfer.documentList, clientId).pipe(
              mergeMap(() => of(res))
            ),
            of(res)
          )
        )
      );
  }

  save(issuedTransfer: IssuedTransfer, clientId: number): Observable<Response> {
    return this.http
      .post<Response>(
        `${environment.url}/transferIssued/clients/${clientId}/save`,
        issuedTransfer,
        httpOptions
      )
      .pipe(
        mergeMap((res: Response) =>
          iif(
            () => issuedTransfer.documentList.length > 0,
            this.uploadItDocs(res.id, issuedTransfer.documentList, clientId).pipe(
              mergeMap(() => of(res))
            ),
            of(res)
          )
        )
      );
  }

  uploadItDocs(issuedTransferId: number, documents: Document[], clientId: number) {
    return this.documentService.uploadDocuments(
      issuedTransferId,
      documents,
      `${environment.url}/transferIssued/clients/${clientId}/uploadDocuments/`
    );
  }

  getIssuedTransferList(
    currentPage: number,
    search: string,
    clientId: number
  ): Observable<IssuedTransfer[]> {
    return this.http.get<IssuedTransfer[]>(
      `${environment.url}/transferIssued/clients/${clientId}/page/${currentPage}?search=${search}`,
      httpOptions
    );
  }

  getCountIssuedTransferList(clientId: number): Observable<number> {
    return this.http.get<number>(
      `${environment.url}/transferIssued/clients/${clientId}/count`,
      httpOptions
    );
  }

  printReport(transferIssuedId: string, clientId: number): Observable<HttpResponse<Blob>> {
    return this.http.get(
      `${environment.url}/transferIssued/clients/${clientId}/reporting/${transferIssuedId}`,
      { responseType: 'blob', observe: 'response' }
    );
  } 

  downloadSwift(transferIssuedId: string): Observable<ArrayBuffer> {
    return this.http.get(
      `${environment.url}/transferIssued/recupererSwift/${transferIssuedId}`,
      { responseType: 'arraybuffer', observe: 'body' }
    );
  }
}
