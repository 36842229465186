
export class Trace {
  public id: number;
  public context: string;
  public operation: string;
  public reference: string;
  public eventDate: string;
  public userName: string;
  public description: string;
  public startDate: Date;
  public endDate: Date;
}
