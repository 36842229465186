import { Component, Input, OnInit } from '@angular/core';
import { LettreCredit } from 'src/app/models/lettre-credit';
import { Util } from 'src/app/util';

@Component({
  selector: 'app-create-required-docs',
  templateUrl: './create-required-docs.component.html',
  styleUrls: ['./create-required-docs.component.css']
})
export class CreateRequiredDocsComponent implements OnInit {
  @Input() public model: LettreCredit;
  INTERNATIONAL_CONSIGNMENTNOTE = 'INTERNATIONAL_CONSIGNMENTNOTE';
  constructor() {}
  ngOnInit() {}

  isSubmissionDeadlineDaysCountValid(): boolean {
    return Util.isSubmissionDeadlineDaysCountValid(this.model);
  }
}
